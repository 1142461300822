// Angular Files
import { Directive, ElementRef, HostListener, Input, AfterViewInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
    selector: '[appRestrictFocus]'
})
export class RestrictFocusDirective implements AfterViewInit {
    @Input('appRestrictFocus') restricted: boolean; // Whether or not the focus is currently restricted
    @Input() closeSelector: string; // A selector for a close button that exists outside of the element that can be tabbed to
    // Add all the child elements inside the element which should be focusable
    private focusableElements = 'button, [href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])';

    private hostElement;
    private firstFocusableElement;
    private focusableContent: Node[];
    private lastFocusableElement;
    private closeElement;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private el: ElementRef
    ) {}

    ngAfterViewInit() {
        this.grabFocusableElements();
        this.focus();
    }

    // We can't filter for just the tab key in the listener as we won't get in here for shift so listen to the keydown in general and filter out later
    @HostListener('document:keydown', ['$event']) onKeyDown(e) {
        let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

        // If it's not the tab key, we don't care about the event
        if (!isTabPressed) {
            return;
        }

        // Always regrab the elements incase the contents has changed since it was first initialized
        this.grabFocusableElements();
       
        if (this.restricted) {
            if (!this.focusableContent.includes(this.document.activeElement)) {
                this.firstFocusableElement.focus();
            } else if (e.shiftKey) { // If shift key pressed for shift + tab combination (to tab backwards)
                if (this.document.activeElement === this.firstFocusableElement) { // If focus has reached the first focusable element, then focus the last focusable element after pressing shift + tab
                    if(this.closeElement) {
                        this.closeElement.focus(); // Add focus to the close element
                    } else {
                        this.lastFocusableElement.focus(); // Add focus to the last focusable element
                    }
                    e.preventDefault();
                } else if (this.closeElement && this.document.activeElement === this.closeElement) {
                    this.lastFocusableElement.focus(); // Add focus to the first focusable element
                    e.preventDefault();
                }
            } else { // If tab key is pressed
                if (this.document.activeElement === this.lastFocusableElement) { // If focus has reached the last focusable element then focus first focusable element after pressing tab
                    if(this.closeElement) {
                        this.closeElement.focus(); // Add focus to the close element
                    } else {
                        this.firstFocusableElement.focus(); // Add focus to the first focusable element
                    }
                    e.preventDefault();
                } else if (this.closeElement && this.document.activeElement === this.closeElement) {
                    this.firstFocusableElement.focus(); // Add focus to the first focusable element
                    e.preventDefault();
                }
            }
        }
    }

    private focus() {
        if(this.restricted) {
            this.firstFocusableElement.focus();
        }
    }

    private grabFocusableElements() {
        this.hostElement = this.el.nativeElement;
        // Grab all the elements that can be focused within the parent element
        this.focusableContent = Array.from(this.hostElement.querySelectorAll(this.focusableElements));
        // Extract out just the first focusable element
        this.firstFocusableElement = this.focusableContent[0]; 
        // Extract out just the last focusable element within the parent
        this.lastFocusableElement = this.focusableContent[this.focusableContent.length - 1];
        // If a closeSelector was specified, grab the close element so we can add that to the focus order
        if(this.closeSelector) {
            this.closeElement = this.document.querySelector(this.closeSelector);
        } 

        // We don't want our first focus to be the close element so we'll remove it out of the regular focus order so it will appear at the end
        if(this.firstFocusableElement == this.closeElement && this.focusableContent[1]) {
            this.firstFocusableElement = this.focusableContent[1];
        }
    }
}
