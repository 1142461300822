// Angular Files
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Angular Material Files
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatIconModule } from "@angular/material/icon";

// Other Files
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";

// Payment Integration Files
import {
    BasePaymentProcessorIntegrationsModule
} from 'apps/public-portal/src/app/payment-integrations/base';

// Component Files
import { DemoComponent } from "./component";

// Teller Online Library Files
import { TellerOnlineIconsModule } from 'teller-online-libraries/icons';


@NgModule({
    imports: [
        CommonModule,
        TellerOnlineIconsModule,
        BasePaymentProcessorIntegrationsModule,
        FormsModule,
        ReactiveFormsModule,
        MatLegacyButtonModule,
        MatLegacyFormFieldModule,
        MatLegacyInputModule,
        MatLegacySelectModule,
        MatLegacyCheckboxModule,
        MatIconModule,
        NgxMaskDirective,
        NgxMaskPipe
    ],
    declarations: [DemoComponent],
    exports: [DemoComponent],
    providers: [provideNgxMask()]
})
export class DemoModule { }