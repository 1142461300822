<div class="layout-grid">
    <div class="notification-banner__content">
        <div class="notification-banner__message">
            <mat-icon [svgIcon]="svgIcon" [ngClass]="(svgIcon ? 'teller-online-icon ' : '') + svgIconClass" *ngIf="icon || svgIcon" [color]="iconColor ?? ''">{{icon}}</mat-icon>
            <p [innerHTML]="message"></p>
        </div>
    
        <div class="notification-banner__actions">
            <button *ngIf="dismissAction && !dismissAction.href" type="button" mat-button [color]="dismissAction.color ?? 'accent'" (click)="onClick_dismissAction()">
                {{dismissAction.text}}
            </button>
            <a *ngIf="dismissAction && dismissAction.href" [routerLink]="dismissAction.href" [state]="dismissAction.hrefState" mat-button color="accent">
                {{dismissAction?.text}}
            </a>

            <button *ngIf="resolveAction?.click" type="button" mat-button [color]="resolveAction.color ?? 'accent'" (click)="onClick_resolveAction()">
                {{resolveAction?.text}}
            </button>
            <a *ngIf="resolveAction?.href" [routerLink]="resolveAction.href" [state]="resolveAction.hrefState" mat-button color="accent">
                {{resolveAction?.text}}
            </a>
        </div>
    </div>
</div>