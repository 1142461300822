// Angular Files
import { EventEmitter, Injectable, Output, TemplateRef, ViewContainerRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SafeHtml } from '@angular/platform-browser';

// Other External Files
import { BehaviorSubject, Subscription } from 'rxjs';

// Teller Online Files
import { CoreModule } from 'apps/public-portal/src/app/core/core.module';
import { TellerOnlineAppService } from 'teller-online-libraries/core';

@Injectable({
    providedIn: CoreModule
})
export class ModalService {

    /** An optional fab that can be displayed in the lower right corner overtop of the modal */
    private _fab: Fab = null;

    /** Reference to the matsidenav that contains the modal in the html */
    private _modal: MatSidenav = null;

    /** The view container ref for the modal */
    private _containerRef: ViewContainerRef = null;

    /** Whether or not the modal can actually be activated (opened/closed) - meaning it has content that can be shown */
    private _enabled: boolean = false;

    /** Flag indicating whether or not this modal should only show up if the user is logged in */
    private _authenticationRequired: boolean = false;

    /** Subscription used for custom action to perform when modal closed 
     * (utilized to enable escape to perform same close action) 
     * Also used to indicate to external listenered when the modal is closed*/
    private _closedSubscription: Subscription;
    
    /** Subscription used to indicate to external listenered when the modal is opened */
    private _openedSubscription: Subscription;

    private _toggleAppRootClass: boolean = false;
    
    private _modalToggled = new BehaviorSubject<boolean>(false);
    public modalToggled$ = this._modalToggled.asObservable();

    constructor(private appService: TellerOnlineAppService) {
        this.appService.breakpoints$.subscribe(() => {
            if(!this.appService.isNarrow) {
                this.close();
            } else {
                this.open();
            }
        });
    }

    public get fab() {
        return this._fab;
    }

    public get opened() {
        return this._modal?.opened ?? false;
    }

    public get enabled() {
        return this._enabled;
    }

    public get authenticationRequired() {
        return this._authenticationRequired;
    }

    /** Sets the reference to the sidenav to be used for the modal */
    public setModal(modal: MatSidenav, containerRef: ViewContainerRef) {
        this._modal = modal;
        this._containerRef = containerRef;
    }

    /** Instantiates content for the full page modal, destroying any previously existing full page modal*/
    public addModal(options: {
        content: SafeHtml | TemplateRef<any>, 
        closeAction?: Function, 
        authenticationRequired?: boolean,
        toggleAppRootClass?: boolean
    } = {
        content: null,
        closeAction: null,
        authenticationRequired: false,
        toggleAppRootClass: false
    }) {
        this.destroyModal();
        this._enabled = true;

        this._authenticationRequired = options.authenticationRequired;
        this._toggleAppRootClass = options.toggleAppRootClass;

        // Add the content to the modal sidenav
        if (options.content instanceof TemplateRef) {
            this._containerRef.createEmbeddedView(<TemplateRef<any>>options.content);
        } else {
             this._containerRef.element.nativeElement.innerHTML = options.content;
        }

        // ensures the custom close action will be performed when the modal is closed (e.g. via .close() or via escape)
        this._closedSubscription = this._modal.closedStart.subscribe(() => {
            this._modalToggled.next(false);
            if(this.appService.isNarrow && options.closeAction) options.closeAction();
        });
    }

    /** Destroys the current existing full page modal 
     * -- removes the content from the modal sidenv element
     * -- disables the modal
     * -- removes an existing fab tied to the modal
     * -- unsubscribes from the close action
     * -- if close is true, will also close the modal before destroying it
     */
    public destroyModal(close = false) {
        if(close) this.close();
        this._containerRef.remove();
        this._enabled = false;
        this._fab = null;
        if(this._openedSubscription) this._openedSubscription.unsubscribe();
        if(this._closedSubscription) this._closedSubscription.unsubscribe();
    }

    /** Adds a custom fab button to be shown when the modal is open */
    public addFab(fab: {icon: string, action: Function, ariaLabel: string, iconAriaLabel: string}) {
        if(!this._enabled) return;
        this._fab = new Fab();
        this._fab.icon = fab.icon;
        this._fab.action = fab.action;
        this._fab.ariaLabel = fab.ariaLabel;
        this._fab.iconAriaLabel = fab.iconAriaLabel;
    }

    /** Opens the modal */
    public open() {
        if(!this._enabled) return;
        if(this._toggleAppRootClass) this._modalToggled.next(true);
        this._modal.open();
    }

    /** Closes the modal. Will perform the custom close action as well (automatically via subscribe) */
    public close() {
        if(!this._enabled) return;
        this._modalToggled.next(false);
        this._modal.close();
    }
}

class Fab {
    /** The name of the svgIcon to use (should be a teller-online-icon name) */
    icon: string;

    /** The aria-label to use on the icon */
    iconAriaLabel: string;

    /** The aria label to use on the fab itself */
    ariaLabel: string;

    /** The action to be performed when the fab is clicked */
    action: Function;
}