// Angular Files
import { Injectable } from '@angular/core';


// Other External Files
import { Observable } from 'rxjs';

// Teller Online Files
import { CoreModule } from 'apps/public-portal/src/app/core/core.module';
import { AuthService } from './auth.service';
import { CartService } from './cart.service';

// Teller Online Library Files
import { CartStatusEnum, TellerOnlineAppService } from 'teller-online-libraries/core';
import { TellerOnlineMessageService } from 'teller-online-libraries/shared';

@Injectable({
    providedIn: CoreModule
})
export class PendingChangesGuardService {

    constructor(
        private messageService: TellerOnlineMessageService,
        private appService: TellerOnlineAppService,
        private authService: AuthService,
        private cartService: CartService
    ) { }

    async canDeactivate(featureComponent: FeatureCanDeactivate): Promise<boolean> {
        let featurePrompt = {
            message: 'You have unsaved changes. Press "No" to go back and save these changes, or "Yes" to lose these changes.',
            title: 'Lose Unsaved Changes?'
        }

        if (featureComponent && this.authService.authDetails && this.cartService.cartGuid) {
            let featureDeactivate = featureComponent.canDeactivate(featurePrompt);
            let canDeactivate: boolean;
            // if there are no pending changes, just allow deactivation; else confirm first
            if (featureDeactivate) {
                canDeactivate = true;
            } else {
                // Turn off loading before we prompt or the prompt won't work
                this.appService.finishLoading();
                // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
                // when navigating away from your angular app, the browser will show a generic warning message
                // see http://stackoverflow.com/a/42207299/7307355
                canDeactivate = await this.messageService.prompt(featurePrompt.message, featurePrompt.title);
            }
            // turn loading back on if we're leaving
            if (canDeactivate) this.appService.triggerLoading("Loading...");

            // If we can deactivate and we're on the checkout page, open the cart back up before we leave
            if (canDeactivate && this.appService.checkoutPage) {
                if (this.cartService.cartGuid) await this.cartService.updateStatus(CartStatusEnum.Open);
            }

            return canDeactivate;
        }
        return true;
    }
}
export interface FeatureCanDeactivate {
    canDeactivate: (featurePrompt: { message: string, title: string }) => boolean | Observable<boolean>;
}
