// Angular Files
import { Injectable } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';

// Other External Files
import { filter, pairwise } from 'rxjs/operators';
import { TellerOnlineWindowService } from 'teller-online-libraries/shared';

@Injectable({
    providedIn: 'root'
})
export class TellerOnlineHistoryService {
    previousRedirectableUrl = '';
    previousUrl = '';
    signedOut = false;

    constructor(private router: Router, private windowService: TellerOnlineWindowService) {
        this.router.events
        .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
        .subscribe((events: RoutesRecognized[]) => {
            let url = events[0].urlAfterRedirects;
            this.previousUrl = url;

            // If the current page is not sign-up or sign-in, remember it to redirect so we can redirect back
            if (url != '/sign-in' && url != 'sign-in' &&
                url != '/sign-up' && url != 'sign-up' &&
                url != '/hold-page' && url != 'hold-page') {
                this.setPreviousRedirectableUrl(url);
            }
        });
    }

    clearPreviousRedirectableUrl() {
        this.setPreviousRedirectableUrl(null);
    }

    getLocalPreviousRedirectableUrl() {
        return this.windowService.getLocalStorageItem("previousRedirectableUrl");
    }

    setPreviousRedirectableUrl(value) {
        this.previousRedirectableUrl = value;
        this.windowService.setLocalStorageItem("previousRedirectableUrl", value)
    }
}