// Angular Files
import { Injectable } from '@angular/core';

// Teller Online Core Files
import {
    SiteCustomizationDto,
    AppConfigurationDto,
    SiteMetadataDto,
    MenuItemDto,
    PortalFeatureDto,
    SiteMetadataApiClient,
    FlagTypeEnumDto,
    NoticeTypeEnumDto,
    NoticeDto
} from '../api/CoreWebApi';

// Teller Online Services
import { TellerOnlineNotificationBanner, TellerOnlineNotificationBannerService } from './notification-banner.service';

@Injectable({
    providedIn: 'root'
})
export class TellerOnlineSiteMetadataService {
    private _metadata: SiteMetadataDto;
    loaded: boolean;

    static features: {
        authenticatedUsers: PortalFeatureDto,
        tellerOnlineLite: PortalFeatureDto
    };

    constructor(
        private siteMetadataApiClient: SiteMetadataApiClient,
        private notificationBannerService: TellerOnlineNotificationBannerService
    ) { }

    init(baseUrl: string) {
        SiteMetadataApiClient.init(baseUrl);
    }

    public async load() {
        this._metadata = await this.siteMetadataApiClient.getSiteMetadata().toPromise();
        this.loaded = true;
    }

    public get appConfiguration(): AppConfigurationDto {
        if (!this._metadata) {
            throw new Error("Site metadata is not loaded!");
        }

        return this._metadata.appConfiguration;
    }

    public get portalFeatures(): {authenticatedUsers: PortalFeatureDto, tellerOnlineLite: PortalFeatureDto} {
        if (!this._metadata) {
            throw new Error("Site metadata is not loaded!");
        }

        if(!TellerOnlineSiteMetadataService.features) {
            let features = {
                authenticatedUsers: null,
                tellerOnlineLite: null
            };

            // Change from an array to an key value list so the items can be referenced by name rather than index
            this._metadata.portalFeatures.forEach(feature => {
                features[feature.name.replace(feature.name[0], feature.name[0].toLowerCase())] = feature;
            });

            TellerOnlineSiteMetadataService.features = features;
        }

        return TellerOnlineSiteMetadataService.features;
    }

    public get customization(): SiteCustomizationDto {
        if (!this._metadata) {
            // throw new Error("Site metadata is not loaded!");
            return null;
        }

        return this._metadata.siteCustomization;
    }

    public get menuItems(): MenuItemDto[] {
        if (!this._metadata) {
            // throw new Error("Site metadata is not loaded!");
            return null;
        }

        return this._metadata.menuItems;
    }

    public get authenticationEnabled(): boolean {
        return this.portalFeatures.authenticatedUsers.enabled;
    }

    public get tellerOnlineLiteEnabled(): boolean {
        return this.portalFeatures.tellerOnlineLite?.enabled ?? false;
    }

    public get tenant(): string {
        return this._metadata.tenantName.toLowerCase() ?? null;
    }

    public get activeNotices() {
        let today = new Date();
        return this._metadata.notices.filter(n => n.startTimeUtc <= today && n.endTimeUtc > today);
    }

    public get upcomingNotices() {
        let today = new Date();

        return this._metadata.notices.filter(n => {
            let timeDifference = n.startTimeUtc.getTime() - today.getTime();
            // Ensure that the notice start time is less than a day in the future at most
            return timeDifference > 0 && timeDifference < 24 * 60 * 60 * 1000;
        });
    }

    public parseMenuNotices(menuItem: number, noticeType: FlagTypeEnumDto = null) {
        let activeMenuFlags = this.activeNotices.filter(n => n.integrationFlags.find(f => f.pageId == menuItem));

        if (noticeType) {
            activeMenuFlags = activeMenuFlags.filter(n => n.integrationFlags.find(f => f.pageId == menuItem && f.flagType == noticeType))
        }

        return activeMenuFlags?.length > 0;
    }

    public createNoticeBanner(notice: NoticeDto) {
        let banner = new TellerOnlineNotificationBanner()
        let bannerCount = this.notificationBannerService.banners.length;
        banner.tag = (`notice-${bannerCount + 1}`).toString();
        banner.message = notice.message;
        banner.resolveAction = {
            text: ""
        };
        banner.dismissAction = {
            text: 'Dismiss',
            color: ''
        };

        switch (notice.noticeType) {
            case NoticeTypeEnumDto.Info:
                banner.bannerClass = `notification-banner--info`;
                banner.svgIcon = "info";
                banner.svgIconClass = "teller-online-icon--info";
                break;
            case NoticeTypeEnumDto.Warning:
                banner.bannerClass = `notification-banner--caution`;
                banner.svgIcon = "attention";
                banner.svgIconClass = `teller-online-icon--caution`;
                break;
            case NoticeTypeEnumDto.Alert:
                banner.bannerClass = `notification-banner--warn`;
                break;
        }

        if (this.notificationBannerService.banners.length > 0)
            // Replace 'Dismiss' with 'Next' on the action button of the final banner in the list. The new banner will now have the 'Dismiss' text
            this.notificationBannerService.updateBanner(this.notificationBannerService.banners.find(b => b.dismissAction.text == "Dismiss").tag, { dismissAction: { text: "Next" } });
        this.notificationBannerService.addBanner(banner);
    }
}
