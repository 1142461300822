// Angular Files
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

// Teller Online Files
import { CoreModule } from 'apps/public-portal/src/app/core/core.module';
import { AuthService } from 'apps/public-portal/src/app/core/services/auth.service';
import { ModalService } from 'apps/public-portal/src/app/core/services/modal.service';

// Teller Online Library Files
import { TellerOnlineSiteMetadataService, TellerOnlineXsrfService } from 'teller-online-libraries/core';

@Injectable({
    providedIn: CoreModule
})
export class SessionAuthGuardService  {

    // If the url is any of these values, we can't be signed in and be on that page
    private _requiresAnonymousPages = [
        "sign-in",
        "sign-up",
        "reset-password",
        "forgot-password"
        
    ];

    // If the url contains any of these values, we can't be unauthenticated and be on that page
    private _requiresAuthenticationPages = [
        "profile",
        "dashboard",
        "notification-settings",
        "change-password",
        "payment-history",
        "payment-methods",
        "payment-methods/:id",
        "personal-info",
        "upcoming-payments",
        "user-history"
    ];

    constructor(
        private router: Router,
        private authService: AuthService,
        private modalService: ModalService,
        private siteMetadataService: TellerOnlineSiteMetadataService,
        private xsrfService: TellerOnlineXsrfService
    ) { }

    canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
        return this.canActivate(route);
    }

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
        const isAnonymousPage = this._requiresAnonymousPages.includes(route.routeConfig.path);
        const isAuthenticatedPage = this._requiresAuthenticationPages.includes(route.routeConfig.path);

        if (this.siteMetadataService.authenticationEnabled) {
            // Refresh the XSRF token to make sure an expired antiforgery token does not restrict us from retrieving auth details.
            await this.xsrfService.loadXsrfToken();

            let authDetails = await this.authService.getAuthDetails().toPromise();

            const isLoggedInClient = this.authService.isSignedIn;
            const isLoggedInServer = authDetails && authDetails.isSignedIn;

            await this.authService.updateAuthDetails(authDetails);

            // If server auth is not valid and client auth is out of sync, sign the user out (no redirect).
            if (isLoggedInClient && !isLoggedInServer) {
                // If there's an authentication restricted modal enabled right now, destroy it
                if(this.modalService.enabled && this.modalService.authenticationRequired) this.modalService.destroyModal(true);
                await this.authService.signOut();
            }

            // We're on a url that requires you NOT to be signed in, but you are, redirect out of here!
            if (isLoggedInServer && isAnonymousPage) {
                return this.router.parseUrl('/');
            }

            // We're on a url that requires you TO be signed in, but you aren't, redirect out of here!
            if (!isLoggedInServer && isAuthenticatedPage) {
                return this.router.parseUrl('/sign-in');
            }

            // If we made it this far, we can activate this route
            return true;
        } else if (isAnonymousPage || isAuthenticatedPage) {
            // Anonymous pages are associated with authenticated users as are pages that require auth.
            // Since authenticated users feature is not enabled, take the user away from any of these pages.
            return this.router.parseUrl('/');
        } else {
            return true;
        }
    }
}
