// Angular Files
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

// Other External Files
import { Observable } from 'rxjs';

// Teller Online Core Files
import { XsrfTokenApiClient } from '../api/CoreWebApi';

@Injectable({
    providedIn: 'root'
})
export class TellerOnlineXsrfService implements HttpInterceptor {
    constructor(
        private xsrfTokenApiClient: XsrfTokenApiClient
    ) {}

    init(baseUrl: string) {
        XsrfTokenApiClient.init(baseUrl);
    }

    async loadXsrfToken() {
        localStorage.xsrfToken = await this.xsrfTokenApiClient.getXsrfToken().toPromise();
    }

    // 'Cache-Control': 'no-cache',
    // 'Pragma': 'no-cache',
    // Intercept all HTTP requests to add the XSRF token as a header on every request.
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (localStorage.xsrfToken && !req.url.includes('errorLog') && !req.url.includes('xsrf-token')) {
            const clonedRequest = req.clone({
                setHeaders: {
                    'X-XSRF-TOKEN': localStorage.xsrfToken,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                }
            });
            return next.handle(clonedRequest);
        } else {
            return next.handle(req);
        }
    }
}
