// Angular Files
import {
    Component,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    OnInit,
    ViewChild,
    Inject,
    OnDestroy,
    AfterViewInit
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DOCUMENT } from '@angular/common';

// Angular Material Files
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

// Other External Files
import { cloneDeep } from 'lodash';

// Teller Online Files
import { ItemDto, ItemPaymentOptionDto } from 'apps/public-portal/src/app/core/api/PublicPortalApiClients';

// Teller Online Library Files
import { TellerOnlineValidationService, RestrictFocusDirective } from 'teller-online-libraries/shared';
import { TellerOnlineAppService } from 'teller-online-libraries/core';

@Component({
    selector: 'app-select-payment',
    templateUrl: './select-payment.component.html',
    styleUrls: ['./select-payment.component.scss'],
    host: {
        class: 'select-payment'
    }
})
export class SelectPaymentComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() item: ItemDto;
    /** A list of options that are causing a conflict in the dropdown and will be displayed with a red border */
    @Input() conflictingOptions: SelectedPaymentOption[];
    @Output() closePopup = new EventEmitter<boolean>();
    @Output() updateCartItem = new EventEmitter<any>();
    @Output() addToCart = new EventEmitter<any>();

    @ViewChild("customAmountInput") customAmountInput: ElementRef;

    /** A reference to the restrict focus directive so that the menu is accessible using tab without closing it while still trapping focus */
    @ViewChild(RestrictFocusDirective) private _restrictFocusDirective: RestrictFocusDirective;
    
    /** The element that originally had focus, so we can restore to it later */
    private _activeElement: Element;

    /** The amount to be saved to a custom payment */
    public customAmount: string = "";

    /** Toggle to indicate if the Custom Amount field should be shown when not selected */
    public showCustomAmountField: boolean = false;
    public paymentOptions: SelectedPaymentOption[];

    public customAmountForm: UntypedFormGroup = new UntypedFormGroup({
        "Amount": new UntypedFormControl(0)
    });

    constructor(
        private matBottomSheetRef: MatBottomSheetRef,
        public validationService: TellerOnlineValidationService,
        public appService: TellerOnlineAppService,
        @Inject(DOCUMENT) private document: Document,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data
    ) {
        if (this.data) this.item = this.data;
    }

    ngOnInit() {
        // Save the actively focused element
        this._activeElement = this.document.activeElement;

        this.paymentOptions = cloneDeep(this.item?.paymentOptions);
        let selectedOption = this.paymentOptions?.find(i => i.name == this.item.selectedPaymentOption?.name && i.amount == this.item.selectedPaymentOption?.amount);
        if(selectedOption) selectedOption.selected = true;

        let customOption;

        if(!this.conflictingOptions?.length) {
            customOption = this.paymentOptions?.find(i => i.isCustomAmount);
            this.customAmount = customOption?.amount?.toFixed(2) ?? "";
        } else {
            selectedOption.selected = false;
            customOption = this.paymentOptions.find(i => i.isCustomAmount);
            // Update the custom amount label to indicate it's a new option
            if(customOption) customOption.label = "New " + customOption.label;

            this.conflictingOptions.forEach(option => {
                let paymentOption = cloneDeep(option);
                // If this isn't a custom amount, find it in the existing list and set it's error to true
                if(!paymentOption.isCustomAmount) {
                    this.paymentOptions.find(i => i.name == paymentOption.name).error = true;
                // If this is a custom amount, set it's error to true, and add to the list with a new label
                } else {
                    paymentOption.error = true;
                    paymentOption.label = "Previous " + paymentOption.label;
                    this.paymentOptions.unshift(paymentOption);
                }
            });
        }

        // if we have a custom option, add min/max validation on it
        // because we can't use regular forms since we don't want it to be type=number
        if(customOption) {
            let control = this.customAmountForm.controls["Amount"];
            control.addValidators([
                Validators.max(customOption.maxAmount),
                Validators.min(customOption.minAmount)
            ]);
            control.setValue(this.customAmount);
        }
    }

    ngAfterViewInit() {
        this._focusCustomAmount();
    }

    ngOnDestroy() {
        // Restore focus to the previously focused element
        if (this._activeElement) {
            (this._activeElement as HTMLElement)?.focus?.() 
        }
    }

    /** When the element receives focus, auto select all the text inside the box for edit */
    onFocusIn_selectText() {
        setTimeout(() => {
            this.customAmountInput.nativeElement.select();
        }, 1);
    }

    /** params are: item, paymentOption, customAmount */
    onClick_updateCartItem(item, paymentOption: SelectedPaymentOption, customAmount?: number) {
        // Remove all the custom amount error'd options, if there were any
        this.paymentOptions = this.paymentOptions?.filter(i => !(i.error && i.isCustomAmount));

        // If this is a previous option and it's custom, copy the amount to the real custom amount option
        if(paymentOption?.error && paymentOption?.isCustomAmount) {
            let customAmountOption = this.paymentOptions?.find(i => i.isCustomAmount);
            customAmountOption.amount = customAmount = paymentOption.amount;
            // Update the label for the custom payment option
            customAmountOption.label = customAmountOption.label.replace("New ", "");
            // Update the option we're saving to be the custom one
            paymentOption = customAmountOption;
        }

        this.updateCartItem.emit([item, paymentOption, customAmount]);
        this.closePopup.emit();
        this.matBottomSheetRef.dismiss?.({item: item, paymentOption: paymentOption, customAmount: customAmount, action: 'update'});
    }

    /** params are: item, paymentOption, customAmount */
    onClick_addToCart(...args) {
        this.addToCart.emit(args);
        this.closePopup.emit();
        this.matBottomSheetRef.dismiss?.({item: args[0], paymentOption: args[1], customAmount: args[2], action: 'add'});
    }

    onClick_showCustomAmount(e) {
        e.stopPropagation();
        this.showCustomAmountField = true;
        this._focusCustomAmount();
    }

    onSubmit_customPaymentAmount() {
        if(this.validationService.runValidation(this.customAmountForm)) {
            this.customAmount = this.customAmountForm.controls["Amount"].value;

            let paymentOption = this.paymentOptions?.find(i => i.isCustomAmount && !i.error);
            if (!this.item.amountInCart) {
                this.onClick_addToCart(this.item, paymentOption, Number(this.customAmount));
            } else {
                this.onClick_updateCartItem(this.item, paymentOption, Number(this.customAmount));
            }
            this.closePopup.emit();
        }
    }

    /** Prevent tab from closing the menu, and use the focus trap instead */
    onKeyDown_handleFocus(e: KeyboardEvent) {
        if (e.key === "Tab") {
            e.stopPropagation();
        }
        
        this._restrictFocusDirective.onKeyDown(e);
    }

    private _focusCustomAmount() {
        /* For some reason the field doesn't exist yet when this is fired so calling .focus() on it does nothing
        so we'll wait 1 ms so it'll exist and then we'll fire it
        */
        setTimeout(() => {
            if (this.customAmountInput) {
                this.customAmountInput.nativeElement.focus();
            }
        }, 1);
    }
}

export class SelectedPaymentOption extends ItemPaymentOptionDto {
    error?: boolean;
    selected?: boolean;
}