export class StateOrProvince {
    constructor(code: string, name: string, countryCode: string) {
        this.code = code;
        this.name = name;
        this.countryCode = countryCode;
    }

    code: string
    name: string
    countryCode: string

    public static GetByCountryCode(countryCode: string) : StateOrProvince[] {
        return STATES_AND_PROVINCES.filter(x => x.countryCode == countryCode);
    }

    public static GetByCode(stateCode: string) {
        return STATES_AND_PROVINCES.find(s => s.code === stateCode);
    }

    public static GetByName(stateName: string) {
        return STATES_AND_PROVINCES.find(s => s.name === stateName);
    }

    public static GetByNameAndCountryCode(stateName: string, countryCode: string) {
        return STATES_AND_PROVINCES.find(s => s.name === stateName && s.countryCode == countryCode);
    }
}

export const STATES_AND_PROVINCES: StateOrProvince[] = [
    {name: 'Alabama', code: 'AL', countryCode: 'US'},
    {name: 'Alaska', code: 'AK', countryCode: 'US'},
    {name: 'Arizona', code: 'AZ', countryCode: 'US'},
    {name: 'Arkansas', code: 'AR', countryCode: 'US'},
    {name: 'California', code: 'CA', countryCode: 'US'},
    {name: 'Colorado', code: 'CO', countryCode: 'US'},
    {name: 'Connecticut', code: 'CT', countryCode: 'US'},
    {name: 'Delaware', code: 'DE', countryCode: 'US'},
    {name: 'Florida', code: 'FL', countryCode: 'US'},
    {name: 'Georgia', code: 'GA', countryCode: 'US'},
    {name: 'Hawaii', code: 'HI', countryCode: 'US'},
    {name: 'Idaho', code: 'ID', countryCode: 'US'},
    {name: 'Illinois', code: 'IL', countryCode: 'US'},
    {name: 'Indiana', code: 'IN', countryCode: 'US'},
    {name: 'Iowa', code: 'IA', countryCode: 'US'},
    {name: 'Kansas', code: 'KS', countryCode: 'US'},
    {name: 'Kentucky', code: 'KY', countryCode: 'US'},
    {name: 'Louisiana', code: 'LA', countryCode: 'US'},
    {name: 'Maine', code: 'ME', countryCode: 'US'},
    {name: 'Maryland', code: 'MD', countryCode: 'US'},
    {name: 'Massachusetts', code: 'MA', countryCode: 'US'},
    {name: 'Michigan', code: 'MI', countryCode: 'US'},
    {name: 'Minnesota', code: 'MN', countryCode: 'US'},
    {name: 'Mississippi', code: 'MS', countryCode: 'US'},
    {name: 'Missouri', code: 'MO', countryCode: 'US'},
    {name: 'Montana', code: 'MT', countryCode: 'US'},
    {name: 'Nebraska', code: 'NE', countryCode: 'US'},
    {name: 'Nevada', code: 'NV', countryCode: 'US'},
    {name: 'New Hampshire', code: 'NH', countryCode: 'US'},
    {name: 'New Jersey', code: 'NJ', countryCode: 'US'},
    {name: 'New Mexico', code: 'NM', countryCode: 'US'},
    {name: 'New York', code: 'NY', countryCode: 'US'},
    {name: 'North Carolina', code: 'NC', countryCode: 'US'},
    {name: 'North Dakota', code: 'ND', countryCode: 'US'},
    {name: 'Ohio', code: 'OH', countryCode: 'US'},
    {name: 'Oklahoma', code: 'OK', countryCode: 'US'},
    {name: 'Oregon', code: 'OR', countryCode: 'US'},
    {name: 'Pennsylvania', code: 'PA', countryCode: 'US'},
    {name: 'Rhode Island', code: 'RI', countryCode: 'US'},
    {name: 'South Carolina', code: 'SC', countryCode: 'US'},
    {name: 'South Dakota', code: 'SD', countryCode: 'US'},
    {name: 'Tennessee', code: 'TN', countryCode: 'US'},
    {name: 'Texas', code: 'TX', countryCode: 'US'},
    {name: 'Utah', code: 'UT', countryCode: 'US'},
    {name: 'Vermont', code: 'VT', countryCode: 'US'},
    {name: 'Virginia', code: 'VA', countryCode: 'US'},
    {name: 'Washington', code: 'WA', countryCode: 'US'},
    {name: 'West Virginia', code: 'WV', countryCode: 'US'},
    {name: 'Wisconsin', code: 'WI', countryCode: 'US'},
    {name: 'Wyoming', code: 'WY', countryCode: 'US'},
    {name: 'Alberta', code: 'AB', countryCode: 'CA'},
    {name: 'British Columbia', code: 'BC', countryCode: 'CA'},
    {name: 'Manitoba', code: 'MB', countryCode: 'CA'},
    {name: 'New Brunswick', code: 'NB', countryCode: 'CA'},
    {name: 'Newfoundland and Labrador', code: 'NL', countryCode: 'CA'},
    {name: 'Northwest Territories', code: 'NT', countryCode: 'CA'},
    {name: 'Nova Scotia', code: 'NS', countryCode: 'CA'},
    {name: 'Nunavut', code: 'NU', countryCode: 'CA'},
    {name: 'Ontario', code: 'ON', countryCode: 'CA'},
    {name: 'Prince Edward Island', code: 'PE', countryCode: 'CA'},
    {name: 'Quebec', code: 'QC', countryCode: 'CA'},
    {name: 'Saskatchewan', code: 'SK', countryCode: 'CA'},
    {name: 'Yukon', code: 'YT', countryCode: 'CA'}
]
