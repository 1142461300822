// Angular Files
import { Injectable } from '@angular/core';

/**
 * This service is a wrapper for the window object so performing functions such as window.location can be mocked out in unit tests
 */
@Injectable()
export class TellerOnlineWindowService {
    constructor() {
    }

    /** window.location.href = &lt;url&gt; */
    public navigateExternalUrl(url: string) {
        window.location.href = url;
    }

    public get location() {
        return window.location;
    }

    /** Sometimes we need to access the current url to check before we have access to the router, this will retrieve the url using raw javascript for us */
    public get url() {
        return window.location.href;
    }

    /** Check the innerWidth of the window for determining things like desktop or not */
    public get width() {
        return window.innerWidth;
    }

    public get visualViewport() {
        return window.visualViewport;
    }

    /** Indicates whether the application is running the Teller Online Lite context */
    public get isTOL(): boolean {
        return this.url.includes("/TOL");
    }

    /** Literally just a wrapper for the window.addEventListener method */
    public addEventListener(type, listener) {
        window.addEventListener(type, listener);
    }

    public getLocalStorageItem(key: string) {
        return window.localStorage.getItem(key);
    }

    public setLocalStorageItem(key: string, value: string) {
        return window.localStorage.setItem(key, value);
    }

    public removeLocalStorageItem(key: string) {
        return window.localStorage.removeItem(key);
    }

    public getSessionStorageItem(key: string) {
        return window.sessionStorage.getItem(key);
    }

    public setSessionStorageItem(key: string, value: string) {
        return window.sessionStorage.setItem(key, value);
    }

    public removeSessionStorageItem(key: string) {
        return window.sessionStorage.removeItem(key);
    }

    public print() {
        window.print();
    }

    public open(url?: string | URL, target?: string, features?: string): WindowProxy | null {
        return window.open(url, target, features);
    }
}
