// Angular Files
import { NgModule } from '@angular/core';

// Teller Online Shared Files
import { TellerOnlineMessageService } from './message.service';
import { TellerOnlineValidationService } from './validation.service';
import { TellerOnlineWindowService } from './window.service';

@NgModule({
    providers: [
        TellerOnlineValidationService,
        TellerOnlineMessageService,
        TellerOnlineWindowService
    ]
})
export class ServicesModule { }