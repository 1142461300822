// Angular Files
import { Injectable, OnDestroy } from '@angular/core';

// Other External Files
import { Observable, BehaviorSubject, Subscription } from 'rxjs';

// Payment Integration Files
import { PaymentMethodTypeEnum, PaymentMethodTypeEnumConvertor } from 'apps/public-portal/src/app/payment-integrations';

// Teller Online Files
import {
    CartDto,
    ItemDto,
    CartApiClient,
    PostPaymentResponseDto,
    IAddCartEventRequestDto,
    AddCartEventRequestDto,
    CartStatusEnumDto,
    CartItemWarningDto,
    PaymentMethodTypeEnumDto,
    IErrorDto,
    CartResponseDto,
    CartPaymentDetailsDto,
    AddItemResponseDto,
    ItemPaymentOptionDto
} from 'apps/public-portal/src/app/core/api/PublicPortalApiClients';

// Teller Online Library Files
import {
    CartStatusEnum,
    CartStatusEnumConvertor,
    TellerOnlineAppService,
    TellerOnlineNotificationBannerService
} from 'teller-online-libraries/core';
import { TellerOnlineMessageService, TellerOnlineWindowService } from 'teller-online-libraries/shared';

@Injectable({
    providedIn: 'root'
})
export class CartService implements OnDestroy {
    // Updates are made locally to the _cart BehaviorSubject
    private _cart: BehaviorSubject<CartModel> = new BehaviorSubject(new CartModel());

    // The cart$ Observable can be subscribed to in order to automatically receive updates to the _cart object
    public readonly cart$: Observable<CartModel> = this._cart.asObservable();

    // Updates are made locally to the _itemAdded BehaviorSubject
    private _itemAdded: BehaviorSubject<boolean> = new BehaviorSubject(false);

    // The itemAdded$ Observable can be subscribed to in order to automatically receive updates to the _itemAdded object
    public readonly itemAdded$: Observable<boolean> = this._itemAdded.asObservable();

    private cartSubscription: Subscription;

    /** The order with which carts sent via cart.next have been sent in*/
    private _cartVersion: number = 0;

    /** Keep track of the last warning messages that were displayed in order to not display duplicates */
    private _lastDisplayWarnings: CartItemWarningDto[];

    /** A flag indicating that we tried to update the cart's status but there was no cart to update */
    private _statusUpdatePending: CartStatusEnum;

    private _previousCartPresent: boolean = false;

    public get previousCartPresent(): boolean {
        return this._previousCartPresent;
    }

    public get cartGuid(): string {
        return this.windowService.getLocalStorageItem('cartGuid');
    }

    constructor(
        private cartApiClient: CartApiClient,
        private appService: TellerOnlineAppService,
        private messageService: TellerOnlineMessageService,
        private windowService: TellerOnlineWindowService,
        private notificationBannerService: TellerOnlineNotificationBannerService
    ) {
        windowService.addEventListener('storage', this.onWindow_storage);

        this._initializeCart();
    }

    private _initializeCart() {
        /* If we don't have a cart guid, or we're on the processing page, or the TOL context is loading, get out of here (don't make a request for the cart)
                The processing page is a "placeholder" page that doesn't require the cart in order to render as it is just a go between
                to verify the cart guid given in the url has been processed and this page never actually does anything with the cart in the session.
                We don't need the cart while the TOL context is loading because the cart is reset during this process
        */
        if (!this.cartGuid ||
            this.windowService?.url?.includes("checkout/processing") ||
            this.windowService?.isTOL) {
            return;
        }

        this.cartApiClient.getCart(this.cartGuid, PaymentMethodTypeEnumDto.Undetermined).subscribe(response => {
            this._processCartResponse(response);
        });

        this.cartSubscription = this.cart$.subscribe(cart => {
            this._handleCartSubscription(cart);
        });
    }

    ngOnDestroy() {
        if(this.cartSubscription) this.cartSubscription.unsubscribe();
    }

    public async retrieveUserCart() {
        let response = await this.cartApiClient.retrieveUserCart(this.cartGuid).toPromise();

        await this._processCartResponse(response);
    }

    /** Retrieve a fresh version of the cart from the DB (synced with Teller),
     * returns false if the cart requires user action */
    public async refreshCart(cartGuid, paymentMethodType?: PaymentMethodTypeEnum, allowConfirmationPrompt = true) {
        /** Whether or not the action that triggered this should proceed based on warnings
         * (if action doesn't care, the value is not used) */
        let proceedWithAction = true;

        // Set to undetermined if it was not provided as a parameter,
        // since it is required in the API
        if (!paymentMethodType) {
            paymentMethodType = PaymentMethodTypeEnum.Undetermined;
        }

        // Only refresh the cart if the guid we want is the same as the one in the session
        if(cartGuid == this.cartGuid) {
            let response = await this.cartApiClient.getCart(cartGuid,
                PaymentMethodTypeEnumConvertor.toDto(paymentMethodType)).toPromise();

            // If we're calling from a place where we care about the cart size,
            // take the cart size into account when we call _processCartResponse
            if (allowConfirmationPrompt) {
                // If there's no more items in the cart, proceedWithAction should be false
                proceedWithAction = await this._processCartResponse(response,
                    response?.cart?.items?.length > 0, response?.cart?.items?.length == 0);
            } else {
                proceedWithAction = await this._processCartResponse(response);
            }
        }

        return proceedWithAction;
    }

    // Public Properties and Methods
    public cartExists()
    {
        return this.cartGuid && ((!this.loadingCart && this._cart.getValue()) || this.loadingCart);
    }

    /**A snapshot of the cart at this point in time. Do not use this for binding */
    public get cartSnapshot()
    {
        return this._cart.getValue();
    }

    // This is for updating properties of the cart, not for adding, removing, or updating items
    // Use the respective methods for those
    public async updateCart(properties) {
        // Get the existing cart object
        let cart = this._cart.getValue();

        // If "items" was passed as a property, make sure we remove it because we don't want to accidentally update items this way!
        if(properties.items) delete properties.items;

        // Copy any new values from the "properties" object to the existing cart object
        cart = Object.assign(cart, properties);

        // Store change in DB
        await this.cartApiClient.populateCartUserDetails(cart.cartId, cart.guestEmailAddress, !!cart.rememberPaymentMethod, cart.paymentMethodId ?? null).toPromise();

        // Send out the next version of cart to all subscribers (newly updated!)
        this._nextCart(cart);
    }

    // Set the cart back to open after it's been set to processing
    public async openCart() {
        // Get the existing cart object
        let cart = this._cart.getValue();

        cart.status = CartStatusEnumDto.Open;
        cart.cartStatus = CartStatusEnum.Open;

        // Store change in DB
        await this.cartApiClient.userSetCartOpen(cart.cartGuid).toPromise();
        
        // Send out the next version of cart to all subscribers (newly updated!)
        this._nextCart(cart);
    }

    public async addExternalItemToCart(item: any) {
        // let cart = this._cart.getValue();

        // if (!cart || !cart.cartGuid) {
        //     await this._createCart();
        //     cart = this._cart.getValue();
        // }

        // item.cartGuid = cart.cartGuid;

        //return await this.searchApiClient.addExternalItemToCart(item).toPromise();

        // TEMPORARY
        return true;
    }

    /** Common logic to be used whenever adding a business system item to the cart. */
    public async addBsiToCart(item: ItemDto, paymentOption: ItemPaymentOptionDto, customAmount: number) {
        // copy the item so we can update it before sending it to the backend
        const addItem: ItemDto = ItemDto.fromJS(item.toJSON());

        addItem.selectedPaymentOption = paymentOption;
        addItem.amountInCart = customAmount ?? paymentOption.amount;
        addItem.selectedPaymentOption.amount = addItem.amountInCart; // Ensure these 2 match

        const response = await this.addItemToCart(addItem, true);

        // Fetch itemGuid generated by the backend
        item.itemGuid = response.newItem.itemGuid;
        // If it all worked out, update the search result to reflect the changes
        item.selectedPaymentOption = addItem.selectedPaymentOption;
        item.selectedPaymentOptions = [item.selectedPaymentOption];
        item.amountInCart = addItem.amountInCart;
        item.paymentOptions.find(option => option.label == item.selectedPaymentOption.label).amount = item.selectedPaymentOption.amount;
    }

    public async addItemToCart(item: ItemDto, overrideCheckout = false) {
        let cart = this._cart.getValue();
        let response: AddItemResponseDto;

        // If we don't currently have a cart, add item will create one
        await this._removeCartOnAlreadyPaidError(async () => {
            response = await this.cartApiClient.addItem(
                cart.cartGuid ?? null,
                this.appService.checkoutPage || overrideCheckout,
                item
            ).toPromise();
        });

        await this._processCartResponse(response.cart);

        this._itemAdded.next(true);

        return response;
    }

    public async removeItemFromCart(item: ItemDto, removeAll: boolean = true) {
        let cart = this._cart.getValue();
        let response: CartResponseDto;

        cart.items
            .filter(i => i.businessSystemItemKey == item.businessSystemItemKey)
            .forEach(i => this.notificationBannerService.dismissBanner("warning-" + i.itemGuid));

        await this._removeCartOnAlreadyPaidError(async () => {
            response = await this.cartApiClient.removeItem(
                cart.cartGuid,
                item.itemGuid,
                removeAll,
                this.appService.checkoutPage
            ).toPromise();
        });

        await this._processCartResponse(response);
    }

    public async updateItem(item: ItemDto, properties) {
        let cart = this._cart.getValue();
        let response: CartResponseDto;

        // Remove any existing warning as any update to this item should be valid and should clear existing warnings
        properties.warning = null;

        // Grab out the itemGuid and title to pass to the backend
        properties.itemGuid = item.itemGuid;
        properties.title = item.title;

        // Make a copy of the item from the properties
        let updateItem = new ItemDto(properties);
        // Update the selected payment option's amount to match the amount in the cart (so custom amount gets updated)
        if(updateItem.selectedPaymentOption) updateItem.selectedPaymentOption.amount = updateItem.amountInCart;

        await this._removeCartOnAlreadyPaidError(async () => {
            response = await this.cartApiClient.updateItem(
                cart.cartGuid,
                item.itemGuid,
                this.appService.checkoutPage,
                (updateItem).toJSON()
            ).toPromise();
        });

        await this._processCartResponse(response);

        return this._cart.getValue()?.items?.find(i => i.itemGuid == item.itemGuid);
    }

    public async refreshItem(itemGuid) {
        let item = await this.cartApiClient.refreshItem(this.cartGuid, itemGuid).toPromise();
        return item;
    }

    // If we have a cartGuid in the session but no cart object yet, we are loading the cart still
    // This is used in a lot of places to check if we should respond to the cart event because the first event thrown is always an empty CartDto
    public get loadingCart() {
        return this.cartGuid && !this._cart.getValue()?.cartGuid;
    }

    public reset() {
        this._removeCartGuid();
    }

    public async postPayment(authorizationTrackingNoCustomValueName?: string): Promise<PostPaymentResponseDto> {
        // return await this.cartApiClient.postPayment(this._cart.getValue().cartId).toPromise();
        return;
    }

    public async addEvent(request: IAddCartEventRequestDto) {
        await this.cartApiClient.addCartEvent(this._cart.getValue().cartId, new AddCartEventRequestDto(request)).toPromise();
    }

    public async updateStatus(status: CartStatusEnum) {
        let cart = this._cart.getValue();

        if(cart.cartGuid) {
            // ensure the pending flag is set to null
            this._statusUpdatePending = null;
            let cartStatusDto = CartStatusEnumConvertor.toDto(status);

            await this.cartApiClient.updateCartStatus(cart.cartGuid, cartStatusDto).toPromise();

            // Update the status locally and push it out
            cart.status = cartStatusDto;
            cart.cartStatus = status;
            this._nextCart(cart);
        // If we don't have a cart (yet), set a flag to update it later
        } else if(this.cartGuid) {
            this._statusUpdatePending = status;
        }
    }

    public async getCartPaymentDetails(cartGuid: string): Promise<CartPaymentDetailsModel> {
       let cart: CartPaymentDetailsModel = await this.cartApiClient.getCartPaymentDetails(cartGuid).toPromise();
       cart.cartGuid = cartGuid;
       return cart;
    }

    /** Assign selected payment options due to duplicate items in the cart */
    public assignDuplicates(searchResults: ItemDto[]): void {
        let cartSnapshot = this.cartSnapshot;
        
        searchResults?.forEach(result => {
            if (result.selectedPaymentOption) {
                result.selectedPaymentOptions = [result.selectedPaymentOption];
                result.duplicates = cartSnapshot.items?.filter(i => i.businessSystemItemKey == result.businessSystemItemKey && i.itemGuid != result.itemGuid);
                if (result.duplicates?.length > 0) {
                    result.duplicates.forEach(item => {
                        result.selectedPaymentOptions.push(item.selectedPaymentOption);
                    });
                }
            }
        });
    }

    public checkCartDependenciesSatisfied(item: ItemDto): boolean {
        return item.dependencies?.every(d => {
            let matchingItem = this.cartSnapshot?.items?.find(i => i.businessSystemItemKey == d.bsiKey && i.page.businessSystemNo == item.page.businessSystemNo);
            return matchingItem && matchingItem.amountInCart >= d.amount;
        });
    }

    /*  Iterate through all the search results for items in the cart, and update the items as needed */
    public static updateSearchResults(cart: CartModel, results: ItemDto[]) {
        results?.forEach(result => {
            // Find the item from the cart that matches this item in the search results
            // See PROD-62 and PROD-111 for reference.
            // Back-end generates a new itemGuid before adding to cart,
            // so we need to compare items using the same criteria that we use in the back-end
            let item = cart.items?.find(item => item.businessSystemItemKey == result.businessSystemItemKey
                && item?.page.businessSystemNo == result?.page.businessSystemNo);

            // If the item isn't found, set the amountInCart to null (this denotes it is not in the cart), and remove the payment option
            if (!item) {
                result.itemGuid = null;
                result.amountInCart = 0;
                result.selectedPaymentOption = null;
                // If the item is found, update the amountInCart and selectedPaymentOptions to reflect what has been chosen
            } else {
                result.itemGuid = item.itemGuid;
                result.amountInCart = item.amountInCart;
                result.selectedPaymentOption = item.selectedPaymentOption;
            }
        });
    }

    //
    // Event Handlers
    //
    onWindow_storage = async (e: StorageEvent) => {
        if (e.key === 'cartGuid') {
            if (!e.newValue) {
                this._nextCart(new CartDto());
            } else {
                this._previousCartPresent = true;
                const cart = this._cart.getValue();
                if (!cart || cart.cartGuid !== e.newValue) {
                    this.refreshCart(e.newValue);
                }
            }
        }
    }

    //
    // Private Methods

    /** If there are any warnings, maps the warnings to the appropriate cart items */
    private _associateWarnings(response) {
        if(response.warnings) {
            // Add the warnings to the items
            if(response.cart) {
                response.cart.items.map((item) => {
                    item.warning = response.warnings.filter((warning) => {
                        return warning.requiresAction && item.itemGuid == warning.itemGuid;
                    })[0];
                });
            }
        }
    }

    /** If there are warnings, renders a list of warnings in a modal dialog
     * if forceProceedFalse is true, it will force the return variable to be false,
     * indicating whatever triggered this action should not proceed
     */
    private async _displayWarnings(warnings: CartItemWarningDto[], requiresConfirmation = false, forceProceedFalse = false) {
        /** Whether or not the triggering process should proceed based on the received warnings/user response */

        //if the last warning was exactly the same skip it
        if (this._duplicateWarning(warnings)) {
            return;
        }

        let proceed = true;

        if (warnings && warnings.length > 0) {
            let title = "Cart Warnings";
            let html = '<ul>';
            warnings.forEach(warning => {
                html += `<li>${warning.message}</li>`
            });
            html += '</ul>';

            if (requiresConfirmation) {
                let requiresAction = warnings.filter(warn => warn.requiresAction);

                if (requiresAction.length > 0) {
                    html += "<p>Please resolve the above warnings to continue.<p>";
                    await this.messageService.alert(html, title);
                    proceed = false;
                } else {
                    html += "<p>Do you want to proceed and pay?</p>";
                    proceed = await this.messageService.prompt(html, title);
                }
            } else {
                await this.messageService.alert(html, title);
            }

            warnings.forEach(warning => {
                if (warning && warning.requiresAction) {
                    this.notificationBannerService.addBanner({
                        message: warning.message,
                        tag: 'warning-' +warning.itemGuid,
                        bannerClass: 'notification-banner--caution',
                        svgIcon: 'attention',
                        svgIconClass: 'teller-online-icon--caution',
                        resolveAction: {
                            href: '/cart/'+warning.itemGuid,
                            text: 'Resolve Now'
                        },
                        dismissAction: {
                            text: 'Resolve Later'
                        },
                        requiresAuthentication: true
                    });
                }
            });
        }

        if (forceProceedFalse) proceed = false;

        return proceed;
    }

    /** Prevent duplicate warning dialogs from being displayed by comparing warnings against the lastDisplayWarnings.
     * If warnings is null, _lastDisplayWarnings will be cleared out so the same warning could be displayed again
     */
    private _duplicateWarning(warnings: CartItemWarningDto[]): boolean {
        let isDuplicate = false;

        // If we have previously displayed a warning, and we have new warnings to display,
        // check if they are the same
        if (this._lastDisplayWarnings && warnings?.length > 0) {
            // Grab out just the messages from the previous warnings to compare against our new ones
            let warningMessages = this._lastDisplayWarnings.map(i => i.message);

            // If the number of warnings is the same and every new message was in the previous list of warnings
            // these are duplicate warnings
            isDuplicate =
                warnings?.length === this._lastDisplayWarnings?.length &&
                warnings.every(function (element) {
                    return warningMessages.includes(element.message);
                });
        }

        // If this wasn't a duplicate, update the warnings to match the new warnings that were received
        if (!isDuplicate) {
            this._lastDisplayWarnings = warnings?.length > 0 ? warnings : null;
        }

        return isDuplicate;
    }

    /** Associate any warnings returned in the response with the items,
     * display the warnings as either an alert (ok) or a prompt (yes/no) based on requiresConfirmation flag
     * if forceProceedFalse is true, it will force the return variable to be false,
     * indicating whatever triggered this action should not proceed
     */
    private async _processCartResponse(response, requiresConfirmation = false, forceProceedFalse = false) {
        this._associateWarnings(response);

        let proceedWithAction = await this._displayWarnings(response.warnings, requiresConfirmation, forceProceedFalse);

        if (response.cart) {
            this._nextCart(response.cart);
            this._setCartGuid(response.cart.cartGuid);
        } else if (this.cartGuid) {
            // If we did not get a cart back, remove the cartGuid
            this._removeCartGuid();
        }
        return proceedWithAction;
    }

    /** Handle any new updates to the cart via _cart.next() */
    private _handleCartSubscription(cart) {
        this.appService.consoleLog(this, cart, 'Cart Version: ' + cart?.version);

        //If this is an older version of the cart, we don't want it
        if(cart?.version && cart.version < this._cartVersion)
            return;

        // Only respond once we've finished loading the cart (the first event will always be an empty CartDto)
        // Also, only respond if we have a cartGuid in the session, if we don't, no need to respond to this event
        if(!this.loadingCart && this.cartGuid) {
            if(!cart) {
                this._removeCartGuid();
            } else {
                this._checkCartValid(cart);
                if(this._statusUpdatePending) {
                    this.updateStatus(this._statusUpdatePending);
                }
            }
        }
    }

    /** If the cart is in an "invalid" state (something the user can't do anything about), removes it from the session */
    private _checkCartValid(cart: CartModel) {
        const validCartStates = [
            CartStatusEnum.Open,
            CartStatusEnum.Processing,
            CartStatusEnum.CheckingOut
        ]

        let validCartState = validCartStates.includes(cart.cartStatus);

        // Check for a cartGuid because if we "don't have a cart" it's an empty dto which is still truthy
        if (cart && cart.cartGuid && !validCartState) {
            this._removeCartGuid();
        }
    }

    private async _createCart() {
        // await this.cartApiClient.createCart().toPromise().then((response) => {
        //     this._nextCart(response);
        //     this._setCartGuid(response.cartGuid);
        // });
    }

    /** Removes the cartGuid token from the session and sends out an updated empty cartDto */
    private _removeCartGuid() {
        // Only attempt to remove the guid (and push an empty cart) if we have a guid to remove
        if (this.cartGuid) {
            this._previousCartPresent = true;
            // Remove the guid first so that when we push the empty cart, things can respond appropriately
            this.windowService.removeLocalStorageItem('cartGuid');
            this._nextCart(new CartDto());
        }
    }

    private _setCartGuid(cartGuid: string) {
        this._previousCartPresent = true;
        this.windowService.setLocalStorageItem('cartGuid', cartGuid);
    }

    /** Sends out the next cart to the Behavior Subject */
    private _nextCart(cart: CartDto) {
        this._cartVersion++;
        if (cart) {
            cart.version = this._cartVersion;
        }

        let cartModel = this._generateItemsForDisplay(cart);

        if (cartModel.items?.find(i => i.warning?.requiresAction)) {
            cartModel.checkoutEnabled = false;
        }

        this._cart.next(cartModel);
    }

    private _generateItemsForDisplay(cart: CartDto) {
        let cartModel: CartModel = new CartModel(cart);

        if (cartModel) {
            cartModel.cartStatus = CartStatusEnumConvertor.fromDto(cart.status);
            // Generate the display list from all non-duplicates items
            if (cartModel.items) {
                cartModel.itemsForDisplay = [];

                cartModel.items.forEach(item => {
                    // If this BSI isn't already in the list, add the item to the list (or if it's a misc item, always add it)
                    if(!cartModel.itemsForDisplay.filter(i => i.businessSystemItemKey != null &&  i.businessSystemItemKey == item.businessSystemItemKey).length) {
                        cartModel.itemsForDisplay.push(item);
                    // If it's already in the list, we can't check out with this cart
                    } else {
                        cartModel.checkoutEnabled = false;
                    }
                });

                // Partition the cart into an object keyed on the configKey, valued with arrays of items
                cartModel.partitions = cartModel.itemsForDisplay.reduce((partitions, item) => {
                    const key = item.page.configKey ?? 'default';

                    // Initialize the array of items for the partition if it doesn't already exist.
                    partitions[key] ??= [];

                    // Push the item into the appropriate partition
                    partitions[key].push(item);

                    return partitions;
                }, {});
            }
        }
        return cartModel;
    }

    private async _removeCartOnAlreadyPaidError(method) {
        let error: IErrorDto;
        try{
            await method();
        } catch(e) {
            if (e.errorDef) {
                error = e as IErrorDto;
                if (error.errorDef == "CartAlreadyPaid" || error.errorDef === "CartNonExistent") {
                    this._removeCartGuid();
                }
                throw e;
            }
        }
    }
}


export class CartPaymentDetailsModel extends CartPaymentDetailsDto {
    cartGuid?: string;
}

export class CartModel extends CartDto {
    /** Copy of the items property but for display purposes to the user.
     * This will exclude any duplicate items
     */
    cartStatus: CartStatusEnum;
    itemsForDisplay?: ItemDto[];
    checkoutEnabled: boolean = true;
    partitions: { [key: string]: ItemDto[] } = {};
}
