// Angular Files
import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, Output, EventEmitter, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular Material Files
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

// Other Files
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { EllipsisModule } from 'ngx-ellipsis';

// Payment Integration Files
import { PaymentMethodTypeEnum } from 'apps/public-portal/src/app/payment-integrations/base/models';

// Teller Online Files

// Teller Online Library Files
import { TellerOnlineIconsModule } from 'teller-online-libraries/icons';
import { TellerOnlineMessageService } from 'teller-online-libraries/shared';

@Component({
  selector: 'app-echeck-disclaimer',
  templateUrl: './echeck-disclaimer.component.html',
  styleUrls: ['./echeck-disclaimer.component.scss'],
  host: {
    class: 'echeck-disclaimer'
  }
})
export class EcheckDisclaimerComponent implements OnInit {
  @Input() public forEdit: boolean = false;
  @Input() public eCheckMessage: string;

  @Output() public eCheckCheckChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  /** A version of the disclaimer that can be used for the short preview text shown */
  public shortECheckMessage: string;
  public eCheckMessageConfirmed: boolean = false;

  public PaymentMethodTypeEnum = PaymentMethodTypeEnum;


  constructor(
    public messageService: TellerOnlineMessageService
  ) { }

  ngOnInit(): void {
    this.shortECheckMessage = this.eCheckMessage?.replace(/<[^>]*>/g, '');
  }

  onCheck_agreeECheckDisclaimer(event) {
    this.eCheckMessageConfirmed = event.checked;
    this.eCheckCheckChange.emit(this.eCheckMessageConfirmed);
  }

  onClick_showECheckDisclaimer() {
    this.messageService.alert(this.eCheckMessage, this.forEdit ? "Disclaimer" : "Terms & Conditions", "cart-disclaimer-modal");
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacySelectModule,
    MatLegacyCheckboxModule,
    MatLegacyButtonModule,
    MatIconModule,
    NgxMaskDirective,
    NgxMaskPipe,
    TellerOnlineIconsModule,
    EllipsisModule
  ],
  declarations: [EcheckDisclaimerComponent],
  exports: [EcheckDisclaimerComponent],
  providers: [provideNgxMask()]
})
export class EcheckDisclaimerModule { }