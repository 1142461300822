// Angular Files
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular Material Files
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule } from '@angular/material/legacy-select';

// Payment Integration Files
import {
    BasePaymentProcessorIntegrationsModule
} from 'apps/public-portal/src/app/payment-integrations/base';

// Component Files
import { CeleroComponent } from './component';

// Teller Online Library Files
import { TellerOnlineIconsModule } from "teller-online-libraries/icons";

@NgModule({
    imports: [
        CommonModule,
        TellerOnlineIconsModule,
        BasePaymentProcessorIntegrationsModule,
        MatLegacyFormFieldModule,
        MatLegacyInputModule,
        MatLegacyButtonModule,
        MatLegacyCheckboxModule,
        MatIconModule,
        MatLegacySelectModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [ CeleroComponent ],
    exports: [ CeleroComponent ]
})
export class CeleroModule { }