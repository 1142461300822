import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-sign-up',
    templateUrl: '../svgs/SignUp.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--sign-up'
    }
})
export class TellerOnlineIconSignUp {
}