// Angular Files
import { Injectable } from '@angular/core';

// Teller Online Files
import { PasswordPolicyDto } from 'apps/public-portal/src/app/core/api/PublicPortalApiClients';
import { ServicesModule } from 'apps/public-portal/src/app/shared/services/services.module';

// Teller Online Library Files
import { TellerOnlineSiteMetadataService } from 'teller-online-libraries/core';

@Injectable({
    providedIn: ServicesModule
})
export class PasswordService {
    public passwordPattern: string;
    public passwordPolicy: PasswordPolicyDto;

    public validationMessages = {
        base: "The password must contain: ",
        minlength: "at least # characters",
        uppercase: "at least one uppercase character",
        lowercase: "at least one lowercase character",
        number: "at least one number",
        symbol: "at least one symbol"
    }

    private _matchLowercase: string = "a-z";
    private _matchUppercase: string = "A-Z";
    private _matchDigit: string = "\\d"; // Double backslash because we want the slash to appear in our regex
    private _matchSymbol: string = "-=+^$*.\\[\\]{}()?\"!@#%&\\/\\\\,><\\':;|_~`"; // Double backslashes because we want them to appear in our regex

    public get passwordPatternRegexp() {
        return new RegExp(this.passwordPattern);
    }

    constructor(
        siteMetadataService: TellerOnlineSiteMetadataService
    ) {
        let passwordPattern = "^"; // Start with "^" meaning to look at the start of the string

        this.passwordPolicy = siteMetadataService.appConfiguration.passwordPolicy;

        // Update the validation message to indicate appropriate # of characters
        this.validationMessages.minlength = this.validationMessages.minlength.replace('#', this.passwordPolicy.minimumLength.toString());

        // First add the look-aheads
        if (this.passwordPolicy.requireLowercase) passwordPattern += `(?=.*[${this._matchLowercase}])`;
        if (this.passwordPolicy.requireUppercase) passwordPattern += `(?=.*[${this._matchUppercase}])`;
        if (this.passwordPolicy.requireNumbers) passwordPattern += `(?=.*${this._matchDigit})`;
        if (this.passwordPolicy.requireSymbols) passwordPattern += `(?=.*[${this._matchSymbol}])`;

        // Next generate the actual matching string but allow all of the possible options (or anything not required, won't be allowed)
        // We will just use "\w as it matches all lowercase, uppercase, and digits"
        passwordPattern += `[${this._matchSymbol}\\w]`;

        // We only need to check against the length at the very end of the string as part of the matching case
        if (this.passwordPolicy.minimumLength) passwordPattern += `{${this.passwordPolicy.minimumLength},}`;

        passwordPattern += "$" // End with "$" meaning to go until the end of the string

        this.passwordPattern = passwordPattern;
    }

    public upperCharacterChecker(str: string): boolean{
        const regexUpper = new RegExp("["+this._matchUppercase+"]", "g");
        return regexUpper.test(str);
    }

    public lowerCharacterChecker(str: string): boolean{
        const regexLower = new RegExp("["+this._matchLowercase+"]", "g");
        return regexLower.test(str);
    }

    public symbolChecker(str: string): boolean{
        const regexSymbol = new RegExp("["+this._matchSymbol+"]", "g");
        return regexSymbol.test(str);
    }

    public checkIfNumberContains(str: string): boolean{
        const numbRegex = new RegExp("["+this._matchDigit+"]", "g");
        return numbRegex.test(str);
    }
}
