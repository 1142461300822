import { StateOrProvince, Country } from 'apps/public-portal/src/app/shared/constants';

export class BillingDetails
{
    email: string;
    phone: string;
    fullName: string;
    addressCountry: Country;
    addressState: StateOrProvince;
    addressRegion: string;
    addressZip: string;
    addressLine1: string;
    addressLine2: string;
    addressCity: string;
}
