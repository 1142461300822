<a class="skip-to-main-content" [href]="appService.currentUrl+'#maincontent'"
   *ngIf="!((appService.loadingTOL$ | async) || (appService.loading$ | async))"
   [attr.inert]="(appService.pageLoading$ | async) || (appService.loading$ | async) ? '' : null">Skip to Main Content</a>

<!-- The hasBackdrop property of mat-sidenav-container indirectly produces invisible divs that can be focused by tab navigation (which is undesirable).
    Additionally, we don't actually want a backdrop for any of our sidenavs when at fullscreen anyway, so we dynanically bind them to address these issues. 
    Since the intended focus trapping is not working as expected (primarily when position is set to 'end' on the sidenav), we are using our own directive. -->

<!-- container for an optional full page modal that emulate being taken to a separate page -->
<mat-sidenav-container class="modal-page-container" [attr.inert]="(appService.pageLoading$ | async) || (appService.loading$ | async) ? '' : null" [hasBackdrop]="fullPageModalActive">
    <!-- main site content (the real page a user is on that lives beneath the full page modal) -->
    <mat-sidenav-content>
        <!-- container for help menu -->
        <mat-sidenav-container class="app-container" [attr.aria-busy]="(appService.loading$ | async) ? 'true' : 'false'" [hasBackdrop]="appService.isMobile && help.opened">
            <!-- content beneath cart/mobile menu-->
            <mat-sidenav-content>
                <app-header
                    [siteTitle]="siteMetadataService.customization.title + ' ' + siteMetadataService.customization.subtitle"
                    (toggleMobileMenu)="toggleMobileMenu()"
                    (toggleHelp)="toggleHelp()">
                </app-header>

                <!-- container for cart/mobile menu -->
                <mat-sidenav-container class="inner-container" [hasBackdrop]="appService.isMobile && (cart.opened || mobilemenu.opened)">
                    <!-- mobile menu, left-->
                    <mat-sidenav class="mat-sidenav--mobile-menu" #mobilemenu
                        [appRestrictFocus]="appService.isMobile && mobilemenu.opened"
                        fixedInViewport
                        aria-label="Mobile"
                        role="navigation"
                        mode="over"
                        [opened]="false"
                        (openedStart)="menuOpening()">
                        <app-mobile-menu *ngIf="!((appService.loadingTOL$ | async) || (appService.loading$ | async))"></app-mobile-menu>
                    </mat-sidenav>

                    <!-- app content, beneath cart/mobile menu -->
                    <mat-sidenav-content class="main-layout" [ngClass]="{'main-layout--cart': cart.opened}">
                        <teller-online-notification-banner
                            *ngIf="notificationBannerService.enabled"
                            [ngClass]="notificationBannerService.banners[0]?.bannerClass"
                            [message]="notificationBannerService.banners[0]?.message"
                            [dismissAction]="notificationBannerService.banners[0]?.dismissAction"
                            [resolveAction]="notificationBannerService.banners[0]?.resolveAction"
                            [icon]="notificationBannerService.banners[0]?.icon"
                            [svgIcon]="notificationBannerService.banners[0]?.svgIcon"
                            [iconColor]="notificationBannerService.banners[0]?.iconColor"
                            [svgIconClass]="notificationBannerService.banners[0]?.svgIconClass"
                            [tag]="notificationBannerService.banners[0]?.tag">
                        </teller-online-notification-banner>

                        <main id="maincontent" [ngClass]="{
                            'debug': appService.debug,
                            'main-layout--verification': userMustVerifyEmail,
                            'loading-TOL': (appService.loadingTOL$ | async)
                        }">
                            <router-outlet *ngIf="!(appService.loading$ | async)">
                            </router-outlet>
                            <article class="skeleton-content">
                                <header class="banner">
                                    <h1>&nbsp;</h1>
                                </header>
                            </article>
                        </main>

                        <app-footer></app-footer>

                        <button
                            *ngIf="!appService.fabShown"
                            [ngClass]="{'mat-fab-extended' : cartItemCount > 0}"
                            type="button"
                            aria-label="Toggle cart"
                            mat-fab
                            [attr.tabindex]="cart.opened ? -1 : 0"
                            (click)="toggleCart(true)">
                            <mat-icon aria-label="Cart toggle icon" svgIcon="cart" class="teller-online-icon"></mat-icon>
                            <span *ngIf="cartItemCount > 0">{{cartItemCount}}</span>
                        </button>
                    </mat-sidenav-content><!-- end content beneath cart/mobile menu -->

                    <!-- cart, right-->
                    <mat-sidenav #cart
                        class="mat-sidenav--cart"
                        [appRestrictFocus]="appService.isMobile && cart.opened"
                        fixedInViewport
                        position="end"
                        [fixedTopGap]="appService.isMobile ? 0 : 64"
                        role="region"
                        [mode]="appService.isMobile ? 'over' : 'side'"
                        [opened]="false"
                        (openedStart)="cartOpening()">
                        <app-cart
                            [ngClass]="{
                                'cart--empty': cartItemCount < 1
                            }"
                            (toggleCart)="toggleCart(true)">
                        </app-cart>
                    </mat-sidenav>
                </mat-sidenav-container><!-- end container for app/mobile menu -->
            </mat-sidenav-content><!-- end content beneath help menu -->

            <!-- help menu, right-->
            <mat-sidenav #help
                [appRestrictFocus]="appService.isMobile && help.opened"
                class="mat-sidenav--help"
                fixedInViewport
                position="end"
                [fixedTopGap]="appService.isPhone ? 56 : 64"
                role="region"
                mode="over"
                (openedStart)="helpOpening()">
                <app-help-sidebar (toggleHelp)="toggleHelp()"></app-help-sidebar>
            </mat-sidenav>
        </mat-sidenav-container><!-- end main app container -->
    </mat-sidenav-content>
    <!-- full page modal, right-->
    <mat-sidenav #modal
        [appRestrictFocus]="appService.isMobile && fullPageModalActive"
        fixedInViewport
        position="end"
        fixedTopGap="0"
        role="dialog"
        aria-modal="true"
        mode="over"
        class="full-page-modal">
        <!-- Do not style this element, it is here for reference purposes only and is not used -->
        <div #modalcontent></div>
    </mat-sidenav>
</mat-sidenav-container><!-- end "page" modal container  -->
<button
    *ngIf="modalService.opened && modalService.fab"
    type="button"
    [attr.aria-label]="modalService.fab?.ariaLabel"
    mat-fab
    (click)="modalService.fab?.action()"
    [attr.inert]="(appService.pageLoading$ | async) || (appService.loading$ | async) ? '' : null">
    <mat-icon [attr.aria-label]="modalService.fab?.iconAriaLabel" [svgIcon]="modalService.fab?.icon" class="teller-online-icon"></mat-icon>
</button>
<teller-online-load-panel></teller-online-load-panel>
