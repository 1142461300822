import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-expand',
    templateUrl: '../svgs/Expand.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--expand'
    }
})
export class TellerOnlineIconExpand {
}