// Angular Files
import { CommonModule } from '@angular/common';
import { Component, NgModule, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators
} from '@angular/forms';

// Angular Material Files
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule } from '@angular/material/legacy-select'

// Other External Files
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { Subscription } from 'rxjs';

// Payment Integration Files
import { BillingDetails } from 'apps/public-portal/src/app/payment-integrations/base/models';

// Teller Online Files
import { StateOrProvince, Country, COUNTRIES } from 'apps/public-portal/src/app/shared/constants';

// Teller Online Library Files
import { TellerOnlineIconsModule } from "teller-online-libraries/icons";
import { TellerOnlineAppService } from 'teller-online-libraries/core';
import { TellerOnlineValidationService } from 'teller-online-libraries/shared';

@Component({
    selector: 'app-billing-info',
    templateUrl: './billing-info.component.html',
    styleUrls: ['./billing-info.component.scss'],
    host: {
        class: 'billing-info'
    }
})
export class BillingInfoComponent implements AfterViewInit {
    // Declare @Input variables
    @Input() public set billingInfo(value: BillingDetails) {
        this._billingInfo = value;
        this.cdr.detectChanges();
    }
    public get billingInfo() {
        return this._billingInfo;
    }
    private _billingInfo: BillingDetails;

    @Input() public requiredFields: BillingInfoFields[] = [];
    @Input() public disabledFields: BillingInfoFields[] = [];
    @Input() public forEdit: boolean = false;

    @Input() public set formGroup(value: UntypedFormGroup) {
        if(this._formChangeSubscription) this._formChangeSubscription.unsubscribe();

        this._formGroup = value;

        if(!this._billingInfo) {
            if(this.appService.debug) throw "Billing info binding must be loaded before formGroup binding";
        }

        this.stateOrProvinceList = StateOrProvince.GetByCountryCode(this.billingInfo.addressCountry?.code);

        // Add the billing info fields to the form, defaulted to the billing info values
        this._addControl(this.BILLING_INFO_FIELDS.email, BillingInfoFields.email, this.billingInfo.email);
        this._addControl(this.BILLING_INFO_FIELDS.phone, BillingInfoFields.phone, this.billingInfo.phone);
        this._addControl(this.BILLING_INFO_FIELDS.addressLine1, BillingInfoFields.addressLine1, this.billingInfo.addressLine1);
        this._addControl(this.BILLING_INFO_FIELDS.addressLine2, BillingInfoFields.addressLine2, this.billingInfo.addressLine2);
        this._addControl(this.BILLING_INFO_FIELDS.addressCity, BillingInfoFields.addressCity, this.billingInfo.addressCity);
        this._addControl(this.BILLING_INFO_FIELDS.addressState, BillingInfoFields.addressState, this.billingInfo.addressState);
        this._addControl(this.BILLING_INFO_FIELDS.addressRegion, BillingInfoFields.addressRegion, this.billingInfo.addressRegion);
        this._addControl(this.BILLING_INFO_FIELDS.addressCountry, BillingInfoFields.addressCountry, this.billingInfo.addressCountry);
        this._addControl(this.BILLING_INFO_FIELDS.addressZip, BillingInfoFields.addressZip, this.billingInfo.addressZip);

        // Anytime one of the form controls changes, update the billing info
        this._formChangeSubscription = this._formGroup.valueChanges.subscribe((value) => {
            this.billingInfo.email = this._getBillingInfoFieldValue(value, this.BILLING_INFO_FIELDS.email);
            this.billingInfo.phone = this._getBillingInfoFieldValue(value, this.BILLING_INFO_FIELDS.phone);
            this.billingInfo.addressCity = this._getBillingInfoFieldValue(value, this.BILLING_INFO_FIELDS.addressCity);
            this.billingInfo.addressLine1 = this._getBillingInfoFieldValue(value, this.BILLING_INFO_FIELDS.addressLine1);
            this.billingInfo.addressLine2 = this._getBillingInfoFieldValue(value, this.BILLING_INFO_FIELDS.addressLine2);
            this.billingInfo.addressZip = this._getBillingInfoFieldValue(value, this.BILLING_INFO_FIELDS.addressZip);
            this.billingInfo.addressRegion = this._getBillingInfoFieldValue(value, this.BILLING_INFO_FIELDS.addressRegion);

            // Unset the state if the country was changed
            if (this.billingInfo.addressCountry.code != this._getBillingInfoFieldValue(value, this.BILLING_INFO_FIELDS.addressCountry)?.code) {
                this.billingInfo.addressCountry = this._getBillingInfoFieldValue(value, this.BILLING_INFO_FIELDS.addressCountry);

                // Toggle validation rules on fields that are switching visibility
                if (Country.HasRegionList(this.billingInfo.addressCountry?.code)) {
                    // Country has a list of state/provinces so enable the validator for the state dropdown field
                    if (this.requiredFields.includes(BillingInfoFields.addressState)) {
                        this._formGroup.controls[this.BILLING_INFO_FIELDS.addressState].setValidators(Validators.required);
                    }

                    // Country has a list of state/provinces so disable the validator for the region field since it will be hidden.
                    if (this.requiredFields.includes(BillingInfoFields.addressRegion)) {
                        this._formGroup.controls[this.BILLING_INFO_FIELDS.addressRegion].clearValidators();
                    }
                } else {
                    // Country does not have a list of state/provinces so disable the validator for the state dropdown since it will be hidden.
                    if (this.requiredFields.includes(BillingInfoFields.addressState)) {
                        this._formGroup.controls[this.BILLING_INFO_FIELDS.addressState].clearValidators();
                    }

                    // Country does not have a list of state/provinces so enable the validator for the region field.
                    if (this.requiredFields.includes(BillingInfoFields.addressRegion)) {
                        this._formGroup.controls[this.BILLING_INFO_FIELDS.addressRegion].setValidators(Validators.required);
                    }
                }

                // Force the form fields to update. This will trigger the subscription event which will update the backing fields.
                this._formGroup.controls[this.BILLING_INFO_FIELDS.addressState].setValue(undefined);
                this._formGroup.controls[this.BILLING_INFO_FIELDS.addressRegion].setValue(undefined);
            } else {
                this.billingInfo.addressState = this._getBillingInfoFieldValue(value, this.BILLING_INFO_FIELDS.addressState);
                this.billingInfo.addressRegion = this._getBillingInfoFieldValue(value, this.BILLING_INFO_FIELDS.addressRegion);
            }

            this.stateOrProvinceList = StateOrProvince.GetByCountryCode(this.billingInfo.addressCountry?.code);
        });

        this.cdr.detectChanges();
    }

    public get formGroup() {
        return this._formGroup;
    }

    private _formGroup: UntypedFormGroup;

    // Declare @Output variables

    // Public variables
    public countryList: Country[] = COUNTRIES;
    public stateOrProvinceList: StateOrProvince[];
    public BILLING_INFO_FIELDS = {
        "email": "Email",
        "phone": "Phone Number",
        "addressCity": "City",
        "addressLine1": "Address Line 1",
        "addressLine2": "Address Line 2",
        "addressState": "State",
        "addressRegion": "Region",
        "addressCountry": "Country",
        "addressZip": "Zip Code"
    }
    public BillingInfoFields = BillingInfoFields;

    public get stateLabel() {
        return this.billingInfo.addressCountry?.code == 'US'
            ? 'State'
            : this.billingInfo.addressCountry?.code == 'CA'
                ? 'Province'
                : 'Region';
    }

    public get zipLabel() {
        return this.billingInfo.addressCountry?.code == 'US'
            ? 'Zip Code'
            : 'Postal Code';
    }

    public get zipMask() {
        return this.billingInfo.addressCountry?.code == 'US'
            ? "00000"
            : this.billingInfo.addressCountry?.code == 'CA'
                ? 'S0S 0S0'
                : '';
    }
    public get zipInputMode() {
        return this.billingInfo.addressCountry?.code == 'US' ? 'numeric' : '';
    }

    public get phoneMask() {
        return this.validationService.getPhoneNumberMask(
            (this.billingInfo.addressCountry?.code === 'US' || this.billingInfo.addressCountry?.code === 'CA'));
    }

    // Private variables

    // Subscriptions
    private _formChangeSubscription: Subscription;

    constructor(
        public validationService: TellerOnlineValidationService,
        private appService: TellerOnlineAppService,
        private cdr: ChangeDetectorRef
    ) {
    }

    ngAfterViewInit() {
    }

    public countryHasRegionList(countryCode: string) {
        return Country.HasRegionList(countryCode);
    }

    private _addControl(fieldName: string, fieldEnum: BillingInfoFields, fieldValue: any) {
        const control = new UntypedFormControl({ value: [fieldValue], disabled: this.disabledFields.includes(fieldEnum) });
        control.setValue(fieldValue);
        this._formGroup.addControl(fieldName, control);
    }

    private _getBillingInfoFieldValue(formValue: any, fieldName: string) : any {
        let field = '';
        field = formValue[fieldName];

        // If the field is undefined, it may be missing from the form values. This will happen for disabled fields.
        // In this case, we need to find the value from the controls collection instead.
        if (field === undefined) {
            field = this._formGroup.controls[fieldName].value;
        }

        return field;
    }
}
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatLegacyFormFieldModule,
        MatLegacyInputModule,
        MatLegacySelectModule,
        TellerOnlineIconsModule,
        NgxMaskDirective,
        NgxMaskPipe
    ],
    declarations: [ BillingInfoComponent ],
    exports: [BillingInfoComponent],
    providers: [provideNgxMask()]
})
export class BillingInfoModule { }

/** List of required fields that can be customized --
 * any not in this list (e.g. email and zip) always are required
 * The values of these must map to the BILLING_INFO_FIELDS above*/
export enum BillingInfoFields {
    phone = "phone",
    email = "email",
    addressCity = "addressCity",
    addressState = "addressState",
    addressRegion = "addressRegion",
    addressCountry = "addressCountry",
    addressLine1 = "addressLine1",
    addressLine2 = "addressLine2",
    addressZip = "addressZip"
}
