import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-home',
    templateUrl: '../svgs/Home.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--home'
    }
})
export class TellerOnlineIconHome {
}