// Angular Files
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

// Angular Material Files

// Other External Files

// Component Files

// Teller Online Files
import { Country } from 'apps/public-portal/src/app/shared/constants';

// Teller Online Library Files
import {
    PaymentMethodModel, 
    TellerOnlineAppService, 
    TellerOnlinePaymentMethodsService 
} from "teller-online-libraries/core";
import { TellerOnlineValidationService } from 'teller-online-libraries/shared';

@Component({
    selector: 'app-payment-method',
    templateUrl: './payment-method.component.html',
    styleUrls: ['./payment-method.component.scss'],
    host: {
        class: 'payment-method-view'
    }
})
export class TellerOnlinePaymentMethodComponent {
    // Public variables
    @Input() public readOnly: boolean = false;
    @Input() public paymentMethod: PaymentMethodModel;
    @Input() public isDefault: boolean;
    @Input() public emailIsVerified: boolean;
    @Output() remove = new EventEmitter<any>();
    @Output() default = new EventEmitter<any>();


    // Private variables

    // Subscriptions

    constructor(
        private validationService: TellerOnlineValidationService,
        public appService: TellerOnlineAppService,
        public paymentMethodsService: TellerOnlinePaymentMethodsService
    ) { }

    // TODO (TOP-2185): Re-evaluate how phone number formatting is determined
    public isNorthAmerica() {
        let country = Country.GetByCode(this.paymentMethod.addressCountry) ?? Country.GetByName(this.paymentMethod.addressCountry) ?? null;

        if (!country)
            return false;
        else
            return this.validationService.checkPhoneNumberNorthAmerica(country.dial_code);
    }

    public onClick_removeMethod(e, paymentMethod) {
        this.remove.emit({event: e, paymentMethod: paymentMethod});
    }

    public onChange_setDefault(paymentMethod) {
        this.default.emit(paymentMethod);
    }
}
