<div class="password-caption__container" id="aria_password_caption">
    <span>{{passwordService.validationMessages.base}}</span>
    <span *ngIf="passwordService.passwordPolicy.minimumLength > 0" 
        [ngClass]="{
            'caption-error': (error || (password?.length ?? 0) > 0) && (password?.length ?? 0) < passwordService.passwordPolicy.minimumLength, 
            'caption-positive': (password?.length ?? 0) >= passwordService.passwordPolicy.minimumLength
        }">
        - {{passwordService.validationMessages.minlength}}
    </span>

    <span *ngIf="passwordService.passwordPolicy.requireUppercase" 
        [ngClass]="{
            'caption-error': !passwordService.upperCharacterChecker(password) && (error || (password?.length ?? 0) > 0), 
            'caption-positive': passwordService.upperCharacterChecker(password) && (error || (password?.length ?? 0) > 0)
        }">
        - {{passwordService.validationMessages.uppercase}}
    </span>

    <span *ngIf="passwordService.passwordPolicy.requireLowercase" 
        [ngClass]="{
            'caption-error': !passwordService.lowerCharacterChecker(password) && (error || (password?.length ?? 0) > 0), 
            'caption-positive': passwordService.lowerCharacterChecker(password) && (error || (password?.length ?? 0) > 0)
        }">
        - {{passwordService.validationMessages.lowercase}}
    </span>

    <span *ngIf="passwordService.passwordPolicy.requireNumbers" 
        [ngClass]="{
            'caption-error': !passwordService.checkIfNumberContains(password) && (error || (password?.length ?? 0) > 0), 
            'caption-positive': passwordService.checkIfNumberContains(password) && (error || (password?.length ?? 0) > 0)
        }">
        - {{passwordService.validationMessages.number}}
    </span>
    
    <span *ngIf="passwordService.passwordPolicy.requireSymbols" 
        [ngClass]="{
            'caption-error': !passwordService.symbolChecker(password) && (error || (password?.length ?? 0) > 0), 
            'caption-positive': passwordService.symbolChecker(password) && (error || (password?.length ?? 0) > 0)
        }">
        - {{passwordService.validationMessages.symbol}}
    </span>
</div>