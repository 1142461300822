// Angular Files
import { ApplicationRef, Inject, Injectable, Injector } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { DOCUMENT } from "@angular/common";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { BreakpointObserver } from '@angular/cdk/layout';

// Other External Files
import { BehaviorSubject, concat, interval, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

// Services
import { TellerOnlineSiteMetadataService } from './site-metadata.service';

// Teller Online Shared Files
import { TellerOnlineMessageService, TellerOnlineWindowService } from 'teller-online-libraries/shared';

export class TellerOnlineDocumentTitlePieces {
    /** Content that will appear before the title defined in app-routing */
    beforeRouteTitle?: string;
    /** Content that will appear after the title defined in app-routing */
    afterRouteTitle?: string;
}

@Injectable({
    providedIn: 'root'
})
export class TellerOnlineAppService implements HttpInterceptor {
    private _isNarrow: boolean;
    private _isWide: boolean;
    private _isMobile: boolean;
    private _isPhone: boolean;
    private _isPortraitTablet: boolean;
    private _isLandscapeTablet: boolean;
    private _isDesktop: boolean;
    private _isLargeDesktop: boolean;

    /** Current browser size matches PortraitTablet, > 600px */
    public readonly breakpoints$: Observable<boolean> = this.breakpointObserver.observe([
        TellerOnlineBreakpoints.PortraitTablet,
        TellerOnlineBreakpoints.LandscapeTablet,
        TellerOnlineBreakpoints.Desktop,
        TellerOnlineBreakpoints.LargeDesktop
    ])
    .pipe(
        map(result => result.matches)
    );

    /** Current status of the application's load state - pushes out updates to loading$ */
    private _loading: BehaviorSubject<boolean> = new BehaviorSubject(true);
    /** Current status of the application's load state - set via triggerLoading and finishLoading */
    public readonly loading$: Observable<boolean> = this._loading.asObservable();
    /** The message to display on the #navigationLoadPanel */
    private _loadingMessage: string = 'Loading...';

    /** Current status of the page's load state - pushes out updates to loading$ */
    private _pageLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
    /** Current status of the page's load state - set via triggerPageLoading and finishPageLoading */
    public readonly pageLoading$: Observable<boolean> = this._pageLoading.asObservable();
    /** The message to display on a page's #loadPanel */
    private _pageLoadingMessage: string = 'Loading...';

    /** Used to enabled/disable
        - the consoleLog function
        - the on screen indication of breakpoints (desktop, tablet, mobile)
        - rethrowing errors to the console instead of hiding them
    */
    private _debugLevel: TellerOnlineDebugLevel = TellerOnlineDebugLevel.None;

    /** The title parameter defined within the route for the current page we're on */
    private _routeTitle: string = '';

    /** A flag to track if this is the first page load so we can determine if we need to auto-refresh or prompt the user */
    private _firstLoad: boolean = true;

    /** A flag to track if the we're currently trying to update (so multiple events aren't fired at once */
    private _updating: boolean = false;

    private _currentUrl: string = '';

    /** List of pages that have a rail navigation and should use a rail layout/navigation
     * used by railPage to compare against currentUrl
     * only railPages or nonRailPages should be set, not both */
    private _railPages: string[] = [];

    /** List of pages that do not have a rail navigation and should use a normal layout/navigation
     * used by railPage to compare against currentUrl.
     * only railPages or nonRailPages should be set, not both */
    private _nonRailPages: string[] = [];

    /** The current navigation state from the router. Should only be set from the app.component file */
    private _routerState: any;

    /** Whether or not another fab (non cart) is active and being shown (only one can be shown at a time) */
    private _fabShown: boolean = false;

    /** Current status of the TOL application's load state - set via triggerPageLoading and finishPageLoading AND TOL being true */
    public get loadingTOL$(): Observable<boolean> {
        return this.windowService.isTOL ? this.pageLoading$ : new BehaviorSubject(false).asObservable();
    }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private injector: Injector,
        private breakpointObserver: BreakpointObserver,
        private updates: SwUpdate,
        private router:  Router,
        private titleService: Title,
        private siteMetadataService: TellerOnlineSiteMetadataService,
        private messageService: TellerOnlineMessageService,
        private windowService: TellerOnlineWindowService
    ) {
        this.loading$.subscribe();

        this.breakpoints$.subscribe(() => {
            // reset all the values
            this._isLargeDesktop = false;
            this._isDesktop = false;
            this._isLandscapeTablet = false;
            this._isPortraitTablet = false;
            this._isPhone = false;
            this._isWide = false;
            this._isNarrow = false;
            this._isMobile = false;

            // Set the correct values
            if(this.breakpointObserver.isMatched(TellerOnlineBreakpoints.LargeDesktop)) {
                this._isLargeDesktop = true;
                this._isWide = true;
            } else if(this.breakpointObserver.isMatched(TellerOnlineBreakpoints.Desktop)) {
                this._isDesktop = true;
                this._isWide = true;
            } else if(this.breakpointObserver.isMatched(TellerOnlineBreakpoints.LandscapeTablet)) {
                this._isLandscapeTablet = true;
                this._isMobile = true;
                this._isWide = true;
            } else if(this.breakpointObserver.isMatched(TellerOnlineBreakpoints.PortraitTablet)) {
                this._isPortraitTablet = true;
                this._isNarrow = true;
                this._isMobile = true;
            } else {
                this._isPhone = true;
                this._isNarrow = true;
                this._isMobile = true;
            }
        });
    }

    // This only applies to calls made via an apiClient
    intercept(
      req: HttpRequest<any>,
      next: HttpHandler
    ): Observable<HttpEvent<any>> {
        let headers = {};
        this.setHeaders(headers);

        req = req.clone({
            setHeaders: headers,
        });

        return next.handle(req)
    }

    /** Set any headers that are always set (not conditional)
     * Used by the interceptor and in dashboardService for dataGrid
     */
    public setHeaders(headers) {
        headers["ngsw-bypass"] = "true"; // Don't cache requests in the service worker
    }

    /** Checks every 2 minutes once the app is stable for updates to the app's code base */
    public checkForUpdates() {
        // If the service worker is enabled
        if(this.updates.isEnabled)
        {
            this._subscribeToAvailableUpdates();

            // Check for updates, after we've done so, set the first load to false
            console.log('[AppService] Checking for initial new version.');
            this.updates.checkForUpdate().finally(() => {
                this._firstLoad = false;
            });

            // Get the application reference so we can determine when it is stable
            let app = this.injector.get(ApplicationRef);

            // Check for updates to the app's code
            // Allow the app to stabilize first, before starting polling for updates with `interval()`.
            const appIsStable$ = app.isStable.pipe(first(isStable => isStable === true));
            const repeated$ = interval(2 * 60 * 1000); // every 2 minutes
            const repeatedOnceAppIsStable$ = concat(appIsStable$, repeated$);

            repeatedOnceAppIsStable$.subscribe(val => {
                // don't react if this was from appIsStable (it will be an integer if it's from interval)
                if(typeof val != "number")
                    return;
                // Not just for debugging, just an informative message in the console
                console.log('[AppService] Checking for updates.');
                this.updates.checkForUpdate();
            });
        }
    }

    public triggerLoading(message?) {
        // Indicate the app is loading again (to screen readers)
        this.document.querySelector('.app-container')?.setAttribute('aria-busy', 'true');
        // Update the app-status text so screen readers will announce that it is loading
        if(this.document.querySelector('.app-status')) this.document.querySelector('.app-status').textContent = message ?? 'Loading page';
        // if there's no message, unset it
        this._loadingMessage = message ?? "";
        this._loading.next(true);
    }

    public finishLoading(message?) {
        // Turn off the app loading (for screen readers)
        this.document.querySelector('.app-container')?.setAttribute('aria-busy', 'false');
        // Update the text for screen readers to indicate it's done loading
        if(this.document.querySelector('.app-status')) this.document.querySelector('.app-status').textContent = message ?? 'Loaded';

        this._loadingMessage = "Loading..." // Set the loading message back to default
        this._loading.next(false);
    }

    public triggerPageLoading(message?) {
        // Turn off loading first if it's already on
        if (this._pageLoading.getValue()) {
            this._pageLoading.next(false);
        }

        // Indicate the page is loading again (to screen readers)
        this.document.querySelector('.main-content')?.setAttribute('aria-busy', 'true');
        // Update the app-status text so screen readers will announce that it is loading
        if(this.document.querySelector('.app-status')) this.document.querySelector('.app-status').textContent = message ?? 'Loading content';

        if(message) this._pageLoadingMessage = message;
        this._pageLoading.next(true);
    }

    public finishPageLoading(message?) {
        // Reset to defaul message
        this._pageLoadingMessage = 'Loading...';
        // Turn off the page loading (for screen readers)
        this.document.querySelector('.main-content')?.setAttribute('aria-busy', 'false');
        // Update the text for screen readers to indicate it's done loading
        if(this.document.querySelector('.app-status')) this.document.querySelector('.app-status').textContent = message ?? 'Loaded';

        this._pageLoading.next(false);
    }

    /** Explicit set method for routeTitle to ensure setting _routeTitle is
     a little more deliberate as it should not be done carelessly */
    public setRouteTitle(title) {
        this._routeTitle = title?.trim();
    }

    public setDocumentTitle(titlePieces?: TellerOnlineDocumentTitlePieces) {
        let title = this._routeTitle;

        // If we want to add something before the title defined in app-routing
        if(titlePieces?.beforeRouteTitle) {
            title = titlePieces.beforeRouteTitle.trim() + ' ' + title;
        }

        // If we want to add something after the title defined in app-routing
        if(titlePieces?.afterRouteTitle) {
            title += ' ' + titlePieces.afterRouteTitle.trim();
        }

        let customTitle = this.siteMetadataService.customization.title;
        let customSubtitle = this.siteMetadataService.customization.subtitle;
        let siteTitle = customSubtitle ? customTitle + ' ' + customSubtitle : customTitle;

        // If we have a title, add a separator before we add the site title
        if (title) {
            title += ' | ' + siteTitle;
        } else {
            title = siteTitle;
        }

        this.titleService.setTitle(title);
    }

    // Breakpoint values
    public get isPhone() { return this._isPhone; }
    public get isPortraitTablet() { return this._isPortraitTablet; }
    public get isLandscapeTablet() { return this._isLandscapeTablet; }
    public get isDesktop() { return this._isDesktop; }
    public get isLargeDesktop() { return this._isLargeDesktop; }
    public get isNarrow() { return this._isNarrow; }
    public get isWide() { return this._isWide; }
    public get isMobile() { return this._isMobile; }

    /** Should only be called in app.component in the router events subscription */
    public set routerState(value) {
        this._routerState = value;
    }

    /** Reading the current router state will reset the value so if it is being read,
     * it should be stored in a local variable for reuse */
    public get routerState() {
        this.consoleLog(this, "Router state read");
        let state = this._routerState;
        this._routerState = null;
        return state;
    }

    public get checkoutPage() {
        return this.currentUrl.startsWith("/checkout") &&
              !this.currentUrl.includes("processing") &&
              !this.currentUrl.includes("success");
    }

    /** returns a boolean indicating if the current url is in the list of railPages */
    public get railPage() {
        return this._railPages.length > 0 ?
            this._railPages.includes(this._currentUrl) :
            !this._nonRailPages.includes(this._currentUrl);
    }

    /** Sets the list of pages that have a rail style navigation and should use rail layout for this app.
     * only railPages or nonRailPages should be set, not both */
    public setRailPages(railPages) {
        this._railPages = railPages;
    }

    /** Sets the list of pages that do not have a rail style navigation and should use regular layout for this app.
     * only railPages or nonRailPages should be set, not both */
    public setNonRailPages(nonRailPages) {
        this._nonRailPages = nonRailPages;
    }

    /** Gets the current url property for the app, should be a match for the exact url (exclusive of the domain)  to be used in routerLink and etc */
    public get currentUrl() {
        return this._currentUrl;
    }

    /** Sets the current url property for the app, only override this when using location.replaceState to change the url */
    public set currentUrl(url) {
        // Make sure we don't save the "skip to main content" part of the url if it exists
        this._currentUrl = url.replace('#maincontent', '');
    }

    public get loadingMessage() {
        return this._loadingMessage;
    }

    public get pageLoadingMessage() {
        return this._pageLoadingMessage;
    }

    public get routeTitle() {
        return this._routeTitle;
    }

    public get debugLevel() {
        return this._debugLevel;
    }

    public get debug(): boolean {
        return this._debugLevel != TellerOnlineDebugLevel.None;
    }

    public get showErrorDialogs() {
        return false;
    }

    public set fabShown(value) {
        setTimeout(() => {
            this._fabShown = value;
        }, 0);
    }

    public get fabShown() {
        return this._fabShown;
    }

    /** Determines if the current route starts with the specified value */
    public currentPage(route: string, strict: boolean = false) : boolean {
        let currentPage = false;
        if(!strict) {
            currentPage = this.currentUrl.startsWith(route);
        } else {
            currentPage = this.currentUrl == route;
        }
        return currentPage;
    }

    /** Determines if the aria-current value for a given route should be set to 'page' or null */
    public ariaCurrent(route: string, strict: boolean = false) : "page" | null {
        return this.currentPage(route, strict) ? "page" : null;
    }

    public enableDebugging(debugLevel = TellerOnlineDebugLevel.Log) {
        console.log('[AppService] Debugging enabled.');
        this._debugLevel = debugLevel;
    }

    public disableDebugging() {
        console.log('[AppService] Debugging disabled.');
        this._debugLevel = TellerOnlineDebugLevel.None;
    }

    public consoleLog(scope, ...args) {
        if(this.debugLevel == TellerOnlineDebugLevel.Log) {
            // All of the following error/stack code is to grab the line this was called from
            // Otherwise all consoleLog calls will never tell you where they came from, they will only ever log (line 46 of appService)
            var e = new Error();
            if (!e.stack) {
                try {
                    // IE requires the Error to actually be thrown or else the
                    // Error's 'stack' property is undefined.
                    throw e;
                } catch (e) {
                    if (!e.stack) {
                        //return 0; // IE < 10, likely
                    }
                }
            }
            var stack = e.stack.toString().split(/\r\n|\n/);

            // Grab the file/line where this call came from (unmapped - will link to the compiled file)
            var line = 'unknown';
            if (stack[2].includes('https://')) {
                line = 'https://'+stack[2].split('(https://')[1];
            } else if (stack[2].includes('http://')) {
                line = 'http://'+stack[2].split('(http://')[1];
            }

            // [angular] is when running ng serve [ProxyZone] is when running ng test
            line = line.replace(') [angular]', '').replace(') [ProxyZone]', '');

            // Add our custom formatted message to the args
            args.unshift(`[${scope.constructor.name}] [URL: ${this.router?.url}] [TRACE: ${line}]\n`);
            // Then pass all the args to the console as if we had called console.log normally
            console.log.apply(console, args);
        }
    }

    /** Subscribe to updates from the service worker (will prompt the user to reload when update found) */
    private _subscribeToAvailableUpdates() {
        this.updates.versionUpdates.pipe(
            filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
            map(evt => ({
              type: 'UPDATE_AVAILABLE',
              current: evt.currentVersion,
              available: evt.latestVersion,
            }))
        ).subscribe(event => {
            this._handleAvailableUpdateSubscription(event);
        });
    }

    private _handleAvailableUpdateSubscription(event) {
        // Only try to fire an update, if we're not already updating
        if(!this._updating) {
            this._updating = true;

            let currentAppData: any = event.current.appData;
            let availableAppData: any = event.available.appData;

            console.log(`[AppService] applying update ${availableAppData.version}`);

            // If there's an update when the app is first loaded, reload immediately
            if(this._firstLoad) {
                this.messageService.notification("Reloading...", "info", 3000);
                this.updates.activateUpdate().finally(() => {
                    this.document.location.reload();
                });
            } else {
                this.messageService.alert(`There is a new version of the application and your webpage needs to reload. It will reload as soon as you dismiss this message.`, `Update Required`).then(async (confirm) => {
                    this.updates.activateUpdate().finally(() => {
                        this.document.location.reload();
                    });
                });
            }
        }
    }
}

export enum TellerOnlineBreakpoints {
    // Don't need Phone because it is just ! PortraitTablet
    PortraitTablet = "(min-width: 600px)",
    LandscapeTablet = "(min-width: 905px)",
    Desktop = "(min-width: 1240px)",
    LargeDesktop = "(min-width: 1440px)"
}

export enum TellerOnlineDebugLevel {
    /** Production level of debug */
    None,
    /** Console errors will not be passed to the backend and will show up on the frontend,
     * on screen indication will be shown of breakpoints*/
    Error,
    /**
     * Error level + consoleLog function will be output
     */
    Log
}
