import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-preferences',
    templateUrl: '../svgs/Gear.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--preferences'
    }
})
export class TellerOnlineIconPreferences {
}