// Angular Files
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'parseExpiry'
})
export class ParseExpiryPipe implements PipeTransform {

    transform(str: string) {
        if(!str) 
            return;
        if(str.includes("/"))
            return str;
        return str.slice(0,2) + "/" + str.slice(2);    
    }

}
