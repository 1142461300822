<ng-container *ngIf="item.template != 'loadMore'">
    <div class="separated-search-result__wrapper">
        <div class="separated-search-result__title" [matTooltip]="item.title.length > 100 ? item.title : ''">
            <h2>{{item.title}}</h2>
        </div>
        
        <mat-list class="separated-search-result__details" role="list" [attr.aria-label]="item.title + ' details'">
            <mat-list-item *ngFor="let detail of item.details" class="separated-search-result__detail">
                <!-- 2 lines if narrow -->
                <span class="label" mat-line *ngIf="appService.isPhone || appService.isPortraitTablet">{{detail.description}}</span>
                <span mat-line *ngIf="appService.isPhone || appService.isPortraitTablet">{{detail.value}}</span>
        
                <!-- 1 line if wide -->
                <div mat-line *ngIf="!appService.isPhone && !appService.isPortraitTablet" class="separated-search-result__single-line">
                    <span class="label">{{detail.description}}</span>
                    <span>{{detail.value}}</span>
                </div>
            </mat-list-item>
        </mat-list>
        <div class="separated-search-result__view-details">
            <a mat-flat-button
                color="accent"
                type="button"
                class="separated-search-result__view-details__button"
                [routerLink]="['/search', item.page.pageId, 'details']"
                [queryParams]="{ BsiKey: item.businessSystemItemKey }">
        
                <span>View Details</span>
            </a>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="item.template === 'loadMore'">
    <button class="separated-search-result__load-more" mat-flat-button type="button" color="accent" (click)="loadMore.emit()">
        Show More Results
    </button>
</ng-container>
