<section [formGroup]="formGroup" *ngIf="formGroup" class="layout-grid">
    <mat-form-field appearance="standard" *ngIf="!forEdit">
        <mat-label>{{BILLING_INFO_FIELDS.email}}</mat-label>
        <input matInput
        [formControlName]="BILLING_INFO_FIELDS.email"
        inputmode="email" required [pattern]="validationService.emailRegex">
        <mat-error *ngIf="formGroup?.controls?.[BILLING_INFO_FIELDS.email].touched && formGroup?.controls?.[BILLING_INFO_FIELDS.email].invalid">
            {{validationService.getFieldErrorMessage(BILLING_INFO_FIELDS.email, formGroup.controls[BILLING_INFO_FIELDS.email], validationService.fieldErrors[BILLING_INFO_FIELDS.email])}}
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="standard">
        <mat-label>{{BILLING_INFO_FIELDS.phone}}</mat-label>
        <input matInput
            [formControlName]="BILLING_INFO_FIELDS.phone"
            inputmode="tel"
            [mask]="phoneMask"
            [required]="requiredFields.includes(BillingInfoFields.phone)">
        <mat-error *ngIf="formGroup?.controls?.[BILLING_INFO_FIELDS.phone].touched && formGroup?.controls?.[BILLING_INFO_FIELDS.phone].invalid">
            {{validationService.getFieldErrorMessage(BILLING_INFO_FIELDS.phone, formGroup.controls[BILLING_INFO_FIELDS.phone], BILLING_INFO_FIELDS.phone + ' must match the provided format.')}}
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="standard">
        <mat-label>{{BILLING_INFO_FIELDS.addressLine1}}</mat-label>
        <input matInput
            [formControlName]="BILLING_INFO_FIELDS.addressLine1"
            [required]="requiredFields.includes(BillingInfoFields.addressLine1)">
        <mat-error *ngIf="formGroup?.controls?.[BILLING_INFO_FIELDS.addressLine1].touched && formGroup?.controls?.[BILLING_INFO_FIELDS.addressLine1].invalid">
            {{validationService.getFieldErrorMessage(BILLING_INFO_FIELDS.addressLine1, formGroup.controls[BILLING_INFO_FIELDS.addressLine1])}}
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="standard">
        <mat-label>{{BILLING_INFO_FIELDS.addressLine2}}</mat-label>
        <input matInput
            [formControlName]="BILLING_INFO_FIELDS.addressLine2"
            [required]="requiredFields.includes(BillingInfoFields.addressLine2)">
        <mat-error *ngIf="formGroup?.controls?.[BILLING_INFO_FIELDS.addressLine2].touched && formGroup?.controls?.[BILLING_INFO_FIELDS.addressLine2].invalid">
            {{validationService.getFieldErrorMessage(BILLING_INFO_FIELDS.addressLine2, formGroup.controls[BILLING_INFO_FIELDS.addressLine2])}}
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="standard">
        <mat-label>{{BILLING_INFO_FIELDS.addressCity}}</mat-label>
        <input matInput
            [formControlName]="BILLING_INFO_FIELDS.addressCity"
            [required]="requiredFields.includes(BillingInfoFields.addressCity)">
        <mat-error *ngIf="formGroup?.controls?.[BILLING_INFO_FIELDS.addressCity].touched && formGroup?.controls?.[BILLING_INFO_FIELDS.addressCity].invalid">
            {{validationService.getFieldErrorMessage(BILLING_INFO_FIELDS.addressCity, formGroup.controls[BILLING_INFO_FIELDS.addressCity])}}
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="standard" *ngIf='countryHasRegionList(formGroup?.controls?.[BILLING_INFO_FIELDS.addressCountry]?.value?.code)'>
        <mat-label>{{stateLabel}}</mat-label>
        <mat-select
            [formControlName]="BILLING_INFO_FIELDS.addressState"
            [required]="requiredFields.includes(BillingInfoFields.addressState)">
            <mat-option *ngFor="let state of stateOrProvinceList" [value]="state">{{state.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="formGroup?.controls?.[BILLING_INFO_FIELDS.addressState]?.touched &&
                          formGroup?.controls?.[BILLING_INFO_FIELDS.addressState]?.invalid">
            {{validationService.getFieldErrorMessage(stateLabel, formGroup.controls[BILLING_INFO_FIELDS.addressState])}}
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="standard" *ngIf='!countryHasRegionList(formGroup?.controls?.[BILLING_INFO_FIELDS.addressCountry]?.value?.code)'>
        <mat-label>{{stateLabel}}</mat-label>
        <input matInput
               [formControlName]="BILLING_INFO_FIELDS.addressRegion"
               [required]="requiredFields.includes(BillingInfoFields.addressRegion)">
        <mat-error *ngIf="formGroup?.controls?.[BILLING_INFO_FIELDS.addressRegion]?.touched &&
                          formGroup?.controls?.[BILLING_INFO_FIELDS.addressRegion]?.invalid">
            {{validationService.getFieldErrorMessage(stateLabel, formGroup.controls[BILLING_INFO_FIELDS.addressRegion])}}
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="standard">
        <mat-label>{{BILLING_INFO_FIELDS.addressCountry}}</mat-label>

        <mat-select
            [formControlName]="BILLING_INFO_FIELDS.addressCountry"
            [required]="requiredFields.includes(BillingInfoFields.addressCountry)">
            <mat-option *ngFor="let country of countryList" [value]="country">{{country.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="formGroup?.controls?.[BILLING_INFO_FIELDS.addressCountry].touched && formGroup?.controls?.[BILLING_INFO_FIELDS.addressCountry].invalid">
            {{validationService.getFieldErrorMessage(zipLabel, formGroup.controls[BILLING_INFO_FIELDS.addressCountry])}}
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="standard" class="zip-code">
        <mat-label>{{zipLabel}}</mat-label>

        <input matInput
            [formControlName]="BILLING_INFO_FIELDS.addressZip"
            [attr.inputmode]="zipInputMode"
            [mask]="zipMask"
            required>
        <mat-error *ngIf="formGroup?.controls?.[BILLING_INFO_FIELDS.addressZip].touched && formGroup?.controls?.[BILLING_INFO_FIELDS.addressZip].invalid">
            {{validationService.getFieldErrorMessage(zipLabel, formGroup.controls[BILLING_INFO_FIELDS.addressZip], zipLabel + ' must match the provided format.')}}
        </mat-error>
    </mat-form-field>
</section>
