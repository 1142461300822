// Angular Files
import { Component, Input, ChangeDetectorRef, HostBinding, ViewChild, ElementRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

// Teller Online Core Files
import { TellerOnlineAppService } from 'teller-online-libraries/core';

@Component({
    selector: 'teller-online-load-panel',
    templateUrl: './load-panel.component.html',
    styleUrls: ['./load-panel.component.scss'],
    host: {
        class: 'load-panel'
    }
})
export class TellerOnlineLoadPanelComponent {
    @ViewChild('loader') loader: ElementRef;

    // Declare @Input variables
    @Input() loadedMessage: any = "Loaded";

    // Public variables
    public previousFocus: HTMLElement

    // Private variables
    private _pageLoadActive: boolean = false;
    private _siteLoadActive: boolean = false;

    // Subscriptions

    constructor(
        @Inject(DOCUMENT) private document: Document,
        public appService: TellerOnlineAppService,
        public cdr: ChangeDetectorRef
    ) {
        //anytime the page loading state changes, toggle the class
        this.appService.pageLoading$.subscribe(isActive => {
            this._pageLoadActive = isActive;
            this.setActive();
        });

        //anytime the page loading state changes, toggle the class
        this.appService.loading$.subscribe(isActive => {
            this._siteLoadActive = isActive;
            this.setActive();
        });
    }

    @HostBinding('class.load-panel--active') private isActive = true;

    public setActive() {
        // Store currently focused element for redirection once loader is complete
        if (!this.isActive) {
            this.previousFocus = this.document.activeElement as HTMLElement;
        }
        this.isActive = this._siteLoadActive || this._pageLoadActive;
        setTimeout(() => {
            if(this.isActive) {
                this.loader.nativeElement.focus();
            }
            else {
                this.previousFocus?.focus();
            }
        },0);
    }
}

