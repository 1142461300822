// Angular Files
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Payment Integration Files
import {
    RedirectPaymentProcessorResponse,
    PaymentMethodTypeEnum
} from 'apps/public-portal/src/app/payment-integrations/base';
// For some reason if the PaymentProcessorService was included from just base, the unit tests would fail
import { PaymentProcessorService } from 'apps/public-portal/src/app/payment-integrations/base/interfaces';

// Teller Online Files
import {
    RedirectVerifyPaymentStatusRequestDto,
    RedirectVerifyPaymentStatusResponseDto,
    SharedPaymentIntegrationApiClient
} from 'apps/public-portal/src/app/core/api/PaymentIntegrationApiClients';
import { CartService } from 'apps/public-portal/src/app/core/services';
import { TellerOnlineAppService } from 'teller-online-libraries/core';
import { TellerOnlineWindowService } from 'teller-online-libraries/shared';

@Injectable({
    providedIn: 'root'
})
export class PointAndPayService extends PaymentProcessorService {
    //#region PaymentProcessorService fields

    public redirect = true;
    public systemPaymentIdentifierKey = "ref";
    public checkoutUrl: string;
    public productId: string;
    //TODO: Retrieve/set these urls via config in the backend
    // currently these doesn't work, but I'm leaving it in, in the hopes we get P&P to change it
    // public finishUrl: string = "https://localhost:5002/checkout/processing?ref=[cartGuid]&result=success";
    // public cancelUrl: string = "https://localhost:5002/checkout/processing?ref=[cartGuid]&result=cancelled";

    //#endregion

    constructor(
        private router: Router,
        private appService: TellerOnlineAppService,
        private cartService: CartService,
        private windowService: TellerOnlineWindowService,
        sharedPaymentApi: SharedPaymentIntegrationApiClient
    ) {
        super(sharedPaymentApi);
    }

    //#region PaymentProcessorService

    public override async checkout(cartGuid: string) {
        // currently this doesn't work, but I'm leaving it in, in the hopes we get P&P to change it
        // this.finishUrl = this.finishUrl.replace("[cartGuid]", cartGuid);
        // this.cancelUrl = this.cancelUrl.replace("[cartGuid]", cartGuid);
        this.router.navigate(["/checkout/redirect"]);
    }

    public override async handlePostbackResponse(params) {
        let verifyPaymentStatusRequest = new RedirectVerifyPaymentStatusRequestDto();
        verifyPaymentStatusRequest.paymentIdentifier = this.windowService.getLocalStorageItem("pnpPaymentIdentifier");
        verifyPaymentStatusRequest.paymentStatus = params.result;

        let response: RedirectVerifyPaymentStatusResponseDto;
        if(this.cartService.cartGuid) response = await this.sharedPaymentApi.verifyPaymentStatus(verifyPaymentStatusRequest).toPromise();

        let state: { cartError?: string, emailAddress?: string } = {};
        let redirectUrl = '/';

        if(response?.responseSuccess) {
            // The default is to go home, this is the only situation where we don't want to, so override the default
            redirectUrl = '/checkout/success/' + this.cartService.cartGuid;
            // The email is used for displaying a "sent email" message
            state.emailAddress = response.emailAddress;
        } else {
            state.cartError = response.responseMessage;
        }

        this.appService.consoleLog(this, state);

        return new RedirectPaymentProcessorResponse({
            paymentIdentifier: this.cartService.cartGuid,
            cartGuid: this.cartService.cartGuid,
            status: response.responseSuccess ? "success" : "cancel",
            redirectUrl: redirectUrl,
            navigationExtras: { state: state }
        });
    }

    public async startPayment(cartGuid: string): Promise<string> {
        let response = await this.sharedPaymentApi.startPayment(cartGuid).toPromise();
        return response.paymentIdentifier;
    }
    //#endregion
}
