import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

// Based on https://blog.angular-university.io/angular-custom-validators/
@Directive({
    selector: "[validateMatchField]",
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: ValidateMatchFieldDirective,
        multi: true
    }]
})
export class ValidateMatchFieldDirective implements Validator, OnChanges {
    private _onChange?: () => void;

    /** The field to compare this against. Note that as an input, this can change. */
    @Input('validateMatchField') field: string;

    validate(control: AbstractControl): ValidationErrors | null {
        // Pass `this` so that the validator function has access to the `field` binding on this directive.
        return confirmPasswordValidator(this)(control);
    }

    registerOnValidatorChange(fn: () => void): void {
        // Allow callback for when `field` changes
        this._onChange = fn;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.field) {
            if (this._onChange) this._onChange();
        }
    }
}

/**
 * 
 * @param context The caller, which is a directive. Used to get the `field` for comparison.
 * @returns A validator function that will check to see if the given control matches the field configured to match against.
 */
function confirmPasswordValidator(context: ValidateMatchFieldDirective): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        return context.field === control?.value ? null : {pattern: true};
    }
}