import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-collapse',
    templateUrl: '../svgs/Collapse.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--collapse'
    }
})
export class TellerOnlineIconCollapse {
}