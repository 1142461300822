// Angular Files
import { NgModule } from '@angular/core';

// Teller Online Payment Processor Integration Files
import { CeleroModule } from './celero';
import { DatacapModule } from './datacap';
import { DemoModule } from './demo';
import { NicModule } from './nic';
import { BridgePayModule } from './bridge-pay';
import { PointAndPayModule } from './point-and-pay';
import { ElavonModule } from './elavon';
import { CyberSourceModule } from './cyber-source';
import { WellsFargoModule } from './wells-fargo';
import { FisModule } from './fis';
import { PaymentusModule } from './paymentus';

@NgModule({
    imports: [
        PaymentusModule,
        FisModule,
        WellsFargoModule,
        DatacapModule,
        PointAndPayModule,
        BridgePayModule,
        CeleroModule,
        CyberSourceModule,
        DatacapModule,
        DemoModule,
        ElavonModule,
        NicModule
    ],
    exports: [
        PaymentusModule,
        FisModule,
        WellsFargoModule,
        DatacapModule,
        PointAndPayModule,
        BridgePayModule,
        CeleroModule,
        CyberSourceModule,
        DatacapModule,
        DemoModule,
        ElavonModule,
        NicModule
    ]
})
export class PaymentIntegrationsModule { }
