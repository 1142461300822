// Angular Files
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

// Other External Files

// Teller Online Files
import { CoreModule } from 'apps/public-portal/src/app/core/core.module';
import { ReceiptDownloadApiClient, FileResponse } from 'apps/public-portal/src/app/core/api/PublicPortalApiClients';

@Injectable({
    // Depending how it's used with the app, you may not be able to provide it within the module, it may need to use the alternate version
    // For core services that are used in the APP_INITIALIZER or within the app.module itself will need to use 'root'
    providedIn: 'root'
})
export class ReceiptService {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private receiptDownloadApiClient: ReceiptDownloadApiClient
    ) { }

    /** Fetch the receipt for a given paymentIdentifier and either open it in a separate window or download it through 
     * an anchor tag.
     */
    public async downloadReceipt(paymentIdentifier: string, newWindow: boolean = false): Promise<boolean> {
        let success = false;
        let response = await this.receiptDownloadApiClient.downloadReceipt(paymentIdentifier).toPromise();

        if (response?.data?.size > 0) {
            let file = new Blob([response.data as BlobPart], { type: 'application/pdf' });
            let objectUrl = URL.createObjectURL(file);

            if (newWindow){
                // This method may end up just downloading the file on android devices.
                window.open(objectUrl, "_self", "fullscreen=yes");
            } else {
                let a = this.document.createElement('a');
                a.href = objectUrl;
                a.download = response?.fileName;
                a.click();
                URL.revokeObjectURL(objectUrl);
            }
            success = true;
        } 

        return success;
    }

    public async downloadAllReceipts(cartGuid: string) {
        let response = await this.receiptDownloadApiClient.downloadAllReceipts(cartGuid).toPromise();

        if (response?.data?.size > 0) {
            let file = new Blob([response.data as BlobPart], { type: 'application/zip' });
            let objectUrl = URL.createObjectURL(file);

            let a = this.document.createElement('a');
            a.href = objectUrl;
            a.download = response?.fileName;
            a.click();
            URL.revokeObjectURL(objectUrl);
        }
    }
}