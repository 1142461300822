import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-view-bill',
    templateUrl: '../svgs/ViewBill.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--view-bill'
    }
})
export class TellerOnlineIconViewBill {
}