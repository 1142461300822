import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-envelope-tracking',
    templateUrl: '../svgs/EnvelopeTracking.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--envelope-tracking'
    }
})
export class TellerOnlineIconEnvelopeTracking {
}