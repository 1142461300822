<form #codeVerificationForm="ngForm" [attr.role]="handlesSubmit ? 'form' : 'none'" [attr.aria-label]="ariaLabel" (ngSubmit)="onSubmit_verifyCode(codeVerificationForm)">
    <mat-form-field floatLabel="always" appearance="none">
        <mat-label>Enter the code we sent to your {{requestTypeText}}</mat-label>
        <div class="code">
            <input matInput 
                autofocus 
                required
                inputmode="numeric"  
                minlength="6" 
                maxlength="6"
                [(ngModel)]="verificationCode"
                #codeModel="ngModel"
                name="Verification Code"
                mask="000000"
                (keyup)="onKeyup_setVerificationCode($event, codeVerificationForm)"
                [ngClass]="{'invalid': codeModel.invalid && codeModel.touched}"
                id="aria_validation_input">
        </div>
        <mat-error *ngIf="codeModel.invalid">{{validationService.getFieldErrorMessage(codeModel.name, codeModel)}}</mat-error>
    </mat-form-field>

    <button class="code-verification__resend not-btn" type="button" aria-live="off" (click)="resendEnabled ? onClick_resendCode() : false">    
        Didn't receive the code?  
        <span *ngIf="!resendEnabled">
            You can try again in 
            <span aria-hidden="true">{{resendCounter}} seconds.</span>
        </span>
        <span aria-hidden="true">
            <button *ngIf="resendEnabled" class="code-verification__resend__btn" mat-button color="accent" type="button" tabindex="-1">
                Try again
            </button>
        </span>

        <span aria-live="polite" class="sr-only">{{ariaResendStatus}}</span>
    </button>

    <button *ngIf="handlesSubmit" mat-flat-button color="accent" type="submit" [disabled]="modal && (appService.pageLoading$ | async)">
        <span *ngIf="(modal && !(appService.pageLoading$ | async)) || !modal">{{'Verify '+(requestTypeText | titleCasePipe)}}</span>
        <mat-spinner *ngIf="modal && (appService.pageLoading$ | async)" diameter="20"></mat-spinner>
    </button>
</form>