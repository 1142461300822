<h2 mat-dialog-title>
    Verify your new email address.
</h2>

<mat-dialog-content>
    <app-code-verification
        class="code-verification--modal"
        (verifyCode)="onVerifyCode_closeModal()"
        [email]="data.email"
        [requestType]="requestType"
        [handlesSubmit]="true"
        [codeSent]="data.codeSent"
        [modal]="true">
    </app-code-verification>
</mat-dialog-content>
