import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-cancel',
    styles: [
        ':host.teller-online-icon--cancel svg { transform: scale(1.5); }'
    ],
    templateUrl: '../svgs/Cancel.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--cancel'
    }
})
export class TellerOnlineIconCancel {
}