import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-email',
    templateUrl: '../svgs/Email.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--email'
    }
})
export class TellerOnlineIconEmail {
}