// Angular Files
import { DOCUMENT, Location } from '@angular/common';
import { Component, NgZone, OnInit, DoCheck, Inject, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

// Angular Material Files
import { MatIconRegistry } from '@angular/material/icon';

// Other External Files
import { Subscription } from 'rxjs';

// Payment Integration Files
import {
    BasePaymentProcessorComponent,
    BillingInfoFields,
    PaymentIntegrationFieldValue
} from 'apps/public-portal/src/app/payment-integrations/base/components';
import { ElavonService } from 'apps/public-portal/src/app/payment-integrations/elavon/service';
import { PaymentMethodTypeEnum } from 'apps/public-portal/src/app/payment-integrations/base/models';
import { PaymentProcessorProvider } from 'apps/public-portal/src/app/payment-integrations/base';

// Teller Online Files
import {
    AuthService,
    CartService,
    InboundRedirectService
} from 'apps/public-portal/src/app/core/services';

// Teller Online Library Files
import {
    TellerOnlineAppService,
    TellerOnlineErrorHandlerService,
    TellerOnlineSiteMetadataService
} from 'teller-online-libraries/core';
import {
    TellerOnlineMessageService,
    TellerOnlineValidationService
} from 'teller-online-libraries/shared';

declare const PayWithConverge: {
    open: any
}

@Component({
    selector: 'app-elavon',
    templateUrl: './elavon.component.html',
    styleUrls: ['./elavon.component.scss'],
    host: {
        class: 'elavon'
    }
})
export class ElavonComponent extends BasePaymentProcessorComponent implements OnInit, OnDestroy, DoCheck {
    // Public variables
    public elavonFieldError: string = null;
    public cvvMismatch: boolean = false;
    public elavonFieldsAvailable: boolean = false;
    public requiredAddressFields: BillingInfoFields[] = [
        BillingInfoFields.addressLine1
    ]

    // Private variables

    // Constants
    private PAYWITHCONVERGE_SCRIPT_ID = 'paywithconverge';
    private _formChangeSubscription: Subscription;

    // #region BasePaymentProcessorComponent property overrides
    public set paymentMethodData(paymentMethodData) {
        if(this._formChangeSubscription) this._formChangeSubscription.unsubscribe();

        super.paymentMethodData = paymentMethodData;

        // dynamically add all of the cc fields to the controls for the form group
        if(paymentMethodData.type == PaymentMethodTypeEnum.CreditCard) {
            Object.keys(this.CC_FIELDS).forEach(name => {
                this.paymentDetailsForm.addControl(name, new UntypedFormControl(new PaymentIntegrationFieldValue(null, '', name, this.CC_FIELDS[name])));
            });
            this.paymentDetailsForm.addControl(this.CC_FIELDS.ccname, new UntypedFormControl(paymentMethodData.billingInfo.fullName));
        }

        if(paymentMethodData) {
            this._formChangeSubscription = this.paymentDetailsForm.valueChanges.subscribe((value) => {
                if(paymentMethodData.type == PaymentMethodTypeEnum.CreditCard) {
                    paymentMethodData.billingInfo.fullName = value[this.CC_FIELDS.ccname];
                }
            });
        }
    }
    public get paymentMethodData() {
        return super.paymentMethodData;
    }
    // #endregion

    // Subscriptions

    constructor(
        private elavonService: ElavonService,
        private errorHandlerService: TellerOnlineErrorHandlerService,
        @Inject(DOCUMENT) private document: Document,
        ngZone: NgZone,
        location: Location,
        appService: TellerOnlineAppService,
        siteMetadataService: TellerOnlineSiteMetadataService,
        inboundRedirectService: InboundRedirectService,
        cartService: CartService,
        authService: AuthService,
        messageService: TellerOnlineMessageService,
        validationService: TellerOnlineValidationService,
        paymentProvider: PaymentProcessorProvider,
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer
    ) {
        super(appService, ngZone, location, siteMetadataService, inboundRedirectService, cartService, authService, messageService, validationService, paymentProvider, matIconRegistry, domSanitizer);
        this.loading = true;
    }

    //#region OnInit Implementation

    ngOnInit() {
        super.ngOnInit();

        this.paymentProvider.configLoaded$.subscribe(loaded => {
            if (loaded) this._initializeLightbox();
        });
    }

    //#endregion

    //#region OnDestroy Implementation

    ngOnDestroy(): void {
        this.paymentMethodData.cardNumber = undefined;
        this.paymentMethodData.cardExpiry = undefined;
    }

    //#endregion

    //#region DoCheck Implementation

    ngDoCheck(): void {
        super.ngDoCheck();
    }

    //#endregion

    //#region Event Handlers

    onSubmit_validateAndSubmit = (e) => {
        e.preventDefault();
        this._validateAndSubmit();
    };

    //#endregion

    //#region BasePaymentProcessorComponent Implementation

    public override async savePaymentMethod() {
        this.appService.triggerPageLoading('Saving information...');

        try {
            var response = await this.elavonService.savePaymentMethod({
                paymentMethodData: this.paymentMethodData,
                paymentToken: this._paymentToken,
                paymentMethodId: this.paymentMethodId
            });

            this.paymentMethodId = response.paymentMethodId;

            this.updateUrl();

            this.processingComplete.emit(response.last4);
        } catch (e) {
            this.processingError.emit(e);
        } finally {
            this.finishedDataEntry(true);
            this.appService.finishPageLoading();
        }
    }

    public override async payCart() {
        this.appService.triggerPageLoading("Processing payment, do not refresh your browser...");

        // Wait for the response.
        await this.cartService.updateCart({
            guestEmailAddress: this.paymentMethodData.billingInfo.email,
            rememberPaymentMethod: this.paymentMethodData.rememberPaymentMethod,
            paymentMethodId: null //unset any previously saved paymentMethodId incase a previous attempt to use a saved method was made
        });

        var proceed = await this.cartService.refreshCart(this._cartGuid, this.paymentMethodData.type);

        if (proceed) {
            try {
                var postPaymentResponse = await this.elavonService.payCart({
                    cartId: this._cartId,
                    paymentMethodData: this.paymentMethodData,
                    paymentToken: this._paymentToken,
                    inboundRedirectSourceId: this.inboundRedirectService.redirectSourceId
                });

                if (postPaymentResponse.cartStatus) {
                    this.processingComplete.emit(postPaymentResponse);
                } else {
                    // Display the appropriate message for the current payment method type
                    let notChargedMessage;
                    switch (this.paymentMethodData.type) {
                        case PaymentMethodTypeEnum.ECheck:
                            notChargedMessage = "Your account has not been charged.";
                            break;
                        case PaymentMethodTypeEnum.CreditCard:
                        default:
                            notChargedMessage = "Your card has not been charged.";
                            break;
                    }
                    this.messageService.notification("Unable to process payment. " + notChargedMessage + " Reason: " +
                        postPaymentResponse.errorMessage, "error", 5000);
                }

            } catch (e) {
                this.processingError.emit(e);
            } finally {
                this.finishedDataEntry(true);
                this.appService.finishPageLoading();
            }
        } else {
            this.finishedDataEntry(true);
            this.appService.finishPageLoading();
        }
    }

    //#endregion

    //#region helpers

    public async onClick_openLightbox(e) {
        this.appService.triggerPageLoading('Loading window...');

        e.preventDefault = true;
        await this._openLightbox();
    }

    public onEnter_preventDefault(e) {
        // Just prevent form submission, the onClick event will trigger the Lightbox
        e.preventDefault = true;
    }

    private async _requestSessionToken(): Promise<string> {
        let response = await this.elavonService.requestSessionToken();

        return response?.token;
    }

    private async _openLightbox() {
        var token = await this._requestSessionToken();

        var paymentFields = {ssl_txn_auth_token: token};
        var callbacks = {
            onError: error => {
                this.ngZone.run(() => {
                    this._logError(ElavonStatusEnum.Error, error);
                });
            },
            onCancelled: error => {
                this.ngZone.run(() => {
                    this._logError(ElavonStatusEnum.Cancelled, error);
                });
            },
            onDeclined: error => {
                this.ngZone.run(() => {
                    this._logError(ElavonStatusEnum.Declined, error);
                });
            },
            onApproval: response => {
                this.ngZone.run(() => {
                    this._updateElavonManagedFields(response);
                });
            }
        };

        PayWithConverge.open(paymentFields, callbacks);

        setTimeout(() => {
            this.appService.finishPageLoading();
        }, 1000);
    }

    private _logError(errorType: ElavonStatusEnum, error: any) {
        if (errorType === ElavonStatusEnum.Declined) {
            this.messageService.notification("Payment entry was declined.", "error", 5000);
        } else if (errorType === ElavonStatusEnum.Cancelled) {
            this.messageService.notification("Payment entry was cancelled.", "error", 5000);
        } else {
            this.messageService.notification("Unable to complete payment entry. Please refresh the page and try again.", "error", 5000);
            this.errorHandlerService.handleError({ message: "Elavon lightbox failed to be created. Response from Elavon was '" + errorType + "' with additional info: " + error });
        }

        this.appService.finishPageLoading();
    }

    private _validateAndSubmit() {
        this.appService.triggerPageLoading('Validating information...');

        if (this.cvvMismatch) {
            this.elavonFieldError = "CVV does not match";
        } else if (!this._paymentToken && this.paymentMethodData.type == this.PaymentMethodTypeEnum.CreditCard) {
            this.elavonFieldError = "Payment details are required";
        } else {
            this.elavonFieldError = null;

            if (this.paymentMethodData.type != this.PaymentMethodTypeEnum.CreditCard) {
                this._paymentToken = 'dummy';
            }
        }

        let additionalErrors: {[key: string]: string} = {};

        if (this.elavonFieldError)
            additionalErrors.elavonError = this.elavonFieldError;

        // Only attempt to pay the cart if the rest of the form validation has passed
        if (this.validationService.runValidation(this.paymentDetailsForm, null, false, additionalErrors)) {
            if (this.forEdit) {
                this.savePaymentMethod();
            } else {
                this.payCart();
            }
        } else {
            this.appService.finishPageLoading();
        }
    }

    private _initializeLightbox() {
        // check if the script has already been created
        if (this.elavonService.payWithConvergeUrl) {
            if (!this.document.querySelector("#" + this.PAYWITHCONVERGE_SCRIPT_ID)) {
                const node = this.document.createElement('script');
                node.id = this.PAYWITHCONVERGE_SCRIPT_ID;
                node.src = this.elavonService.payWithConvergeUrl;
                node.type = 'text/javascript';
                node.async = false;

                this.document.getElementsByTagName('head')[0].appendChild(node);
            }

            this._waitForLightbox();
        } else {
            this.messageService.notification("Unable to initialize payment entry form. Payment cannot be completed. Please refresh the page and try again.", "error", 5000);
            this.errorHandlerService.handleError({ message: "Elavon lightbox could not be initialized because payWithConvergeUrl was not provided." });
        }
    }

    /** Every 100ms check if PayWithConverge is defined, if it is, configure it, otherwise, repeat */
    private _waitForLightbox() {
        setTimeout(() => {
            if (typeof PayWithConverge != 'undefined' && this.paymentMethodData.type) {
                this.loading = false;
                this.elavonFieldsAvailable = true;
            } else {
                this._waitForLightbox();
            }
        }, 100);
    }

    private _updateElavonManagedFields(response) {
        this._paymentToken = response.ssl_token;

        this.cvvMismatch = response.ssl_cvv2_response == "N";
        this.elavonFieldError = null;

        if (this.paymentMethodData.type == this.PaymentMethodTypeEnum.CreditCard) {
            this.paymentMethodData.cardNumber = response.ssl_card_number.slice(-4);
            this.paymentMethodData.cardExpiry = response.ssl_exp_date;
            this.paymentMethodData.cardType = response.ssl_card_short_description;
        }

        this.appService.finishPageLoading();
    }

    //#endregion
}

export enum ElavonStatusEnum {
    Cancelled = "Cancelled",
    Declined = "Declined",
    Error = "Error"
}
