import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-info',
    templateUrl: '../svgs/Info.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--info'
    }
})
export class TellerOnlineIconInfo {
}