// Angular Files
import { Component, HostBinding, Input } from '@angular/core';

// Teller Online Core Files
import { TellerOnlineNotificationBannerAction, TellerOnlineNotificationBannerService } from 'teller-online-libraries/core';

@Component({
    selector: 'teller-online-notification-banner',
    templateUrl: './notification-banner.component.html',
    styleUrls: ['./notification-banner.component.scss'],
    host: {
        class: 'notification-banner'
    }
})
export class TellerOnlineNotificationBannerComponent {
    // Declare @Input variables
    @Input() svgIcon: string;
    @Input() svgIconClass: string;
    @Input() icon: string;
    @Input() iconColor: string;
    @Input() message: string;
    @Input() dismissAction: TellerOnlineNotificationBannerAction;
    @Input() resolveAction: TellerOnlineNotificationBannerAction;
    @Input() tag: string;

    @HostBinding('class.notification-banner--with-icon') get hasIcon() {
        return this.svgIcon || this.icon;
    };

    constructor(private notificationBannerService: TellerOnlineNotificationBannerService) { }

    public onClick_resolveAction() {
        this.resolveAction.click();
    }

    public onClick_dismissAction() {
        if(this.dismissAction?.click) {
            this.dismissAction.click();
        } else {
            this.notificationBannerService.dismissBanner(this.tag);
        }
    }
}
