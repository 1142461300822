import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-sync-teller',
    templateUrl: '../svgs/SyncTeller.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--sync-teller'
    }
})
export class TellerOnlineIconSyncTeller {
}