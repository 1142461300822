// Angular Files
import { Injectable } from '@angular/core';
import { PaymentMethodDto } from '../api/CoreWebApi';
import { PaymentMethodTypeEnum, PaymentMethodTypeEnumConvertor } from 'apps/public-portal/src/app/payment-integrations';


@Injectable({
    providedIn: 'root'
})
export class TellerOnlinePaymentMethodsService {
    private CARD_TYPES = ['visa', 'mastercard', 'amex', 'discover'];

    constructor() {
    }

    public checkCardType(cardType) {
        if(cardType) {
            if (cardType.toLowerCase().includes("visa")) {
                return "visa";
            } else if (cardType.toLowerCase().includes("mastercard") || cardType.toLowerCase().includes("mc") || cardType.toLowerCase().includes("m/c")) {
                return "mastercard";
            } else if (cardType.toLowerCase().includes("disc") || cardType.toLowerCase().includes("dcvr")) {
                return "discover";
            } else if (cardType.toLowerCase().includes("american") || cardType.toLowerCase().includes("amex")) {
                return "amex";
            } else {
                return "creditCard";
            }
        }
    }

    /**
     * 
     * @param cardType String representing the type of a credit card.
     * @returns true if the card type matches one of the supported merchants.
     */
    public cardTypeIsMerchant(cardType: string): boolean {
        return this.CARD_TYPES.includes(cardType);
    }

    /**
     * @param cardType String representing the card brand (visa, mastercard, amex, discover).
     * @returns Css class to provide the appropriate styling for the icon.
     */
    public getCardClass(cardType: string) {
        let normalizedCardType = this.checkCardType(cardType)
        return this.cardTypeIsMerchant(normalizedCardType) ? 'teller-online-icon-merchant-logos teller-online-icon--' + normalizedCardType : '';
    }

    /**
     * @param paymentMethod Payment method model which the icon is provided for.
     * @returns Name of the svg asset to use for the icon.
     */
    public getPaymentIcon(paymentMethod: PaymentMethodModel) {
         if (PaymentMethodTypeEnumConvertor.fromDto(paymentMethod.paymentMethodType) == PaymentMethodTypeEnum.CreditCard) {
            return this.getPaymentIconFromString(paymentMethod.cardType);
        } else if (PaymentMethodTypeEnumConvertor.fromDto(paymentMethod.paymentMethodType) == PaymentMethodTypeEnum.ECheck) {
            return 'echeck'
        }
    }

    /**
     * Used to find the appropriate payment method icon. This works even when the whole method is not present (just a string)
     * @param paymentMethodString Either the reference or card type of a given payment method.
     * @returns String indicating which svgIcon to use.
     */
    public getPaymentIconFromString(paymentMethodString: string): string {
        if (this.CARD_TYPES.includes(this.checkCardType(paymentMethodString))) {
            return 'merchant-logos'
        } else if (paymentMethodString?.toLowerCase().includes('echeck') || paymentMethodString?.toLowerCase().includes('e-check')) {
            return 'echeck'
        } else {
            return 'credit-card'
        }
    }
}

export class PaymentMethodModel extends PaymentMethodDto {
    type?: PaymentMethodTypeEnum;
}