<section [formGroup]="formGroup" *ngIf="formGroup" class="layout-grid">
    <mat-form-field appearance="standard" *ngIf="testTriggerData?.length > 0 && shouldDisplay">
        <mat-label>Payment Processor Test Triggers</mat-label>
        <mat-hint>Use this dropdown to trigger a specific response from the payment processor's sandbox environment.</mat-hint>
        <mat-select
            [(value)]="defaultTestTrigger"
            (selectionChange)="selectTestTrigger($event.value)">
            <mat-option value="">-- No Specific Test Trigger --</mat-option>
            <mat-option *ngFor="let testTrigger of testTriggerData" [value]="testTrigger.key">
                {{testTrigger.value}} ({{testTrigger.key}})
            </mat-option>
        </mat-select>
    </mat-form-field>
</section>
