<h2 mat-dialog-title id="aria_dialog_title" cdkFocusInitial tabindex="-1">{{data.title}}</h2>
<form #dialogForm="ngForm">
    <mat-dialog-content>
        <div class="dialog__content" [innerHtml]="data.content"></div>
        <div class="dialog__items" *ngIf="data.items">
            <ng-container *ngFor="let item of data.items | keyvalue">
                <span class="dialog__item-label">{{item.key | titleCasePipe}}</span>
                <span class="dialog__item-value">{{item.value}}</span>
            </ng-container>
        </div>
        <ng-container *ngFor="let field of data?.form?.fields">
            <mat-form-field floatLabel="always" *ngIf="field.type ==='textarea'">
                <mat-label> {{ field.label }} </mat-label>
                <textarea matInput
                    [(ngModel)]="formData[field.name]"
                    #fieldModel="ngModel"
                    [name]="field.label"
                    [placeholder]="field.placeholder"
                    trim-whitespace
                    [required]="field.required"></textarea>
                <mat-error>{{ validationService.getFieldErrorMessage(field.label, fieldModel) }}</mat-error>
            </mat-form-field>
        </ng-container>
        <ng-container *ngFor="let table of data.tables; index as i">
            <div class="dialog__table">
                <h3> {{table.header}} </h3>
                <table mat-table [dataSource]="table.rows">
                    <ng-container *ngFor="let columnDef of columnDefinitions[i]; index as columnIndex" [matColumnDef]="columnDef">
                        <ng-container *ngIf="table.hasColumnHeaderRow">
                            <th mat-header-cell *matHeaderCellDef [class]="'dialog__table--' + table.columnMetadata[columnIndex].contentAlignment.toString().toLowerCase()">
                                {{table.columnMetadata[columnIndex].title}}
                            </th>
                        </ng-container>
                        <td mat-cell *matCellDef="let row" [class]="'dialog__table--' + table.columnMetadata[columnIndex].contentAlignment.toString().toLowerCase()">
                            {{row[columnIndex]}}
                        </td>
                        <ng-container *ngIf="table.footer?.length > 0">
                            <td mat-footer-cell *matFooterCellDef [class]="'dialog__table--' + table.columnMetadata[columnIndex].contentAlignment.toString().toLowerCase()">
                                {{table.footer[columnIndex]}}
                            </td>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="table.hasColumnHeaderRow">
                        <tr mat-header-row *matHeaderRowDef="columnDefinitions[i]; sticky: true"></tr>
                    </ng-container>
                    <tr mat-row *matRowDef="let row; columns: columnDefinitions[i];"></tr>
                    <ng-container *ngIf="table.footer?.length > 0">
                        <tr mat-footer-row class="dialog__table__footer" *matFooterRowDef="columnDefinitions[i]"></tr>
                    </ng-container>
                </table>
            </div>
        </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="data.actions?.length > 0">
        <ng-template ngFor let-action [ngForOf]="data.actions">
            <button *ngIf="action.solidBackground" mat-flat-button [color]="action.color"
                (click)="action.click ? action.click() : onClick_actionClose(action.close, dialogForm)">{{action.text}}</button>
            <button *ngIf="!action.solidBackground" mat-button [color]="action.color"
                (click)="action.click ? action.click() : onClick_actionClose(action.close, dialogForm)">{{action.text}}</button>
        </ng-template>
    </mat-dialog-actions>
</form>