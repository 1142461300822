export class IFramePaymentPrepareResponse {
    paymentToken: string;
    convenienceFee: number;
    processorPaymentMethodType: string;

    constructor(data?: IFramePaymentPrepareResponse) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}
