<form role="form" (ngSubmit)="onSubmit_validateAndSubmit($event)" [attr.aria-labelledby]="ariaLabelledBy">
    <section [formGroup]="paymentDetailsForm" class="layout-grid payment-method-details payment-method-details--credit"
        *ngIf="paymentMethodData.type == PaymentMethodTypeEnum.CreditCard">
        <mat-form-field class="elavon__name card-holder-name" appearance="standard" floatLabel="always">
            <mat-label>{{CC_FIELDS.ccname}}</mat-label>
            <input matInput [formControlName]="CC_FIELDS.ccname" placeholder="Name as shown on card" required>
            <mat-error>{{validationService.getFieldErrorMessage(CC_FIELDS.ccname,
                paymentDetailsForm?.controls?.[CC_FIELDS.ccname])}}</mat-error>
        </mat-form-field>

        <button class="elavon__payment-info__button"
            mat-flat-button color="accent"
            type="button"
            (click)="onClick_openLightbox($event)"
            (keyup.enter)="onEnter_preventDefault($event)"
            [disabled]="!elavonFieldsAvailable">
            Enter Payment Information
        </button>

        <div class="payment-method-details__card-info">
            <mat-form-field appearance="standard">
                <mat-label>{{CC_FIELDS.ccnumber}}</mat-label>
                <input matInput disabled [value]="paymentMethodData.cardNumber ? '**** **** **** ' + paymentMethodData.cardNumber : '---- ---- ---- ----'">
                <mat-error *ngIf="elavonFieldError">{{ elavonFieldError }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard">
                <mat-label>{{CC_FIELDS.ccexp}}</mat-label>
                <input matInput disabled [value]="paymentMethodData.cardExpiry ? paymentMethodData.cardExpiry : 'MM/YY' ">
            </mat-form-field>

            <mat-form-field appearance="standard">
                <mat-label>{{CC_FIELDS.cvv}}</mat-label>
                <input matInput disabled [value]="paymentMethodData.cardNumber ? '***' : '---'">
            </mat-form-field>
        </div>

        <div class="cvv-instructions">
            <div class="cvv-instructions__note">
                <teller-online-icon-info></teller-online-icon-info>
                <span>Where do I find the CVV?</span>
            </div>
            <img class="cvv-instructions__sample" src="assets/images/CVV.png" alt="Sample credit card image showing where cvv is">
        </div>

        <ng-container *ngIf="allowSavePaymentMethod && paymentDetailsForm?.controls?.rememberPaymentMethod">
            <div class="remember-payment-method" formGroupName="rememberPaymentMethod">
                <mat-checkbox formControlName="checked">Save credit card information</mat-checkbox>
            </div>
        </ng-container>
    </section>

    <app-echeck *ngIf="paymentMethodData.type == PaymentMethodTypeEnum.ECheck"
        [allowSavePaymentMethod]="allowSavePaymentMethod"
        [formGroup]="paymentDetailsForm"
        [ECHECK_ACCOUNTTYPELIST]="ECHECK_ACCOUNTTYPELIST"
        [ECHECK_ACCOUNTOWNERTYPELIST]="ECHECK_ACCOUNTOWNERTYPELIST"
        [ECHECK_FIELDS]="ECHECK_FIELDS"
        [accountOwnerTypeEnabled]="true"
        [accountTypeEnabled]="false"
        [paymentMethodData]="paymentMethodData">
    </app-echeck>

    <h2>Billing Address</h2>

    <app-billing-info
        [forEdit]="forEdit"
        [billingInfo]="paymentMethodData.billingInfo"
        [formGroup]="paymentDetailsForm"
        [requiredFields]="requiredAddressFields">
    </app-billing-info>

    <app-echeck-disclaimer
        *ngIf="(forEdit ? eCheckDisclaimer : eCheckConfirmationMessage)
            && paymentMethodData.type == PaymentMethodTypeEnum.ECheck"
        [forEdit]="forEdit"
        [eCheckMessage]="forEdit ? eCheckDisclaimer : eCheckConfirmationMessage"
        (eCheckCheckChange)="onCheck_agreeECheckDisclaimer($event)">
    </app-echeck-disclaimer>

    <button
        mat-flat-button
        color="accent"
        type="submit"
        [disabled]="!elavonFieldsAvailable || shouldDisableECheckSubmit()">
        {{forEdit ? 'Save' : 'Pay'}}
    </button>
</form>