// Angular Files
import { CommonModule } from '@angular/common';
import { Component, NgModule, Input } from '@angular/core';

// Angular Material Files

// Other External Files

// Component Files

// Teller Online Files
import { PasswordService } from 'apps/public-portal/src/app/shared/services';

// Teller Online Library Files
import { TellerOnlineIconsModule } from "teller-online-libraries/icons";

@Component({
    selector: 'app-password-caption',
    templateUrl: './password-caption.component.html',
    styleUrls: ['./password-caption.component.scss'],
    host: {
        class: 'password-caption'
    }
})
export class PasswordCaptionComponent {
    // Declare @Input variables
    @Input() password: string;
    @Input() error: boolean;
    
    // Public variables

    // Private variables

    // Subscriptions

    constructor(public passwordService: PasswordService) { }
}
@NgModule({
    imports: [
        CommonModule,
        TellerOnlineIconsModule
    ],
    declarations: [ PasswordCaptionComponent ],
    exports: [ PasswordCaptionComponent ]
})
export class PasswordCaptionModule { }
