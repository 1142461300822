import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-user-dashboard',
    templateUrl: '../svgs/UserDashboard.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--user-dashboard'
    }
})
export class TellerOnlineIconUserDashboard {
}