// Angular Files
import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { InjectionToken, Injector, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

// Angular Material Files
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

// Teller Online Core Files
import {
    TellerOnlineLoadPanelComponent,
    TellerOnlineNotificationBannerComponent,
    TellerOnlineRailNavigationComponent
} from './components';
import { PaymentsApiClient, UsersApiClient } from './api/CoreWebApi';

// Teller Online Shared Files
import { TellerOnlineSharedModule } from 'teller-online-libraries/shared';

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');

@NgModule({
    declarations: [
        TellerOnlineLoadPanelComponent,
        TellerOnlineNotificationBannerComponent,
        TellerOnlineRailNavigationComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        A11yModule,
        MatLegacyProgressSpinnerModule,
        MatIconModule,
        MatLegacyButtonModule,
        MatLegacyListModule,
        MatLegacyTooltipModule,
        TellerOnlineSharedModule
    ],
    exports: [
        TellerOnlineLoadPanelComponent,
        TellerOnlineNotificationBannerComponent,
        TellerOnlineRailNavigationComponent
    ]
})
export class TellerOnlineCoreModule {
    constructor(injector: Injector, @Optional() @SkipSelf() parentModule?: TellerOnlineCoreModule) {
        if (parentModule) {
            throw new Error('TellerOnlineCoreModule is already loaded. Import it in the AppModule only');
        }
        // init all the clients to use the correct base url since injection tokens don't work in the apis
        PaymentsApiClient.init(injector.get(API_BASE_URL));
        UsersApiClient.init(injector.get(API_BASE_URL));
    }
}
