import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-add-to-cart',
    templateUrl: '../svgs/AddToCart.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--add-to-cart'
    }
})
export class TellerOnlineIconAddToCart {
}