export class IFrameSavePaymentMethodResponse {
    paymentMethodId: number;
    last4: string;

    constructor(data?: IFrameSavePaymentMethodResponse) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}