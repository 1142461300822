// Angular Files
import { Component } from "@angular/core";

// Teller Online Library Files
import { TellerOnlineSiteMetadataService } from 'teller-online-libraries/core';

export class FooterLinks {
    Title: string;
    Url: string;
}

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
    host: {
        class: 'footer'
    }
})
export class FooterComponent {
    constructor(
        public siteMetadata: TellerOnlineSiteMetadataService,
    ) {}
}
