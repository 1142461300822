<mat-toolbar *ngIf="siteMetadataService.authenticationEnabled && authService.isSignedIn" tabindex="-1" cdkFocusInitial>
    <p class="mobile-menu__user-greeting">Welcome back,</p>
    <p class="mobile-menu__user-name">{{userService.currentUser?.name}}</p>
</mat-toolbar>

<mat-nav-list class="navigation" role="list" aria-label="navigation">
    <a mat-list-item
        routerLinkActive="navigation-link--active"
        routerLink="/"
        [routerLinkActiveOptions]="{ exact: true }"
        class="navigation-link"
        [attr.aria-current]="appService.ariaCurrent('/', true)">
        <mat-icon mat-list-icon svgIcon="home"></mat-icon>
        <span>Home</span>
    </a>

    <a mat-list-item *ngIf="siteMetadataService.authenticationEnabled && !authService.isSignedIn"
        routerLinkActive="navigation-link--active"
        routerLink="/sign-in"
        class="navigation-link"
        [attr.aria-current]="appService.ariaCurrent('/sign-in')">
        <mat-icon mat-list-icon svgIcon="login"></mat-icon>
        <span>Sign In</span>
    </a>

    <a mat-list-item *ngIf="siteMetadataService.authenticationEnabled && !authService.isSignedIn"
        routerLinkActive="navigation-link--active"
        routerLink="/sign-up"
        class="navigation-link"
        [attr.aria-current]="appService.ariaCurrent('/sign-up')">
        <mat-icon mat-list-icon svgIcon="sign-up"></mat-icon>
        <span>Sign Up</span>
    </a>

    <mat-expansion-panel *ngIf="siteMetadataService.authenticationEnabled && authService.isSignedIn">
        <mat-expansion-panel-header class="navigation-group" [ngClass]="{'navigation-group--active': appService.currentPage('/profile')}">
            <mat-icon mat-list-icon svgIcon="preferences"></mat-icon>
            <span>Manage Profile</span>
        </mat-expansion-panel-header>
        <mat-nav-list>
            <a mat-list-item
                routerLinkActive="navigation-link--active"
                routerLink="/profile/personal-info"
                class="navigation-link"
                [attr.aria-current]="appService.ariaCurrent('/profile/personal-info')">
                Personal Info
            </a>
            <a mat-list-item
                routerLinkActive="navigation-link--active"
                routerLink="/profile/notification-settings"
                class="navigation-link"
                [attr.aria-current]="appService.ariaCurrent('/profile/notification-settings')">
                Notification Settings
            </a>
            <a mat-list-item
                routerLinkActive="navigation-link--active"
                routerLink="/profile/change-password"
                class="navigation-link"
                [attr.aria-current]="appService.ariaCurrent('/profile/change-password')">
                Change Password
            </a>
            <a mat-list-item
                *ngIf="canSavePaymentMethod"
                routerLinkActive="navigation-link--active"
                routerLink="/profile/payment-methods"
                class="navigation-link"
                [attr.aria-current]="appService.ariaCurrent('/profile/payment-methods')">
                Payment Methods
            </a>
            <a mat-list-item
                routerLinkActive="navigation-link--active"
                routerLink="/profile/payment-history"
                class="navigation-link"
                [attr.aria-current]="appService.ariaCurrent('/profile/payment-history')">
                Payment History
            </a>
        </mat-nav-list>
    </mat-expansion-panel>

    <button mat-list-item *ngIf="siteMetadataService.authenticationEnabled && authService.isSignedIn" class="navigation-link" (click)="logout()">
        <mat-icon mat-list-icon svgIcon="logout"></mat-icon>
        <span>Sign Out</span>
    </button>
</mat-nav-list>
