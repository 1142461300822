// Angular Files
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

// Other External Files
import * as uuid from "uuid";

// Payment Integration Files
import {
    PaymentMethodData,
    IFramePaymentResponse,
    IFrameSavePaymentMethodResponse,
    PaymentMethodTypeEnumConvertor
} from 'apps/public-portal/src/app/payment-integrations/base';
// For some reason if the PaymentProcessorService was included from just base, the unit tests would fail
import { PaymentProcessorService } from 'apps/public-portal/src/app/payment-integrations/base/interfaces';

// Teller Online Files
import {
    SharedPaymentIntegrationApiClient,
    IFramePaymentRequestDto,
    IFrameSavePaymentMethodRequestDto,
    IFrameSavePaymentMethodResponseDto
} from 'apps/public-portal/src/app/core/api/PaymentIntegrationApiClients';
import { PaymentStatusEnum } from 'teller-online-libraries/core';

@Injectable({
    providedIn: 'root'
})
export class DemoService extends PaymentProcessorService {
    constructor(
        private router: Router,
        sharedPaymentApi: SharedPaymentIntegrationApiClient
    ) {
        super(sharedPaymentApi);
    }

    //#region PaymentProcessorService

    public override checkout() {
        this.router.navigate(['/checkout']);
    }

    public override async payCart(params): Promise<IFramePaymentResponse> {
        let request = new IFramePaymentRequestDto();
        let paymentMethodData: PaymentMethodData = params.paymentMethodData;
        request.cartId = params.cartId;
        // Stand in for a transaction id
        request.token = uuid.v4();
        request.paymentMethodType = PaymentMethodTypeEnumConvertor.toDto(params.paymentMethodType ?? paymentMethodData.type);
        request.inboundRedirectSourceId = params.inboundRedirectSourceId;

        if(!params.saved) {
            this.mapRequest(request, paymentMethodData);
        }

        // Make the sale (Validate cart, complete payment transaction, post to teller).
        let response = await this.sharedPaymentApi.makePayment(request).toPromise();

        return new IFramePaymentResponse({
            emailAddress: response.emailAddress,
            cartStatus: PaymentStatusEnum.Finalized,
            processorTrackingId: response.processorTrackingId
        });
    }

    public override async savePaymentMethod(params): Promise<IFrameSavePaymentMethodResponse>
    {
        let request = new IFrameSavePaymentMethodRequestDto();
        let paymentMethodData: PaymentMethodData = params.paymentMethodData;

        // Stand in for a transaction id
        request.token = uuid.v4();

        this.mapRequest(request, paymentMethodData);

        var response: IFrameSavePaymentMethodResponseDto;
        if (params.paymentMethodId) {
            response = await this.sharedPaymentApi.updatePaymentMethod(params.paymentMethodId, request).toPromise();
        }else {
            response = await this.sharedPaymentApi.addPaymentMethod(request).toPromise();
        }

        return new IFrameSavePaymentMethodResponse({
            paymentMethodId: response.paymentMethodId,
            last4: request.last4
        });
    }

    //#endregion

    // Check which credit card issue this is based on card number using these rules: https://i.stack.imgur.com/Cu7PG.jpg
    // It doesn't need to be perfect because this is just a demo interface anyways
    public checkCardIssuer(cardNumber: string) {
        cardNumber = cardNumber?.replace(" ", ""); //ensure there are no spaces, just incase
        let cardType: string;
        switch(true)
        {
            // Visa starts with 4xxxxx
            case cardNumber.match(/^4\d{5}/) != null:
                cardType = "Visa";
                break;
            // Mastercard starts with 51xxxx - 55xxxx
            case cardNumber.match(/^5[1-5]\d{4}/) != null:
                cardType = "Mastercard";
                break;
            // Discover starts with 6011xx, 644xxx, or 65xxxx
            case cardNumber.match(/^(6011\d{2})|(644\d{3})|(65\d{4})/) != null:
                cardType = "Discover";
                break;
            // Amex starts with 34xxxx or 37xxxx
            case cardNumber.match(/^(34\d{4})|(37\d{4})/) != null:
                cardType = "Amex";
                break;
            // If all else fails, just assume mastercard because it's common and
            //some non-mastercard CC's are treated as such anyways
            default:
                cardType = "Mastercard"
                break;
        }
        return cardType;
    }
}
