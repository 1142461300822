export class RedirectPaymentProcessorResponse {
    /** The status of this response */
    status!: string;
    /** Unique identifier of the payment */
    paymentIdentifier!: string;
    /** Unique identifier for the cart */
    cartGuid!: string;
    /** The path of where to redirect to (will be passed to router.navigate) */
    redirectUrl!: string;
    /** Any extras desired to be passed to the router.navigate call */
    navigationExtras?: any;

    constructor(data?: RedirectPaymentProcessorResponse) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }
}