import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-download-receipt',
    templateUrl: '../svgs/DownloadReceipt.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--download-receipt'
    }
})
export class TellerOnlineIconDownloadReceipt {
}