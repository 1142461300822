import { CartStatusEnumDto } from "./api/CoreWebApi";

export enum CartStatusEnum {
    Open = "Open",
    CheckingOut = "Checking Out",
    Processing = "Processing",
    Finalized = "Finalized",
    Closed = "Closed"
}

export class CartStatusEnumConvertor {
    static fromDto(dto: CartStatusEnumDto): CartStatusEnum {
        return CartStatusEnum[dto];
    }

    static toDto(e: CartStatusEnum): CartStatusEnumDto {
        let dto: CartStatusEnumDto;
        
        switch(e) {
            case CartStatusEnum.CheckingOut:
                dto = CartStatusEnumDto.CheckingOut;
                break;
            default:
                dto = CartStatusEnumDto[e];
                break;         
        }

        return dto;
    }
}

export class CartStatusEnumHelpers {
    private static DESCRIPTIONS: {[key: string]: string} = {};

    static CartStatusEnumHelpers() {
        this.DESCRIPTIONS[CartStatusEnum.Open] = 
            `The user is still adding items to their cart prior to payment. 
            If no change happens for 24 hours and no payments were attempted, the cart will be automatically removed.
            <br><br>
            The next logical state for this cart is either Checking Out or Processing.`;

        this.DESCRIPTIONS[CartStatusEnum.CheckingOut] =
            `The user is currently on the checkout page within Teller Online and is in the process of making a payment.
            <br><br>
            If a cart remains in this state for longer than 24 hours without change and no payments have been attempted, it will be automatically removed.
            <br><br>
            The next logical state for this cart is Processing. 
            It may also move back to Open if the user decides they are not ready to pay.`;

        this.DESCRIPTIONS[CartStatusEnum.Finalized] = 
            `The payment(s) were processed and posted sucessfully and receipt(s) were sent to the user.
            <br><br>
            This is considered a final state and a cart does not typically leave this state.`;
    }
}