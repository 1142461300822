// Angular Files
import { CommonModule, DOCUMENT, KeyValuePipe } from '@angular/common';
import { Component, NgModule, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';

// Angular Material Files
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule, MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';

// Teller Online Library Files
import { TellerOnlineIconsModule } from "teller-online-libraries/icons";
import { TellerOnlineDialogOptions, TellerOnlineValidationService, TellerOnlineWindowService } from '../../services';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';

@Component({
    selector: 'teller-online-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
    host: {
        class: 'dialog'
    }
})
export class TellerOnlineDialogComponent implements OnInit, OnDestroy {
    public formData = {};
    public columnDefinitions = [];

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: TellerOnlineDialogOptions,
        @Inject(DOCUMENT) public document: Document,
        public validationService: TellerOnlineValidationService,
        private windowService: TellerOnlineWindowService,
        private dialogRef: MatLegacyDialogRef<TellerOnlineDialogComponent>
    ) {
    }

    ngOnInit(): void {
        this.data.tables?.forEach(table => {
            let header = [];
            for (let i = 0; i < table.rows[0].length; i++) {
                header.push("column_"+i);
            }
            this.columnDefinitions.push(header);
        });

        // class added here to allow proper printing styling, removed in ngOnDestroy()
        this.document.querySelector("body").classList.add('dialog-opened');
    }

    ngOnDestroy(): void {
        this.document.querySelector("body").classList.remove('dialog-opened');
    }

    onClick_actionClose(proceed: boolean | {}, form: NgForm) {
        let close = proceed;
        if(this.data.form && proceed) {
            if(!this.validationService.runValidation(form))
                return;
            // update the close value to be the formdata
            close = this.formData;
        }
        this.dialogRef.close(close);
    }
}
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DirectivesModule,
        TellerOnlineIconsModule,
        MatLegacyDialogModule,
        MatLegacyButtonModule,
        MatLegacyFormFieldModule,
        MatLegacyInputModule,
        MatTableModule,
        MatIconModule,
        KeyValuePipe,
        PipesModule
    ],
    declarations: [ TellerOnlineDialogComponent ],
    exports: [ TellerOnlineDialogComponent ]
})
export class TellerOnlineDialogModule { }
