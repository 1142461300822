// Angular Files
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'usaPhone'
})
export class USAPhonePipe implements PipeTransform {

    transform(rawNum: string, hasCountryCode: boolean = true) {
        if (!rawNum)
            return;

        // Skip the country code if it is present
        const start = hasCountryCode ? (rawNum.startsWith('+') ? 2 : 1) : 0;

        const areaCodeStr = rawNum.slice(start,start + 3);
        const midSectionStr = rawNum.slice(start + 3,start + 6);
        const lastSectionStr = rawNum.slice(start + 6);

        return `(${areaCodeStr}) ${midSectionStr}-${lastSectionStr}`;
    }

}
