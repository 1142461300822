import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-locked',
    templateUrl: '../svgs/Lock.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--locked'
    }
})
export class TellerOnlineIconLocked {
}