// Angular Files
import { Injectable } from '@angular/core';

// Teller Online Core Files
import { 
    PaymentDetailsDto, 
    PaymentOverviewDto, 
    PaymentsApiClient, 
    PaymentSummaryDto
} from '../api/CoreWebApi';

@Injectable({
    providedIn: 'root'
})
export class TellerOnlinePaymentsService {
    constructor(
        private paymentsApiClient: PaymentsApiClient
    ) {}

    /** Retrieve a list of payments for a specific user intended to populate a table/grid or list view.
     * Typcially used to populate a payments history view for a specific user.
     */
    public async getUserPayments(userId: number): Promise<PaymentSummaryModel[]> {
        return await this.paymentsApiClient.getUserPayments(userId).toPromise();
    }

    /** Retrieve the details for a specific payment, intended to populate a receipt view.
     * Typically used on a receipt or a payment details page from a history view
    */
    public async getPaymentDetails(paymentIdentifier: string): Promise<PaymentDetailsModel> {
        let dto = await this.paymentsApiClient.getPaymentDetails(paymentIdentifier).toPromise();

        let model = new PaymentDetailsModel(dto);
        model.flatten();

        return model;
    }
}

export class PaymentOverviewModel extends PaymentOverviewDto 
{
    paymentDate?: Date;
    lastChangeDate?: Date;
    sortableDate?: Date;

    public flatten() {
        if(this.summary) {
            for(let prop of Object.keys(this.summary)) {
                this[prop] = this.summary[prop];
            }
        }
        if(this.user) {
            for(let prop of Object.keys(this.user)) {
                // skip the email property because we don't want to override the existing email property
                if(prop != "email")
                    this[prop] = this.user[prop];
            }
        }
        // Add a sortableDate value that uses whichever value is more important (paymentDate takes priority if it exists)
        this.sortableDate = this.paymentDate ?? this.lastChangeDate;
    }
}
export class PaymentSummaryModel extends PaymentSummaryDto {}
export class PaymentDetailsModel extends PaymentDetailsDto {
    public cartGuid?: string;

    public flatten() {
        if(this.overview) {
            let overview = new PaymentOverviewModel(this.overview);
            overview.flatten();

            for(let prop of Object.keys(overview)) {
                this[prop] = overview[prop];
            } 
        }
    }
}
