// Angular Files
import { CommonModule } from '@angular/common';
import { Component, NgModule, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular Material Files
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule } from '@angular/material/legacy-select'

// Other External Files
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { Subscription } from 'rxjs';

// Payment Integration Files

// Teller Online Files

// Teller Online Library Files
import { TellerOnlineIconsModule } from "teller-online-libraries/icons";
import { TellerOnlineAppService } from 'teller-online-libraries/core';
import { TellerOnlineValidationService } from 'teller-online-libraries/shared';

@Component({
    selector: 'app-test-trigger',
    templateUrl: './test-trigger.component.html',
    styleUrls: ['./test-trigger.component.scss'],
    host: {
        class: 'test-trigger'
    }
})
export class TestTriggerComponent {
    // Declare @Input variables
    @Input() public shouldDisplay: boolean = false;
    @Input() public testTriggerData: {key: string, value: string}[];

    @Input() public set formGroup(value: UntypedFormGroup) {
        if(this._formChangeSubscription) this._formChangeSubscription.unsubscribe();
        this._formGroup = value;
        this.cdr.detectChanges();
    }
    public get formGroup() {
        return this._formGroup;
    }
    private _formGroup: UntypedFormGroup;

    // Declare @Output variables
    @Output() public testTriggerEvent = new EventEmitter<string>();

    public defaultTestTrigger: string = "";

    selectTestTrigger(selectedTestTrigger: string) {
        this.testTriggerEvent.emit(selectedTestTrigger);
    }

    // Subscriptions
    private _formChangeSubscription: Subscription;

    constructor(
        public validationService: TellerOnlineValidationService,
        private appService: TellerOnlineAppService,
        private cdr: ChangeDetectorRef
    ) {
    }
}
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatLegacyFormFieldModule,
        MatLegacyInputModule,
        MatLegacySelectModule,
        TellerOnlineIconsModule,
        NgxMaskDirective,
        NgxMaskPipe
    ],
    declarations: [ TestTriggerComponent ],
    exports: [TestTriggerComponent],
    providers: [provideNgxMask()]
})
export class TestTriggerModule { }
