import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-open-in-new',
    templateUrl: '../svgs/OpenInNew.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--open-in-new'
    }
})
export class TellerOnlineIconOpenInNew {
}
