import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-sms',
    templateUrl: '../svgs/SMS.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--sms'
    }
})
export class TellerOnlineIconSms {
}