<h2 mat-dialog-title class="change-phone__title" id="aria_form_label">{{ data.currentPhone ? 'Change' : 'Add' }} phone number</h2>

<mat-dialog-content>
    <form role="form" (ngSubmit)="onSubmit_changePhone(changePhoneForm)" #changePhoneForm="ngForm" aria-labelledby="aria_form_label">
        <mat-select [(value)]="changePhoneFormModel.country" #country
            (selectionChange)="onSelectionChange_determinePhoneMask()">
            <mat-select-trigger>
                <div>
                    <img class="country-flag" [src]="country.value?.flag"> +{{country.value?.dial_code}}
                </div>
            </mat-select-trigger>

            <mat-option *ngFor="let country of countriesCodes" [value]="country">
                <img class="country-flag" [src]="country.flag"> {{formatCountryCode(country)}}
            </mat-option>
        </mat-select>

        <mat-form-field appearance="standard" *ngIf="userService.emailIsVerified$ | async">
            <mat-label>Phone Number</mat-label>
            <input matInput 
                [(ngModel)]="changePhoneFormModel.number" 
                #numberModel="ngModel" 
                name="Phone Number" 
                inputmode="tel" 
                maxlength="15"
                [mask]="currentMask" 
                [showMaskTyped]="data.northAmerica">
            <mat-error *ngIf="numberModel.invalid">{{validationService.getFieldErrorMessage(numberModel.name, numberModel)}}</mat-error>
        </mat-form-field>    
        
        <button type="submit" mat-flat-button color="accent">
            Save Changes
        </button>
    </form>
</mat-dialog-content>
