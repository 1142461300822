// Angular Files
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Angular Material Files
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacySelectModule } from "@angular/material/legacy-select";

// Payment Integration Files
import {
    BasePaymentProcessorIntegrationsModule
} from 'apps/public-portal/src/app/payment-integrations/base';

// Component Files
import { ElavonComponent } from "./component";

// Teller Online Library Files
import { TellerOnlineIconsModule } from 'teller-online-libraries/icons';
import { MatCheckboxModule } from "@angular/material/checkbox";

@NgModule({
    imports: [
        CommonModule,
        TellerOnlineIconsModule,
        BasePaymentProcessorIntegrationsModule,
        MatLegacyButtonModule,
        MatLegacyInputModule,
        MatLegacyFormFieldModule,
        MatLegacySelectModule,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [ElavonComponent],
    exports: [ElavonComponent],
})
export class ElavonModule { }
