<form role="form" (ngSubmit)="onSubmit_validateAndSubmit()" [attr.aria-labelledby]="ariaLabelledBy">
    <section [formGroup]="paymentDetailsForm" class="payment-method-details payment-method-details--credit layout-grid"
        *ngIf="paymentMethodData.type == PaymentMethodTypeEnum.CreditCard">
        <mat-form-field appearance="standard" floatLabel="always" class="card-holder-name">
            <mat-label>{{CC_FIELDS.ccname}}</mat-label>
            <input matInput
                [formControlName]="CC_FIELDS.ccname"
                required maxlength="50"
                placeholder="Name as shown on card">
            <mat-error
                *ngIf="paymentDetailsForm.controls?.[CC_FIELDS.ccname]?.touched && paymentDetailsForm?.controls?.[CC_FIELDS.ccname]?.invalid">
                {{validationService.getFieldErrorMessage(CC_FIELDS.ccname,
                paymentDetailsForm.controls[CC_FIELDS.ccname])}}
            </mat-error>
        </mat-form-field>

        <div class="payment-method-details__card-info">
            <mat-form-field appearance="standard" class="bridge-pay-iframe-field" [ngClass]="{
                    'bridge-pay-iframe-field--loaded': bridgePayFieldsAvailable,
                    'bridge-pay-error--card': paymentDetailsForm?.controls?.ccnumber.value.error,
                    'bridge-pay-error--cvc': paymentDetailsForm?.controls?.cvv.value.error,
                    'bridge-pay-error--expiry': paymentDetailsForm?.controls?.ccexp.value.error
                }">
                <app-payment-integration-form-field
                    formControlName="ccfields"
                    [id]="'bridge-pay'"
                    [loaded]="bridgePayFieldsAvailable"
                    field-min-size="15"></app-payment-integration-form-field>
                <mat-error
                    id="bridge-pay-error-visible">{{paymentDetailsForm.controls.ccfields.value.error}}</mat-error>
                <!-- This span isn't being used and is effectively hidden in every way but it is required for bridge-pay to work -->
                <span id="bridge-pay-error" aria-hidden="true"></span>
            </mat-form-field>
        </div>

        <div class="cvv-instructions">
            <div class="cvv-instructions__note">
                <teller-online-icon-info></teller-online-icon-info>
                <span>Where do I find the CVC?</span>
            </div>
            <img class="cvv-instructions__sample" src="assets/images/CVV.png" alt="Sample credit card image showing where cvc is">
        </div>

        <ng-container *ngIf="allowSavePaymentMethod && paymentDetailsForm?.controls?.rememberPaymentMethod">
            <div class="remember-payment-method" formGroupName="rememberPaymentMethod">
                <mat-checkbox formControlName="checked">Save credit card information</mat-checkbox>
            </div>
        </ng-container>
    </section>

    <app-echeck *ngIf="paymentMethodData.type == PaymentMethodTypeEnum.ECheck"
        [allowSavePaymentMethod]="allowSavePaymentMethod"
        [formGroup]="paymentDetailsForm"
        [ECHECK_ACCOUNTTYPELIST]="ECHECK_ACCOUNTTYPELIST"
        [ECHECK_ACCOUNTOWNERTYPELIST]="ECHECK_ACCOUNTOWNERTYPELIST"
        [ECHECK_FIELDS]="ECHECK_FIELDS"
        [paymentMethodData]="paymentMethodData">
    </app-echeck>

    <h2>Billing Address</h2>

    <app-billing-info
        [forEdit]="forEdit"
        [billingInfo]="paymentMethodData.billingInfo"
        [formGroup]="paymentDetailsForm">
    </app-billing-info>

    <app-echeck-disclaimer
        *ngIf="(forEdit ? eCheckDisclaimer : eCheckConfirmationMessage)
            && paymentMethodData.type == PaymentMethodTypeEnum.ECheck"
        [forEdit]="forEdit"
        [eCheckMessage]="forEdit ? eCheckDisclaimer : eCheckConfirmationMessage"
        (eCheckCheckChange)="onCheck_agreeECheckDisclaimer($event)">
    </app-echeck-disclaimer>

    <button mat-flat-button
        color="accent"
        class="btn-primary btn-primary--pay"
        type="submit"
        [disabled]="!bridgePayFieldsAvailable || shouldDisableECheckSubmit()">
        {{forEdit ? 'Save' : 'Pay'}}
    </button>
</form>