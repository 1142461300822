// Angular Files
import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-merchant-logos',
    templateUrl: '../svgs/MerchantLogos.svg',
    styles: [
        ':host svg {height: 100%; width: auto}',

        ':host.teller-online-icon--mastercard .mastercard { transform: scale(2) translate(-90px, -19px); }',
        ':host.teller-online-icon--mastercard .discover, :host.teller-online-icon--mastercard .amex, :host.teller-online-icon--mastercard .visa { display: none;}',
        
        ':host.teller-online-icon--discover .discover { transform: scale(2) translate(-230px, -19px);}',
        ':host.teller-online-icon--discover .mastercard, :host.teller-online-icon--discover .amex, :host.teller-online-icon--discover .visa { display: none;}',
        
        ':host.teller-online-icon--visa .visa { transform: scale(2) translate(-159px, -19px);}',
        ':host.teller-online-icon--visa .discover, :host.teller-online-icon--visa .mastercard, :host.teller-online-icon--visa .amex { display: none;}',
        
        ':host.teller-online-icon--amex .amex { transform: scale(2) translate(-17px, -19px);}',
        ':host.teller-online-icon--amex .discover, :host.teller-online-icon--amex .mastercard, :host.teller-online-icon--amex .visa { display: none;}'
    ],
    host: {
        class: 'teller-online-icon teller-online-icon--merchant-logos'
    }
})
export class TellerOnlineIconMerchantLogos {
}