// Angular Files
import {
    Component,
    Inject,
    NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';

// Angular Material Files
import { MatLegacyDialogModule, MatLegacyDialogRef, MAT_LEGACY_DIALOG_DATA } from '@angular/material/legacy-dialog';

// Teller Online Files
import { ConfirmCodeRequestTypeEnumDto } from 'apps/public-portal/src/app/core/api/TellerOnlineIdentityApiClients';
import { CodeVerificationModule } from 'apps/public-portal/src/app/shared/components/code-verification/code-verification.component';

// Teller Online Library Files
import { TellerOnlineMessageService } from 'teller-online-libraries/shared';

@Component({
    selector: 'app-email-verify',
    templateUrl: './email-verify.component.html',
    styleUrls: ['./email-verify.component.scss'],
    host: {
        class: 'email-verify'
    }
})
export class EmailVerifyComponent {
    public requestType: ConfirmCodeRequestTypeEnumDto = ConfirmCodeRequestTypeEnumDto.Email;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: EmailVerifyOptions,
        public dialogRef: MatLegacyDialogRef<EmailVerifyComponent>,
        private messageService: TellerOnlineMessageService
    ) {}

    async onVerifyCode_closeModal(){
        this.messageService.notification("Your email has been confirmed.", "success", 1500);
        this.dialogRef.close(true);
    }
}

@NgModule({
    imports: [
        CommonModule,
        MatLegacyDialogModule,
        CodeVerificationModule
    ],
    declarations: [EmailVerifyComponent],
    exports: [EmailVerifyComponent]
})
export class EmailVerifyModule { }

class EmailVerifyOptions {
    email: string;
    codeSent: boolean = true;
}
