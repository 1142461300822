// Angular Files
import { Injectable } from "@angular/core";

// Other External Files
import { BehaviorSubject, Subject } from "rxjs";
import { map } from "rxjs/operators";

// Teller Online Files
import { CoreModule } from "apps/public-portal/src/app/core/core.module";
import {
    UserProfileApiClient,    
    UserProfileDto,
    DeleteUserProfileDto,
    IUserNotificationSettingsDto,
    UserNotificationSettingsDto,
} from "apps/public-portal/src/app/core/api/TellerOnlineIdentityApiClients";
import { UserPaymentsApiClient, UserPaymentsDto } from "apps/public-portal/src/app/core/api/PublicPortalApiClients";

@Injectable({
    providedIn: CoreModule
})
export class UserService {
    private _currentUser = new BehaviorSubject<UserProfileDto>(null);
    public currentUser$ = this._currentUser.asObservable();

    private _notificationSettings = new BehaviorSubject<IUserNotificationSettingsDto>({});
    public notificationSettings$ = this._notificationSettings.asObservable();

    private _emailIsVerified = new BehaviorSubject<boolean>(false);
    public emailIsVerified$ = this._emailIsVerified.asObservable();

    constructor(
        private userProfileApiClient: UserProfileApiClient,
        private userPaymentsApiClient: UserPaymentsApiClient
    ) {}

    public get currentUser(): UserProfileModel {
        return this._currentUser.getValue();
    }

    public get isEmailVerified() {
        return this._emailIsVerified.value;
    }

    public async getUserProfile(): Promise<UserProfileModel> {
        var response = await this.userProfileApiClient.getUserProfile().toPromise();
        this._currentUser.next(response);
        this._emailIsVerified.next(response.emailVerified);
        return response;
    }

    public updateUserProfile(userData) {
        return this.userProfileApiClient.updateUserProfile(userData).pipe(
            map(() => {this._currentUser.next(userData)})
        );
    }

    public async deleteUserProfile(data: DeleteUserProfileModel) {
        await this.userProfileApiClient.deleteUserProfile(data).toPromise();
    }

    public clearUserProfile() {
        this._currentUser.next(null);
        this._emailIsVerified.next(false);
    }

    public async getUserNotificationSettings(): Promise<UserNotificationSettings> {
        return await this.userProfileApiClient.getUserNotificationSettings().toPromise();
    }

    public async updateUserNotificationSettings(data: UserNotificationSettingsDto) {
        await this.userProfileApiClient.updateUserNotificationSettings(data).toPromise();
    }


    public async getUserPayments(): Promise<UserPaymentModel[]> {
        return await this.userPaymentsApiClient.getUserPayments().toPromise();
    }
}

export class UserNotificationSettings extends UserNotificationSettingsDto {}

export class DeleteUserProfileModel extends DeleteUserProfileDto {}

export class UserProfileModel extends UserProfileDto {}

export class UserPaymentModel extends UserPaymentsDto {}
