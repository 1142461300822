<ng-container *ngIf="item.template != 'loadMore'">
    <mat-toolbar class="search-result__title" [matTooltip]="getSearchDisplayName(item)?.length > 100 ? getSearchDisplayName(item) : ''">
        <h2>{{getSearchDisplayName(item)}}</h2>
        <button *ngIf="item.template != 'heading'" mat-icon-button (click)="onClick_collapse()" [attr.aria-label]="'View details for \''+item.title+'\''">
            <mat-icon *ngIf="collapsed">expand_more</mat-icon>
            <mat-icon *ngIf="!collapsed">expand_less</mat-icon>
        </button>
    </mat-toolbar>

    <div class="search-result__wrapper" [@detailExpand]="collapsed ? 'collapsed' : 'expanded'">
        <mat-list *ngIf="standardDetails?.length > 0" class="search-result__details" role="list" [attr.aria-label]="item.title + ' details'">
            <mat-list-item *ngFor="let detail of standardDetails" class="search-result__detail">
                <!-- 2 lines if narrow -->
                <span class="label" mat-line *ngIf="appService.isPhone && detail.type == ItemDetailTypeEnum.Default">{{detail.description}}</span>
                <span mat-line *ngIf="appService.isPhone && detail.type == ItemDetailTypeEnum.Default">{{detail.value}}</span>
                <!-- 1 line if wide -->
                <div mat-line *ngIf="!appService.isPhone && detail.type == ItemDetailTypeEnum.Default" class="search-result__single-line">
                    <span class="label">{{detail.description}}</span>
                    <span>{{detail.value}}</span>
                </div>
            </mat-list-item>
        </mat-list>

        <div *ngIf="actionableDetails?.length > 0" class="search-result__actions" [attr.aria-label]="item.title + ' actions'">
            <ng-container *ngFor="let detail of actionableDetails">
                <a mat-button
                    *ngIf="detail.type == ItemDetailTypeEnum.Download && detail.billUrl"
                    color="accent"
                    [attr.aria-label]="'View ' + detail.description"
                    [href]="detail.billUrl"
                    target="_blank">
                    <mat-icon *ngIf="!detail.downloading" svgIcon="open-in-new" class="teller-online-icon"></mat-icon>
                    <mat-spinner *ngIf="detail.downloading" class="search-result__detail__download-spinner" diameter="24" color="accent"></mat-spinner>
                    <span>{{ detail.description.replace("_download_", '') }}</span>
                </a>
                <button mat-button
                    *ngIf="detail.type == ItemDetailTypeEnum.Download && !detail.billUrl"
                    color="accent"
                    [attr.aria-label]="'View ' + detail.description"
                    (click)="viewFileError()">
                    <mat-icon *ngIf="!detail.downloading" svgIcon="open-in-new" class="teller-online-icon"></mat-icon>
                    <mat-spinner *ngIf="detail.downloading" class="search-result__detail__download-spinner" diameter="24" color="accent"></mat-spinner>
                    <span>{{ detail.description.replace("_download_", '') }}</span>
                </button>
                <button *ngIf="detail.type == ItemDetailTypeEnum.Popup"
                    aria-haspopup="dialog"
                    mat-button
                    color="accent"
                    (click)="onClick_popup(detail.value)">
                    <mat-icon *ngIf="getPopupSvg(detail.value)" [svgIcon]="getPopupSvg(detail.value)" class="teller-online-icon"></mat-icon>
                    <span>{{ detail.description.replace("_popup_", '') }}</span>
                </button>
                <a *ngIf="detail.type == ItemDetailTypeEnum.Link"
                    class="search-result__detail__link"
                    [href]="detail.value"
                    target="_blank">
                    <mat-icon class="teller-online-icon">link</mat-icon>
                    {{ detail.description.replace("_link_", '') }}
                </a>
            </ng-container>
        </div>


        <ng-container *ngIf="item.template != 'heading' && itemIsPayable">
            <ng-container *ngIf="item.amountInCart && (item.selectedPaymentOption || item.selectedPaymentOptions?.length)">
                <div class="search-result__change-payment-option" [ngClass]="{'search-result__change-payment-option--invalid': !itemHasSinglePaymentOptionSelected}">
                    <span class="search-result__change-payment-option__name">
                        Payment Total {{itemHasSinglePaymentOptionSelected ? '(' + item.selectedPaymentOption?.label + ')' : ''}}
                    </span>

                    <div class="search-result__change-payment-option__section">
                        <span *ngIf="itemHasSinglePaymentOptionSelected" class="search-result__change-payment-option__amount">{{item.selectedPaymentOption.amount | currency: "USD"}}</span>

                        <button type="button" mat-flat-button color="accent" class="search-result__change-payment-option__choose"
                            [attr.aria-haspopup]="checkPaymentOptionNeedsPrompt(item?.paymentOptions) ? 'menu' : false"
                            *ngIf="item.selectedPaymentOptions?.length > 1"
                            aria-controls="aria_cart"
                            (click)="!checkPaymentOptionNeedsPrompt(item?.paymentOptions) ? on_addToCart([item, item?.paymentOptions[0]]) : openSelectPayment()">
                            <mat-icon svgIcon="select-payment" class="teller-online-icon"></mat-icon>
                            <i aria-hidden="true" [matMenuTriggerFor]="selectpayment"></i>
                            <span>Choose Payment Option</span>
                        </button>

                        <button type="button" mat-button color="accent" class="search-result__change-payment-option__change"
                            [attr.aria-haspopup]="checkPaymentOptionNeedsPrompt(item?.paymentOptions) ? 'menu' : false"
                            *ngIf="itemHasSinglePaymentOptionSelected && checkPaymentOptionNeedsPrompt(item.paymentOptions)"
                            (click)="!checkPaymentOptionNeedsPrompt(item?.paymentOptions) ? on_addToCart([item, item?.paymentOptions[0]]) : openSelectPayment()">
                            <i aria-hidden="true" [matMenuTriggerFor]="selectpayment"></i>
                            <span>Change</span>
                        </button>
                    </div>
                </div>
            </ng-container>

            <button mat-button
                color="accent"
                type="button"
                class="search-result__remove-btn search-result__details__cart-btn"
                *ngIf="item.amountInCart"
                (click)="onClick_removeFromCart(item)"
                aria-controls="aria_cart">
                <mat-icon svgIcon="remove" class="teller-online-icon"></mat-icon>
                <span>Remove From Cart</span>
            </button>

            <button mat-flat-button
                [attr.aria-haspopup]="checkPaymentOptionNeedsPrompt(item?.paymentOptions) ? 'menu' : false"
                color="accent"
                type="button"
                class="search-result__add-btn search-result__details__cart-btn"
                *ngIf="!item.amountInCart"
                [disabled]="paymentDisabled || siteMetadata.parseMenuNotices(item.page.pageId)"
                [matTooltip]="paymentDisabled ? paymentDisabledMessage : ''"
                matTooltipPosition="after"
                (click)="!checkPaymentOptionNeedsPrompt(item?.paymentOptions) ? on_addToCart([item, item?.paymentOptions[0]]) : openSelectPayment()"
                aria-controls="aria_cart">
                <mat-icon svgIcon="add-to-cart" class="teller-online-icon"></mat-icon>
                <i aria-hidden="true" [matMenuTriggerFor]="selectpayment"></i>

                <span>Add to Cart</span>
            </button>

            <mat-menu class="search-result__select-payment" #selectpayment="matMenu">
                <app-select-payment
                    *ngIf="appService.isWide && selectPaymentMenuOpen"
                    [item]="item"
                    (closePopup)="trigger?.closeMenu()"
                    [conflictingOptions]="conflictingOptions ?? (item.selectedPaymentOptions?.length > 1 ? item.selectedPaymentOptions : null)"
                    (addToCart)="on_addToCart($event)"
                    (updateCartItem)="on_updateCartItem($event)">
                </app-select-payment>
            </mat-menu>
        </ng-container>
    </div>
</ng-container>

<ng-container *ngIf="item.template === 'loadMore'">
    <button class="search-result__load-more" mat-flat-button type="button" color="accent" (click)="onClick_loadMore()">
        Show More Results
    </button>
</ng-container>
