// Angular Files
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Teller Online Pipe Files
import { ObscurePhonePipe } from './obscure-phone.pipe';
import { ObscureEmailPipe } from './obscure-email.pipe';
import { EmailPipe } from './email.pipe';
import { USAPhonePipe } from './usa-phone.pipe';
import { SortByDatePipe } from './sort-by-date.pipe';
import { SumPipe } from './sum.pipe';
import { PluralPipe } from './plural.pipe';
import { TitleCasePipe } from './title-case.pipe';
import { ParseExpiryPipe } from './parse-expiry.pipe';
import { SentenceCasePipe } from './sentencecase.pipe';

@NgModule({
    declarations: [
        ObscurePhonePipe, 
        ObscureEmailPipe, 
        EmailPipe, 
        SortByDatePipe, 
        USAPhonePipe, 
        SumPipe, 
        PluralPipe, 
        TitleCasePipe, 
        ParseExpiryPipe,
        SentenceCasePipe
    ],
    imports: [CommonModule],
    exports: [
        ObscurePhonePipe, 
        ObscureEmailPipe, 
        EmailPipe, 
        SortByDatePipe, 
        USAPhonePipe, 
        SumPipe, 
        PluralPipe, 
        TitleCasePipe, 
        ParseExpiryPipe,
        SentenceCasePipe
    ]
})
export class PipesModule { }
