import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-view-bill-details',
    templateUrl: '../svgs/ViewBillDetails.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--view-bill-details'
    }
})
export class TellerOnlineIconViewBillDetails {
}