<mat-list role="list" aria-label="Available payment options" (keydown)="onKeyDown_handleFocus($event)" [appRestrictFocus]="true">
    <mat-list-item class="select-payment__option" *ngFor="let paymentOption of paymentOptions" [ngClass]="{'select-payment__option--custom': paymentOption.isCustomAmount}">
        <button mat-button class="select-payment__option__btn"
            (click)="!paymentOption.isCustomAmount || paymentOption.error ? ( item.amountInCart ? onClick_updateCartItem(item, paymentOption) : onClick_addToCart(item, paymentOption) ) : onClick_showCustomAmount($event)"
            [ngClass]="{
                        'select-payment__option--selected': paymentOption.selected
                    }">
            <div class="select-payment__option__content">
                {{paymentOption.label}}
                <span *ngIf="!paymentOption.isCustomAmount || paymentOption.error">{{paymentOption.amount | currency}}</span>
            </div>
        </button>

        <form role="form"
            *ngIf="paymentOption.isCustomAmount && !paymentOption.error && (paymentOption.selected || showCustomAmountField)"
            class="select-payment__option__form" (ngSubmit)="onSubmit_customPaymentAmount()" [formGroup]="customAmountForm"
            aria-controls="aria_cart" (click)="$event.stopPropagation()">
            <mat-form-field appearance="standard" class="select-payment__custom-amount mat-form-field--prefix">
                <input #customAmountInput 
                    matInput 
                    name="customAmountInput" 
                    formControlName="Amount" 
                    required
                    step="0.01"
                    placeholder="0.00" 
                    inputmode="decimal" 
                    mask="separator.2" 
                    separatorLimit="1000000000" 
                    thousandSeparator=","
                    (focus)="onFocusIn_selectText()">
                <mat-icon matPrefix>attach_money</mat-icon>
                <mat-error>{{validationService.getFieldErrorMessage("Amount",
                    customAmountForm?.controls?.["Amount"])}}</mat-error>
            </mat-form-field>

            <button mat-flat-button 
                aria-controls="aria_cart" 
                class="select-payment__option__form__button" 
                color="accent"
                type="submit">
                <mat-icon svgIcon="payment-complete" class="teller-online-icon"></mat-icon>
            </button>
        </form>
    </mat-list-item>
</mat-list>