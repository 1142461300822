<header class="layout-grid">
    <mat-toolbar fixedInViewPort>
        <button
            type="button"
            aria-label="Toggle menu navigation"
            mat-icon-button
            (click)="onClick_toggleMobileMenu()"
            *ngIf="!appService.isWide && !inboundRedirectService.isInboundRedirectUIContext">
            <mat-icon aria-label="Menu navigation toggle icon" svgIcon="menu"></mat-icon>
        </button>

        <div class="header__logo" *ngIf="!inboundRedirectService.isInboundRedirectUIContext">
            <a routerLink="/"><img [src]="logoSource" [alt]="siteTitle + ' Home'"></a>
        </div>
        <div class="header__logo" *ngIf="inboundRedirectService.isInboundRedirectUIContext">
            <img [src]="logoSource" [alt]="siteTitle + ' Logo'">
        </div>

        <nav aria-label="Primary" *ngIf="!appService.isWide && inboundRedirectService.isInboundRedirectUIContext" class="navigation">
            <!-- We need to display a mat-flat-button on the receipt page -->
            <a mat-flat-button color="accent"
               *ngIf="isCheckoutSuccessPage"
               (click)="onClick_cancelOrCompletePayment()"
               class="navigation-link">
                <span>{{returnToSenderLabel}}</span>
            </a>
            <!-- and a mat-button on all other pages (currently limited to the checkout page, but this list will expand) -->
            <a mat-button
               *ngIf="!isCheckoutSuccessPage"
               (click)="onClick_cancelOrCompletePayment()"
               class="navigation-link">
                <span>{{returnToSenderLabel}}</span>
            </a>
        </nav>

        <nav aria-label="Primary" *ngIf="appService.isWide" class="navigation">
            <!-- We need to display a mat-flat-button on the receipt page -->
            <a mat-flat-button color="accent"
               *ngIf="inboundRedirectService.isInboundRedirectUIContext && isCheckoutSuccessPage"
               (click)="onClick_cancelOrCompletePayment()"
               class="navigation-link">
                <span>{{returnToSenderLabel}}</span>
            </a>

            <!-- and a mat-button on all other pages (currently limited to the checkout page, but this list will expand) -->
            <a mat-button
               *ngIf="inboundRedirectService.isInboundRedirectUIContext && !isCheckoutSuccessPage"
               (click)="onClick_cancelOrCompletePayment()"
               class="navigation-link">
                <span>{{returnToSenderLabel}}</span>
            </a>

            <a mat-button
                *ngIf="!inboundRedirectService.isInboundRedirectUIContext"
                routerLink="/"
                routerLinkActive="navigation-link--active"
                [routerLinkActiveOptions]="{ exact: true }"
                class="navigation-link"
                [attr.aria-current]="appService.ariaCurrent('/', true)">
                <mat-icon svgIcon="home" class="teller-online-icon"></mat-icon>
                <span>Home</span>
            </a>

            <a mat-button *ngIf="siteMetadataService.authenticationEnabled && !authService.isSignedIn && !inboundRedirectService.isInboundRedirectUIContext"
                routerLink="/sign-in"
                routerLinkActive="navigation-link--active"
                class="navigation-link"
                [attr.aria-current]="appService.ariaCurrent('/sign-in')">
                <mat-icon svgIcon="login" class="teller-online-icon"></mat-icon>
                <span>Sign In</span>
            </a>

            <a mat-flat-button color="accent"
               *ngIf="siteMetadataService.authenticationEnabled && !authService.isSignedIn && !inboundRedirectService.isInboundRedirectUIContext"
                routerLink="/sign-up"
                routerLinkActive="navigation-link--active"
                class="navigation-link"
                [attr.aria-current]="appService.ariaCurrent('/sign-up')">
                <mat-icon svgIcon="sign-up" class="teller-online-icon"></mat-icon>
                <span>Sign Up</span>
            </a>

            <ng-container *ngIf="siteMetadataService.authenticationEnabled && authService.isSignedIn && !inboundRedirectService.isInboundRedirectUIContext">
                <button mat-button [matMenuTriggerFor]="profilenav" class="navigation-group" [ngClass]="{'navigation-group--active': appService.currentPage('/profile')}">
                    <mat-icon svgIcon="user" class="teller-online-icon"></mat-icon>
                    {{userService.currentUser?.name}}
                    <mat-icon>expand_more</mat-icon>
                </button>
                <mat-menu #profilenav="matMenu" class="navigation">
                    <a mat-menu-item
                        routerLink="/profile/personal-info"
                        routerLinkActive="navigation-link--active"
                        class="navigation-link"
                        [attr.aria-current]="appService.ariaCurrent('/profile/personal-info')">Personal Info</a>
                    <a mat-menu-item
                        routerLink="/profile/notification-settings"
                        routerLinkActive="navigation-link--active"
                        class="navigation-link"
                        [attr.aria-current]="appService.ariaCurrent('/profile/notification-settings')">Notification Settings</a>
                    <a mat-menu-item
                        routerLink="/profile/change-password"
                        routerLinkActive="navigation-link--active"
                        class="navigation-link"
                        [attr.aria-current]="appService.ariaCurrent('/profile/change-password')">Change Password</a>
                    <a mat-menu-item
                        *ngIf="canSavePaymentMethod"
                        routerLink="/profile/payment-methods"
                        routerLinkActive="navigation-link--active"
                        class="navigation-link"
                        [attr.aria-current]="appService.ariaCurrent('/profile/payment-methods')">Payment Methods</a>
                    <a mat-menu-item
                        routerLink="/profile/payment-history"
                        routerLinkActive="navigation-link--active"
                        class="navigation-link"
                        [attr.aria-current]="appService.ariaCurrent('/profile/payment-history')">Payment History</a>
                    <button mat-menu-item (click)="onClick_signOut()" class="navigation-link">Sign Out</button>
                </mat-menu>
            </ng-container>
        </nav>

        <button (click)="onClick_toggleHelp()"
                *ngIf="!inboundRedirectService.isInboundRedirectUIContext"
                mat-icon-button class="help-button"
                aria-label="Help">
            <mat-icon svgIcon="help"></mat-icon>
        </button>
    </mat-toolbar>
</header>
