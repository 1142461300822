// Angular Files
import { NgModule } from '@angular/core';

// Teller Online Payment Processor Integration Files
import { BillingInfoModule } from './billing-info/billing-info.component';
import { ECheckModule } from './echeck/echeck.component';
import { PaymentIntegrationFormFieldModule } from './payment-integration-form-field/payment-integration-form-field.component';
import { TestTriggerModule } from "./test-trigger/test-trigger.component";
import { EcheckDisclaimerModule } from "./echeck-disclaimer/echeck-disclaimer.component"

@NgModule({
    imports: [
        BillingInfoModule,
        TestTriggerModule,
        ECheckModule,
        PaymentIntegrationFormFieldModule,
        EcheckDisclaimerModule
    ],
    exports: [
        BillingInfoModule,
        TestTriggerModule,
        ECheckModule,
        PaymentIntegrationFormFieldModule,
        EcheckDisclaimerModule
    ]
})
export class BasePaymentProcessorIntegrationsModule { }
