import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-search',
    templateUrl: '../svgs/MagnifyingGlass.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--search'
    }
})
export class TellerOnlineIconSearch {
}