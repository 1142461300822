// Angular Files
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Angular Material Files
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';

// Payment Integration Files
import {
    BasePaymentProcessorIntegrationsModule
} from 'apps/public-portal/src/app/payment-integrations/base';

// Component Files
import { BridgePayComponent } from "./component";

// Teller Online Library Files
import { TellerOnlineIconsModule } from 'teller-online-libraries/icons';


@NgModule({
    imports: [
        CommonModule,
        TellerOnlineIconsModule,
        BasePaymentProcessorIntegrationsModule,
        FormsModule,
        ReactiveFormsModule,
        MatLegacyButtonModule,
        MatLegacyFormFieldModule,
        MatLegacyInputModule,
        MatLegacyCheckboxModule
    ],
    declarations: [BridgePayComponent],
    exports: [BridgePayComponent],
})
export class BridgePayModule { }
