// Other External Files
import { BehaviorSubject, Observable, Subject } from "rxjs";

// Payment Integration Files
import {
    IFramePaymentResponse,
    IFrameSavePaymentMethodResponse,
    PaymentMethodTypeEnum,
    RedirectPaymentProcessorResponse,
    PaymentMethodData,
    PaymentMethodTypeEnumConvertor
} from "apps/public-portal/src/app/payment-integrations/base/models";

// Teller Online Files
import {
    SharedPaymentIntegrationApiClient,
    IFrameSavePaymentMethodRequestDto,
    IFramePaymentRequestDto,
    ECheckAccountTypeEnumDto,
    ECheckAccountOwnerTypeEnumDto,
} from "apps/public-portal/src/app/core/api/PaymentIntegrationApiClients";
import { Country } from 'apps/public-portal/src/app/shared/constants';
import { IFramePaymentPrepareResponse } from "apps/public-portal/src/app/payment-integrations/base/models/IFramePaymentPrepareResponse";

export abstract class PaymentProcessorService {
    protected _errorEvent: Subject<string> = new Subject();
    public errorEvent$: Observable<string> = this._errorEvent.asObservable();

    public supportsTokenization: boolean;
    public redirect: boolean = false;
    public systemPaymentIdentifierKey: string;

    protected constructor(protected sharedPaymentApi: SharedPaymentIntegrationApiClient) { }

    /** Handles appropriate redirects in order to checkout:
     * Checkout page for iframes
     * External url for redirects
     */
    public abstract checkout(cartGuid?: string): any;

    /** Handles a postback response from a redirect integration */
    public handlePostbackResponse(params): Promise<RedirectPaymentProcessorResponse> {
        //Do nothing
        return new Promise<any>(null);
    }

    public get hasPrepareStep() { return false };

    public preparePayment(params): Promise<IFramePaymentPrepareResponse> {
        return new Promise<any>(null);
    }

    /** Handles paying for a cart from the checkout page for an iframe integration */
    public payCart(params): Promise<IFramePaymentResponse> {
        //Do nothing
        return new Promise<IFramePaymentResponse>(null);
    }

    /** Handles updating a payment method for an iframe integration */
    public savePaymentMethod(params): Promise<IFrameSavePaymentMethodResponse> {
        //Do nothing
        return new Promise<IFrameSavePaymentMethodResponse>(null);
    }

    //#region helpers

    /**
     * Assigns processor specific properties to the service. Can be overidden if necessary
     * (e.g. additional property names are different than service property name).
     * @param additionalProps Object with properties to be mapped to the service
     * @param processorType Specify which type of payment type the service is being configured for.
     */
    public assignAdditionalProps(additionalProps: {[key: string]: any}, processorType?: PaymentMethodTypeEnum) {
        Object.assign(this, additionalProps);
    }

    protected mapRequest(request: IFrameSavePaymentMethodRequestDto | IFramePaymentRequestDto,
                         paymentMethodData: PaymentMethodData,
                         token?: string,
                         testTrigger?: string) {
        request.paymentMethodType ??= PaymentMethodTypeEnumConvertor.toDto(paymentMethodData.paymentMethodType);

        if (paymentMethodData.billingInfo.fullName) request.fullName = paymentMethodData.billingInfo.fullName;
        if (paymentMethodData.billingInfo.addressZip) request.addressZip = paymentMethodData.billingInfo.addressZip;
        if (paymentMethodData.billingInfo.addressLine1) request.addressLine1 = paymentMethodData.billingInfo.addressLine1;
        if (paymentMethodData.billingInfo.addressLine2) request.addressLine2 = paymentMethodData.billingInfo.addressLine2;
        if (paymentMethodData.billingInfo.addressCity) request.addressCity = paymentMethodData.billingInfo.addressCity;
        if (paymentMethodData.billingInfo.phone) request.phone = paymentMethodData.billingInfo.phone;
        if (token) request.token = token;
        if (testTrigger) request.paymentProcessorTestTrigger = testTrigger;

        if (paymentMethodData.billingInfo.addressCountry?.name) request.addressCountry = paymentMethodData.billingInfo.addressCountry.name;
        if (paymentMethodData.billingInfo.addressCountry?.code) request.addressCountryCode = paymentMethodData.billingInfo.addressCountry.code;

        if (Country.HasRegionList(request.addressCountryCode)) {
            request.addressStateCode = paymentMethodData.billingInfo.addressState?.code ?? undefined;
            request.addressState = paymentMethodData.billingInfo.addressState?.name ?? undefined;
        } else {
            request.addressStateCode = paymentMethodData.billingInfo.addressRegion ?? undefined;
            request.addressState = paymentMethodData.billingInfo.addressRegion ?? undefined;
        }

        switch (paymentMethodData.type) {
            case PaymentMethodTypeEnum.CreditCard:
                request.cardType = paymentMethodData.cardType;
                request.cardExpiry = paymentMethodData.cardExpiry;
                if (paymentMethodData.cardNumber) request.last4 = paymentMethodData.cardNumber.slice(-4);
                break;
            case PaymentMethodTypeEnum.ECheck:
                request.last4 = paymentMethodData.echeckAccountNumber.slice(-4);
                request.eCheckAccountNumber = paymentMethodData.echeckAccountNumber;
                request.eCheckAccountType = ECheckAccountTypeEnumDto[paymentMethodData.echeckAccountType];
                request.eCheckRoutingNumber = paymentMethodData.echeckRoutingNumber;
                request.eCheckAccountOwnerType = ECheckAccountOwnerTypeEnumDto[paymentMethodData.echeckAccountOwnerType];
                break;
        }
    }

    //#endregion
}
