// Angular Files
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

// Component Files
import { PointAndPayComponent } from './component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    declarations: [ PointAndPayComponent ],
    exports: [ PointAndPayComponent ]
})
export class PointAndPayModule { }