import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-mark-refunded',
    templateUrl: '../svgs/MarkRefunded.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--mark-refunded'
    }
})
export class TellerOnlineIconMarkRefunded {
}