import { PaymentMethodTypeEnumDto } from "apps/public-portal/src/app/core/api/PaymentIntegrationApiClients";

export enum PaymentMethodTypeEnum {
    CreditCard = 'Credit Card',
    ECheck = 'ECheck',
    Undetermined = 'Undetermined'
}

export class PaymentMethodTypeEnumConvertor {
    static fromDto(dto: PaymentMethodTypeEnumDto): PaymentMethodTypeEnum {
        return PaymentMethodTypeEnum[dto];
    }

    static toDto(e: PaymentMethodTypeEnum): PaymentMethodTypeEnumDto {
        let dto: PaymentMethodTypeEnumDto;

        switch(e) {
            case PaymentMethodTypeEnum.CreditCard:
                dto = PaymentMethodTypeEnumDto.CreditCard;
                break;
            case PaymentMethodTypeEnum.ECheck:
                dto = PaymentMethodTypeEnumDto.ECheck;
                break;
            case PaymentMethodTypeEnum.Undetermined:
                dto = PaymentMethodTypeEnumDto.Undetermined;
                break;
        }

        return dto;
    }  
}
