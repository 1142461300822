export class AmericanBankRoutingNumber {
    bankName: string
    routingNumber: number
    state: string
}

export const AMERICAN_BANK_ROUTING_NUMBERS: AmericanBankRoutingNumber[] = [
    { bankName: "Bank of America", routingNumber: 311093120, state: "VA" },
    { bankName: "Bank of America", routingNumber: 111000258, state: "VA" },
    { bankName: "Bank of America", routingNumber: 111300945, state: "VA" },
    { bankName: "Bank of America", routingNumber: 111901302, state: "VA" },
    { bankName: "Bank of America", routingNumber: 112002080, state: "VA" },
    { bankName: "Bank of America", routingNumber: 71214579,  state: "VA" },
    { bankName: "Bank of America", routingNumber: 112200303, state: "VA" },
    { bankName: "Bank of America", routingNumber: 114000019, state: "VA" },
    { bankName: "Bank of America", routingNumber: 114000653, state: "VA" },
    { bankName: "Bank of America", routingNumber: 114017714, state: "VA" },
    { bankName: "Bank of America", routingNumber: 114900025, state: "VA" },
    { bankName: "Bank of America", routingNumber: 114913164, state: "VA" },
    { bankName: "Bank of America", routingNumber: 72413609, state: "VA" },
    { bankName: "Bank of America", routingNumber: 84301767, state: "VA" },
    { bankName: "Bank of America", routingNumber: 11400178, state: "VA" },
    { bankName: "Bank of America", routingNumber: 81517897, state: "VA" },
    { bankName: "Bank of America", routingNumber: 82900487, state: "VA" },
    { bankName: "Bank of America", routingNumber: 82900429, state: "VA" },
    { bankName: "Bank of America", routingNumber: 81501065, state: "VA" },
    { bankName: "Bank of America", routingNumber: 81500875, state: "VA" },
    { bankName: "Bank of America", routingNumber: 81221497, state: "VA" },
    { bankName: "Bank of America", routingNumber: 81211135, state: "VA" },
    { bankName: "Bank of America", routingNumber: 81200544, state: "VA" },
    { bankName: "Bank of America", routingNumber: 64208194, state: "VA" },
    { bankName: "Bank of America", routingNumber: 64107046, state: "VA" },
    { bankName: "Bank of America", routingNumber: 64101673, state: "VA" },
    { bankName: "Bank of America", routingNumber: 64101385, state: "VA" },
    { bankName: "Bank of America", routingNumber: 64101178, state: "VA" },
    { bankName: "Bank of America", routingNumber: 64100852, state: "VA" },
    { bankName: "Bank of America", routingNumber: 21001318, state: "VA" },
    { bankName: "Bank of the West", routingNumber: 73902494, state: "CA" },
    { bankName: "Bank of the West", routingNumber: 91200961, state: "CA" },
    { bankName: "Bank of the West", routingNumber: 303188111, state: "CA" },
    { bankName: "Bank of the West", routingNumber: 301171081, state: "CA" },
    { bankName: "Bank of the West", routingNumber: 124002735, state: "CA" },
    { bankName: "Bank of the West", routingNumber: 122242843, state: "CA" },
    { bankName: "Bank of the West", routingNumber: 122105647, state: "CA" },
    { bankName: "Bank of the West", routingNumber: 121100782, state: "CA" },
    { bankName: "Bank of the West", routingNumber: 107006813, state: "CA" },
    { bankName: "Bank of the West", routingNumber: 107002147, state: "CA" },
    { bankName: "Bank of the West", routingNumber: 107000152, state: "CA" },
    { bankName: "Bank of the West", routingNumber: 104112409, state: "CA" },
    { bankName: "Bank of the West", routingNumber: 91803290, state: "CA" },
    { bankName: "Bank of the West", routingNumber: 91400680, state: "CA" },
    { bankName: "Bank of the West", routingNumber: 91300036, state: "CA" },
    { bankName: "Bank of the West", routingNumber: 112025342, state: "TX" },
    { bankName: "Bank of the West", routingNumber: 111916724, state: "TX" },
    { bankName: "Bank of the West", routingNumber: 111916724, state: "TX" },
    { bankName: "Wells Fargo Bank", routingNumber: 122242607, state: "CA" },
    { bankName: "Wells Fargo Bank", routingNumber: 62203751, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 111015159, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 102307164, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 67091780, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 67013564, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 67006432, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 64003768, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 63210125, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 63109430, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 63107513, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 63000021, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 111025013, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 62000080, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 61209756, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 61113279, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 61103056, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 61101155, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 61000256, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 61000227, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 61000010, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 11100106, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 121141534, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 256072701, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 255072935, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 254070019, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 253170305, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 122487307, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 122287170, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 122237955, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 122187076, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 121181866, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 56007604, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 121141288, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 113117767, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 113105449, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 113102138, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 113024520, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 113017870, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 111909634, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 111904215, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 111900659, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 31201467, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 53000183, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 52001963, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 52000016, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 51404985, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 51402961, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 51400549, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 51006778, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 51001414, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 51000253, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 31901686, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 53000219, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 31200730, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 31100869, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 31100225, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 31000503, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 31000011, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 26012881, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 21201011, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 21200559, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 21200025, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 21101108, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 53108580, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 55003201, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 54001220, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 54000807, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 53901473, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 53900225, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 53207766, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 53200019, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 53110400, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 53110303, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 53110112, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 56004089, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 53107963, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 53107633, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 53101626, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 53101561, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 53101529, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 53100494, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 53100355, state: "MN" },
    { bankName: "Wells Fargo Bank", routingNumber: 101089292, state: "NC" },
    { bankName: "Wells Fargo Bank", routingNumber: 102189285, state: "NC" },
    { bankName: "Citizens Bank", routingNumber: 62204938, state: "AL" },
    { bankName: "Citizens Bank", routingNumber: 82907736, state: "AR" },
    { bankName: "Citizens Bank", routingNumber: 82907736, state: "AR" },
    { bankName: "Citizens Bank", routingNumber: 73921048, state: "IA" },
    { bankName: "Citizens Bank", routingNumber: 74905225, state: "IN" },
    { bankName: "Citizens Bank", routingNumber: 42104469, state: "KY" },
    { bankName: "Citizens Bank", routingNumber: 83903195, state: "KY" },
    { bankName: "Citizens Bank", routingNumber: 83907722, state: "KY" },
    { bankName: "Citizens Bank", routingNumber: 101217750, state: "MO" },
    { bankName: "Citizens Bank", routingNumber: 101217514, state: "MO" },
    { bankName: "Citizens Bank", routingNumber: 81518524, state: "MO" },
    { bankName: "Citizens Bank", routingNumber: 81504172, state: "MO" },
    { bankName: "Citizens Bank", routingNumber: 81905593, state: "MO" },
    { bankName: "Citizens Bank", routingNumber: 84204929, state: "MS" },
    { bankName: "Citizens Bank", routingNumber: 65302358, state: "MS" },
    { bankName: "Citizens Bank", routingNumber: 65304385, state: "MS" },
    { bankName: "Citizens Bank", routingNumber: 112201218, state: "NM" },
    { bankName: "Citizens Bank", routingNumber: 111308277, state: "NM" },
    { bankName: "Citizens Bank", routingNumber: 123204013, state: "OR" },
    { bankName: "Citizens Bank", routingNumber: 123204013, state: "OR" },
    { bankName: "Citizens Bank", routingNumber: 36076150, state: "RI" },
    { bankName: "Citizens Bank", routingNumber: 231373069, state: "RI" },
    { bankName: "Citizens Bank", routingNumber: 31101143, state: "RI" },
    { bankName: "Citizens Bank", routingNumber: 64107729, state: "TN" },
    { bankName: "Citizens Bank", routingNumber: 64209177, state: "TN" },
    { bankName: "Citizens Bank", routingNumber: 64208893, state: "TN" },
    { bankName: "Citizens Bank", routingNumber: 64207946, state: "TN" },
    { bankName: "Citizens Bank", routingNumber: 64207946, state: "TN" },
    { bankName: "Citizens Bank", routingNumber: 64102601, state: "TN" },
    { bankName: "Citizens Bank", routingNumber: 64102740, state: "TN" },
    { bankName: "Citizens Bank", routingNumber: 64102740, state: "TN" },
    { bankName: "Citizens Bank", routingNumber: 64102740, state: "TN" },
    { bankName: "Citizens Bank", routingNumber: 64000185, state: "TN" },
    { bankName: "Citizens Bank", routingNumber: 64205388, state: "TN" },
    { bankName: "Citizens Bank", routingNumber: 111320006, state: "TX" },
    { bankName: "Citizens Bank", routingNumber: 111922624, state: "TX" },
    { bankName: "Citizens Bank", routingNumber: 113119396, state: "TX" }
]