import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-logout',
    templateUrl: '../svgs/LogOut.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--logout'
    }
})
export class TellerOnlineIconLogout {
}