<div [cdkTrapFocus]="isActive">
    <button mat-fab 
        class="fab-menu__primary-btn"
        [matTooltip]="extended"
        matTooltipPosition="left"
        aria-haspopup="menu"
        (click)="onClick_toggleMenu()">
        <mat-icon [svgIcon]="svgIcon" [ngClass]="{'teller-online-icon' : svgIcon}">{{icon}}</mat-icon>
    </button>

    <div class="fab-menu__actions" *ngIf="isActive" role="menu" [attr.aria-label]="extended">
        <ng-template ngFor let-button [ngForOf]="actionButtons">
            <ng-container [ngSwitch]="button.actionType">
                <div class="fab-menu__action">
                    <span class="fab-menu__actions__text mat-elevation-z2" *ngIf="button.label">{{button.label}}</span>
                    <button mat-mini-fab 
                        *ngSwitchCase="FabMenuActionType.Button"
                        (click)="button.action"
                        [attr.aria-label]="button.label">
                        <mat-icon [svgIcon]="button.svgIcon" [ngClass]="{'teller-online-icon' : button.svgIcon}">{{button.icon}}</mat-icon>
                    </button>
                    <a mat-mini-fab 
                        *ngSwitchCase="FabMenuActionType.Anchor"
                        [routerLink]="button.action" 
                        [attr.aria-label]="button.label">
                        <mat-icon [svgIcon]="button.svgIcon" [ngClass]="{'teller-online-icon' : button.svgIcon}">{{button.icon}}</mat-icon>
                    </a>
                </div>               
            </ng-container>
        </ng-template>
    </div>
</div>