// Angular Files
import { Component } from '@angular/core';

// Payment Integration Files
import { PaymentProcessorProvider } from 'apps/public-portal/src/app/payment-integrations';

// Teller Online Files
import {
    AuthService,
    UserService
} from 'apps/public-portal/src/app/core/services';

// Teller Online Library Files
import { TellerOnlineSiteMetadataService, TellerOnlineAppService } from 'teller-online-libraries/core';

@Component({
    selector: 'app-mobile-menu',
    templateUrl: 'mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss'],
    host: {
        class: 'mobile-menu'
    }
})
export class MobileMenuComponent {
    constructor(
        public authService: AuthService,
        public siteMetadataService: TellerOnlineSiteMetadataService,
        public appService: TellerOnlineAppService,
        public userService: UserService,
        public paymentProvider: PaymentProcessorProvider
    ) { }

    public get canSavePaymentMethod(): boolean {
        return this.paymentProvider.canSavePaymentMethod;
    }

    async logout() {
        await this.authService.signOut("/sign-in");
    }
}
