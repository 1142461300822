<h2 mat-dialog-title>
    Verify your phone number.
</h2>

<mat-dialog-content>
    <p class="phone-verify__desc">
        We've sent you a text message with a code for the phone number attached to your profile 
        <b>{{ data.northAmerica ? (data.phone | usaPhone) : data.phone }}</b>.
    </p>

    <app-code-verification
        class="code-verification--modal"
        (verifyCode)="onVerifyCode_closeModal()"
        [phone]="data.phone"
        [requestType]="requestType"
        [handlesSubmit]="true"
        [codeSent]="data.codeSent"
        [modal]="true">
    </app-code-verification>
</mat-dialog-content>