// Angular Files
import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular Material Files
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { MatDialogModule } from "@angular/material/dialog";
import { MatLegacyProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";

// Payment Integration Files
import {
    BasePaymentProcessorIntegrationsModule
} from 'apps/public-portal/src/app/payment-integrations/base';

// Component Files
import { FisComponent } from "./component";

// Teller Online Library Files
import { TellerOnlineIconsModule } from 'teller-online-libraries/icons';


@NgModule({
    imports: [
        CommonModule,
        TellerOnlineIconsModule,
        BasePaymentProcessorIntegrationsModule,
        MatLegacyFormFieldModule,
        MatLegacyInputModule,
        MatLegacyButtonModule,
        MatLegacyCheckboxModule,
        MatIconModule,
        MatLegacySelectModule,
        MatDialogModule,
        MatLegacyProgressSpinnerModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [FisComponent],
    exports: [FisComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FisModule { }
