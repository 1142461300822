<form (ngSubmit)="onSubmit_validateAndSubmit()">
    <app-echeck *ngIf="paymentMethodData.type == PaymentMethodTypeEnum.ECheck"
        [allowSavePaymentMethod]="allowSavePaymentMethod"
        [formGroup]="paymentDetailsForm"
        [ECHECK_ACCOUNTTYPELIST]="ECHECK_ACCOUNTTYPELIST"
        [ECHECK_ACCOUNTOWNERTYPELIST]="ECHECK_ACCOUNTOWNERTYPELIST"
        [ECHECK_FIELDS]="ECHECK_FIELDS"
        [accountOwnerTypeEnabled]="true"
        [accountTypeEnabled]="true"
        [paymentMethodData]="paymentMethodData">
    </app-echeck>

    <h2>Billing Address</h2>

    <app-billing-info
        [forEdit]="forEdit"
        [billingInfo]="paymentMethodData.billingInfo"
        [formGroup]="paymentDetailsForm">
    </app-billing-info>

    <app-test-trigger
        [shouldDisplay]="true"
        [testTriggerData]="testTriggerData"
        [formGroup]="paymentDetailsForm"
        (testTriggerEvent)="selectTestTrigger($event)">
    </app-test-trigger>

    <app-echeck-disclaimer
        *ngIf="(forEdit ? eCheckDisclaimer : eCheckConfirmationMessage)
            && paymentMethodData.type == PaymentMethodTypeEnum.ECheck"
        [forEdit]="forEdit"
        [eCheckMessage]="forEdit ? eCheckDisclaimer : eCheckConfirmationMessage"
        (eCheckCheckChange)="onCheck_agreeECheckDisclaimer($event)">
    </app-echeck-disclaimer>

    <button
        mat-flat-button
        color="accent"
        type="submit"
        [disabled]="shouldDisableECheckSubmit()">
        {{forEdit ? 'Save' : 'Pay'}}
    </button>
</form>