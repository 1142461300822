export class Country {
    flag: string
    name: string
    dial_code: string
    code: string

    public static GetByName(name: string) : Country {
        return COUNTRIES.find(x => x.name == name);
    }

    public static GetByCode(code: string) : Country {
        return COUNTRIES.find(x => x.code == code);
    }

    static HasRegionList(codeOrName: string) {
        return codeOrName?.toLowerCase() === "united states" ||
               codeOrName?.toLowerCase() === "us" ||
               codeOrName?.toLowerCase() === "canada" ||
               codeOrName?.toLowerCase() === "ca";
    }
}

export const COUNTRIES: Country[] = [{
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/5f/Flag_of_Afghanistan_%28Colored_Emblem%29.svg",
    name: "Afghanistan",
    dial_code: "93",
    code: "AF"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/52/Flag_of_%C3%85land.svg",
    name: "Aland Islands",
    dial_code: "358",
    code: "AX"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/36/Flag_of_Albania.svg",
    name: "Albania",
    dial_code: "355",
    code: "AL"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Algeria.svg",
    name: "Algeria",
    dial_code: "213",
    code: "DZ"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/87/Flag_of_American_Samoa.svg",
    name: "American Samoa",
    dial_code: "1",
    code: "AS"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Andorra.svg",
    name: "Andorra",
    dial_code: "376",
    code: "AD"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Angola.svg",
    name: "Angola",
    dial_code: "244",
    code: "AO"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Anguilla.svg",
    name: "Anguilla",
    dial_code: "1",
    code: "AI"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Antigua_and_Barbuda.svg",
    name: "Antigua and Barbuda",
    dial_code: "1",
    code: "AG"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg",
    name: "Argentina",
    dial_code: "54",
    code: "AR"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/2f/Flag_of_Armenia.svg",
    name: "Armenia",
    dial_code: "374",
    code: "AM"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Aruba.svg",
    name: "Aruba",
    dial_code: "297",
    code: "AW"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_(converted).svg",
    name: "Australia",
    dial_code: "61",
    code: "AU"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg",
    name: "Austria",
    dial_code: "43",
    code: "AT"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Azerbaijan.svg",
    name: "Azerbaijan",
    dial_code: "994",
    code: "AZ"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/93/Flag_of_the_Bahamas.svg",
    name: "Bahamas",
    dial_code: "1",
    code: "BS"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Bahrain.svg",
    name: "Bahrain",
    dial_code: "973",
    code: "BH"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Flag_of_Bangladesh.svg",
    name: "Bangladesh",
    dial_code: "880",
    code: "BD"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/ef/Flag_of_Barbados.svg",
    name: "Barbados",
    dial_code: "1",
    code: "BB"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/85/Flag_of_Belarus.svg",
    name: "Belarus",
    dial_code: "375",
    code: "BY"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Belgium.svg",
    name: "Belgium",
    dial_code: "32",
    code: "BE"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/e7/Flag_of_Belize.svg",
    name: "Belize",
    dial_code: "501",
    code: "BZ"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Benin.svg",
    name: "Benin",
    dial_code: "229",
    code: "BJ"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bermuda.svg",
    name: "Bermuda",
    dial_code: "1",
    code: "BM"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/91/Flag_of_Bhutan.svg",
    name: "Bhutan",
    dial_code: "975",
    code: "BT"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Bolivia_Flag.svg",
    name: "Bolivia, Plurinational State of",
    dial_code: "591",
    code: "BO"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bosnia_and_Herzegovina.svg",
    name: "Bosnia and Herzegovina",
    dial_code: "387",
    code: "BA"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Botswana.svg",
    name: "Botswana",
    dial_code: "267",
    code: "BW"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_Brazil.svg",
    name: "Brazil",
    dial_code: "55",
    code: "BR"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Brunei.svg",
    name: "Brunei Darussalam",
    dial_code: "673",
    code: "BN"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Bulgaria.svg",
    name: "Bulgaria",
    dial_code: "359",
    code: "BG"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Burkina_Faso.svg",
    name: "Burkina Faso",
    dial_code: "226",
    code: "BF"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/50/Flag_of_Burundi.svg",
    name: "Burundi",
    dial_code: "257",
    code: "BI"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Cambodia.svg",
    name: "Cambodia",
    dial_code: "855",
    code: "KH"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Cameroon.svg",
    name: "Cameroon",
    dial_code: "237",
    code: "CM"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Canada_(Pantone).svg",
    name: "Canada",
    dial_code: "1",
    code: "CA"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Cape_Verde.svg",
    name: "Cape Verde",
    dial_code: "238",
    code: "CV"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_the_Cayman_Islands.svg",
    name: "Cayman Islands",
    dial_code: " 345",
    code: "KY"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/6f/Flag_of_the_Central_African_Republic.svg",
    name: "Central African Republic",
    dial_code: "236",
    code: "CF"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/4b/Flag_of_Chad.svg",
    name: "Chad",
    dial_code: "235",
    code: "TD"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/78/Flag_of_Chile.svg",
    name: "Chile",
    dial_code: "56",
    code: "CL"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg",
    name: "China",
    dial_code: "86",
    code: "CN"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/67/Flag_of_Christmas_Island.svg",
    name: "Christmas Island",
    dial_code: "61",
    code: "CX"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Cocos_(Keeling)_Islands.svg",
    name: "Cocos (Keeling) Islands",
    dial_code: "61",
    code: "CC"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Colombia.svg",
    name: "Colombia",
    dial_code: "57",
    code: "CO"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/df/Flag_of_the_Comoros_(3-2).svg",
    name: "Comoros",
    dial_code: "269",
    code: "KM"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_the_Republic_of_the_Congo.svg",
    name: "Congo",
    dial_code: "242",
    code: "CG"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/35/Flag_of_the_Cook_Islands.svg",
    name: "Cook Islands",
    dial_code: "682",
    code: "CK"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Costa_Rica_(state).svg",
    name: "Costa Rica",
    dial_code: "506",
    code: "CR"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Côte_d%27Ivoire.svg",
    name: "Cote d'Ivoire",
    dial_code: "225",
    code: "CI"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg",
    name: "Croatia",
    dial_code: "385",
    code: "HR"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bd/Flag_of_Cuba.svg",
    name: "Cuba",
    dial_code: "53",
    code: "CU"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Cyprus.svg",
    name: "Cyprus",
    dial_code: "357",
    code: "CY"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_Czech_Republic.svg",
    name: "Czech Republic",
    dial_code: "420",
    code: "CZ"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg",
    name: "Denmark",
    dial_code: "45",
    code: "DK"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_Djibouti.svg",
    name: "Djibouti",
    dial_code: "253",
    code: "DJ"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/c4/Flag_of_Dominica.svg",
    name: "Dominica",
    dial_code: "1",
    code: "DM"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_the_Dominican_Republic.svg",
    name: "Dominican Republic",
    dial_code: "1",
    code: "DO"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/e8/Flag_of_Ecuador.svg",
    name: "Ecuador",
    dial_code: "593",
    code: "EC"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Egypt.svg",
    name: "Egypt",
    dial_code: "20",
    code: "EG"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_El_Salvador.svg",
    name: "El Salvador",
    dial_code: "503",
    code: "SV"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Equatorial_Guinea.svg",
    name: "Equatorial Guinea",
    dial_code: "240",
    code: "GQ"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_Eritrea.svg",
    name: "Eritrea",
    dial_code: "291",
    code: "ER"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/8f/Flag_of_Estonia.svg",
    name: "Estonia",
    dial_code: "372",
    code: "EE"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/71/Flag_of_Ethiopia.svg",
    name: "Ethiopia",
    dial_code: "251",
    code: "ET"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_Falkland_Islands.svg",
    name: "Falkland Islands (Malvinas)",
    dial_code: "500",
    code: "FK"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/3c/Flag_of_the_Faroe_Islands.svg",
    name: "Faroe Islands",
    dial_code: "298",
    code: "FO"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Fiji.svg",
    name: "Fiji",
    dial_code: "679",
    code: "FJ"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Finland.svg",
    name: "Finland",
    dial_code: "358",
    code: "FI"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg",
    name: "France",
    dial_code: "33",
    code: "FR"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_France_%28Pantone%29.svg",
    name: "French Guiana",
    dial_code: "594",
    code: "GF"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/db/Flag_of_French_Polynesia.svg",
    name: "French Polynesia",
    dial_code: "689",
    code: "PF"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/04/Flag_of_Gabon.svg",
    name: "Gabon",
    dial_code: "241",
    code: "GA"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_The_Gambia.svg",
    name: "Gambia",
    dial_code: "220",
    code: "GM"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Georgia.svg",
    name: "Georgia",
    dial_code: "995",
    code: "GE"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Germany.svg",
    name: "Germany",
    dial_code: "49",
    code: "DE"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg",
    name: "Ghana",
    dial_code: "233",
    code: "GH"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/02/Flag_of_Gibraltar.svg",
    name: "Gibraltar",
    dial_code: "350",
    code: "GI"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Greece.svg",
    name: "Greece",
    dial_code: "30",
    code: "GR"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_Greenland.svg",
    name: "Greenland",
    dial_code: "299",
    code: "GL"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Grenada.svg",
    name: "Grenada",
    dial_code: "1",
    code: "GD"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_France_%287x10%29.svg",
    name: "Guadeloupe",
    dial_code: "590",
    code: "GP"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/07/Flag_of_Guam.svg",
    name: "Guam",
    dial_code: "1",
    code: "GU"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/ec/Flag_of_Guatemala.svg",
    name: "Guatemala",
    dial_code: "502",
    code: "GT"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Guernsey.svg",
    name: "Guernsey",
    dial_code: "44",
    code: "GG"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_Guinea.svg",
    name: "Guinea",
    dial_code: "224",
    code: "GN"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Guinea-Bissau.svg",
    name: "Guinea-Bissau",
    dial_code: "245",
    code: "GW"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_Guyana.svg",
    name: "Guyana",
    dial_code: "595",
    code: "GY"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Haiti.svg",
    name: "Haiti",
    dial_code: "509",
    code: "HT"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_the_Vatican_City.svg",
    name: "Holy See",
    dial_code: "379",
    code: "VA"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Hong_Kong.svg",
    name: "Hong Kong",
    dial_code: "852",
    code: "HK"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Hungary.svg",
    name: "Hungary",
    dial_code: "36",
    code: "HU"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Iceland.svg",
    name: "Iceland",
    dial_code: "354",
    code: "IS"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg",
    name: "India",
    dial_code: "91",
    code: "IN"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg",
    name: "Indonesia",
    dial_code: "62",
    code: "ID"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/ca/Flag_of_Iran.svg",
    name: "Iran",
    dial_code: "98",
    code: "IR"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Iraq.svg",
    name: "Iraq",
    dial_code: "964",
    code: "IQ"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Republic_of_Ireland_Flag.svg",
    name: "Ireland",
    dial_code: "353",
    code: "IE"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_the_Isle_of_Man.svg",
    name: "Isle of Man",
    dial_code: "44",
    code: "IM"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg",
    name: "Israel",
    dial_code: "972",
    code: "IL"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/03/Flag_of_Italy.svg",
    name: "Italy",
    dial_code: "39",
    code: "IT"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Jamaica.svg",
    name: "Jamaica",
    dial_code: "1",
    code: "JM"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Japan%28bordered%29.svg",
    name: "Japan",
    dial_code: "81",
    code: "JP"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/1c/Flag_of_Jersey.svg",
    name: "Jersey",
    dial_code: "44",
    code: "JE"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/c0/Flag_of_Jordan.svg",
    name: "Jordan",
    dial_code: "962",
    code: "JO"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kazakhstan.svg",
    name: "Kazakhstan",
    dial_code: "7 7",
    code: "KZ"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Kenya.svg",
    name: "Kenya",
    dial_code: "254",
    code: "KE"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kiribati.svg",
    name: "Kiribati",
    dial_code: "686",
    code: "KI"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_the_Democratic_Republic_of_the_Congo_(3-2).svg",
    name: "Korea",
    dial_code: "850",
    code: "KP"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_South_Korea.svg",
    name: "South Korea",
    dial_code: "82",
    code: "KR"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Kuwait.svg",
    name: "Kuwait",
    dial_code: "965",
    code: "KW"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/c7/Flag_of_Kyrgyzstan.svg",
    name: "Kyrgyzstan",
    dial_code: "996",
    code: "KG"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Laos.svg",
    name: "Laos",
    dial_code: "856",
    code: "LA"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Latvia.svg",
    name: "Latvia",
    dial_code: "371",
    code: "LV"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/59/Flag_of_Lebanon.svg",
    name: "Lebanon",
    dial_code: "961",
    code: "LB"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/4a/Flag_of_Lesotho.svg",
    name: "Lesotho",
    dial_code: "266",
    code: "LS"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/b8/Flag_of_Liberia.svg",
    name: "Liberia",
    dial_code: "231",
    code: "LR"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_Libya.svg",
    name: "Libyan",
    dial_code: "218",
    code: "LY"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/47/Flag_of_Liechtenstein.svg",
    name: "Liechtenstein",
    dial_code: "423",
    code: "LI"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Lithuania.svg",
    name: "Lithuania",
    dial_code: "370",
    code: "LT"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/da/Flag_of_Luxembourg.svg",
    name: "Luxembourg",
    dial_code: "352",
    code: "LU"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/63/Flag_of_Macau.svg",
    name: "Macao",
    dial_code: "853",
    code: "MO"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_North_Macedonia.svg",
    name: "Macedonia",
    dial_code: "389",
    code: "MK"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Madagascar.svg",
    name: "Madagascar",
    dial_code: "261",
    code: "MG"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d1/Flag_of_Malawi.svg",
    name: "Malawi",
    dial_code: "265",
    code: "MW"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_Malaysia.svg",
    name: "Malaysia",
    dial_code: "60",
    code: "MY"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Maldives.svg",
    name: "Maldives",
    dial_code: "960",
    code: "MV"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_Mali.svg",
    name: "Mali",
    dial_code: "223",
    code: "ML"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Malta.svg",
    name: "Malta",
    dial_code: "356",
    code: "MT"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/2e/Flag_of_the_Marshall_Islands.svg",
    name: "Marshall Islands",
    dial_code: "692",
    code: "MH"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_the_Territorial_Collectivity_of_Martinique.svg",
    name: "Martinique",
    dial_code: "596",
    code: "MQ"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/43/Flag_of_Mauritania.svg",
    name: "Mauritania",
    dial_code: "222",
    code: "MR"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Mauritius.svg",
    name: "Mauritius",
    dial_code: "230",
    code: "MU"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Flag_of_France.svg",
    name: "Mayotte",
    dial_code: "262",
    code: "YT"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.svg",
    name: "Mexico",
    dial_code: "52",
    code: "MX"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/e4/Flag_of_the_Federated_States_of_Micronesia.svg",
    name: "Micronesia",
    dial_code: "691",
    code: "FM"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Moldova.svg",
    name: "Moldova",
    dial_code: "373",
    code: "MD"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/ea/Flag_of_Monaco.svg",
    name: "Monaco",
    dial_code: "377",
    code: "MC"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Mongolia.svg",
    name: "Mongolia",
    dial_code: "976",
    code: "MN"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Montenegro.svg",
    name: "Montenegro",
    dial_code: "382",
    code: "ME"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Montserrat.svg",
    name: "Montserrat",
    dial_code: "1",
    code: "MS"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Morocco.svg",
    name: "Morocco",
    dial_code: "212",
    code: "MA"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Mozambique.svg",
    name: "Mozambique",
    dial_code: "258",
    code: "MZ"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/8c/Flag_of_Myanmar.svg",
    name: "Myanmar",
    dial_code: "95",
    code: "MM"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Namibia.svg",
    name: "Namibia",
    dial_code: "264",
    code: "NA"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/30/Flag_of_Nauru.svg",
    name: "Nauru",
    dial_code: "674",
    code: "NR"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9b/Flag_of_Nepal.svg",
    name: "Nepal",
    dial_code: "977",
    code: "NP"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg",
    name: "Netherlands",
    dial_code: "31",
    code: "NL"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/eb/Flag_of_the_Netherlands_Antilles_(1959%E2%80%931986).svg",
    name: "Netherlands Antilles",
    dial_code: "599",
    code: "AN"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_FLNKS.svg",
    name: "New Caledonia",
    dial_code: "687",
    code: "NC"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/3e/Flag_of_New_Zealand.svg",
    name: "New Zealand",
    dial_code: "64",
    code: "NZ"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Nicaragua.svg",
    name: "Nicaragua",
    dial_code: "505",
    code: "NI"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Flag_of_Niger.svg",
    name: "Niger",
    dial_code: "227",
    code: "NE"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_Nigeria.svg",
    name: "Nigeria",
    dial_code: "234",
    code: "NG"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Niue.svg",
    name: "Niue",
    dial_code: "683",
    code: "NU"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Norfolk_Island.svg",
    name: "Norfolk Island",
    dial_code: "672",
    code: "NF"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Flag_of_the_Northern_Mariana_Islands.svg",
    name: "Northern Mariana Islands",
    dial_code: "1",
    code: "MP"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg",
    name: "Norway",
    dial_code: "47",
    code: "NO"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Oman.svg",
    name: "Oman",
    dial_code: "968",
    code: "OM"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg",
    name: "Pakistan",
    dial_code: "92",
    code: "PK"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Palau.svg",
    name: "Palau",
    dial_code: "680",
    code: "PW"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/f4/Palestine_Flag.svg",
    name: "Palestinian Territory",
    dial_code: "970",
    code: "PS"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/a/ab/Flag_of_Panama.svg",
    name: "Panama",
    dial_code: "507",
    code: "PA"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/e3/Flag_of_Papua_New_Guinea.svg",
    name: "Papua New Guinea",
    dial_code: "675",
    code: "PG"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Paraguay.svg",
    name: "Paraguay",
    dial_code: "595",
    code: "PY"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Peru.svg",
    name: "Peru",
    dial_code: "51",
    code: "PE"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_the_Philippines.svg",
    name: "Philippines",
    dial_code: "63",
    code: "PH"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_the_Pitcairn_Islands.svg",
    name: "Pitcairn",
    dial_code: "872",
    code: "PN"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/12/Flag_of_Poland.svg",
    name: "Poland",
    dial_code: "48",
    code: "PL"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg",
    name: "Portugal",
    dial_code: "351",
    code: "PT"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/28/Flag_of_Puerto_Rico.svg",
    name: "Puerto Rico",
    dial_code: "1",
    code: "PR"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Qatar.svg",
    name: "Qatar",
    dial_code: "974",
    code: "QA"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Romania.svg",
    name: "Romania",
    dial_code: "40",
    code: "RO"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Russia.svg",
    name: "Russia",
    dial_code: "7",
    code: "RU"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Rwanda.svg",
    name: "Rwanda",
    dial_code: "250",
    code: "RW"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/8e/Proposed_flag_of_R%C3%A9union_%28VAR%29.svg",
    name: "Reunion",
    dial_code: "262",
    code: "RE"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/03/Saint-Barthelémy_Icône.svg",
    name: "Saint Barthelemy",
    dial_code: "590",
    code: "BL"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Saint_Helena.svg",
    name: "Saint Helena",
    dial_code: "290",
    code: "SH"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Saint_Kitts_and_Nevis.svg",
    name: "Saint Kitts and Nevis",
    dial_code: "1",
    code: "KN"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Saint_Lucia.svg",
    name: "Saint Lucia",
    dial_code: "1",
    code: "LC"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Saint-Martin_%28fictional%29.svg",
    name: "Saint Martin",
    dial_code: "590",
    code: "MF"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_Saint-Pierre_and_Miquelon.svg",
    name: "Saint Pierre and Miquelon",
    dial_code: "508",
    code: "PM"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/6d/Flag_of_Saint_Vincent_and_the_Grenadines.svg",
    name: "Saint Vincent and the Grenadines",
    dial_code: "1",
    code: "VC"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Samoa.svg",
    name: "Samoa",
    dial_code: "685",
    code: "WS"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/b1/Flag_of_San_Marino.svg",
    name: "San Marino",
    dial_code: "378",
    code: "SM"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_S%C3%A3o_Tom%C3%A9_and_Pr%C3%ADncipe.svg",
    name: "Sao Tome and Principe",
    dial_code: "239",
    code: "ST"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg",
    name: "Saudi Arabia",
    dial_code: "966",
    code: "SA"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fd/Flag_of_Senegal.svg",
    name: "Senegal",
    dial_code: "221",
    code: "SN"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/ff/Flag_of_Serbia.svg",
    name: "Serbia",
    dial_code: "381",
    code: "RS"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Seychelles.svg",
    name: "Seychelles",
    dial_code: "248",
    code: "SC"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Sierra_Leone.svg",
    name: "Sierra Leone",
    dial_code: "232",
    code: "SL"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Singapore.svg",
    name: "Singapore",
    dial_code: "65",
    code: "SG"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/e6/Flag_of_Slovakia.svg",
    name: "Slovakia",
    dial_code: "421",
    code: "SK"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/f0/Flag_of_Slovenia.svg",
    name: "Slovenia",
    dial_code: "386",
    code: "SI"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Solomon_Islands.svg",
    name: "Solomon Islands",
    dial_code: "677",
    code: "SB"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_Somalia.svg",
    name: "Somalia",
    dial_code: "252",
    code: "SO"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/a/af/Flag_of_South_Africa.svg",
    name: "South Africa",
    dial_code: "27",
    code: "ZA"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_South_Georgia_and_the_South_Sandwich_Islands.svg",
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "500",
    code: "GS"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Spain.svg",
    name: "Spain",
    dial_code: "34",
    code: "ES"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg",
    name: "Sri Lanka",
    dial_code: "94",
    code: "LK"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Sudan.svg",
    name: "Sudan",
    dial_code: "249",
    code: "SD"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/60/Flag_of_Suriname.svg",
    name: "Suriname",
    dial_code: "597",
    code: "SR"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg",
    name: "Svalbard and Jan Mayen",
    dial_code: "47",
    code: "SJ"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fb/Flag_of_Eswatini.svg",
    name: "Swaziland",
    dial_code: "268",
    code: "SZ"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Sweden.svg",
    name: "Sweden",
    dial_code: "46",
    code: "SE"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Switzerland.svg",
    name: "Switzerland",
    dial_code: "41",
    code: "CH"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/5/53/Flag_of_Syria.svg",
    name: "Syrian Arab Republic",
    dial_code: "963",
    code: "SY"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/72/Flag_of_the_Republic_of_China.svg",
    name: "Taiwan",
    dial_code: "886",
    code: "TW"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Tajikistan.svg",
    name: "Tajikistan",
    dial_code: "992",
    code: "TJ"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tanzania.svg",
    name: "Tanzania, United Republic of Tanzania",
    dial_code: "255",
    code: "TZ"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_Thailand.svg",
    name: "Thailand",
    dial_code: "66",
    code: "TH"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/26/Flag_of_East_Timor.svg",
    name: "Timor-Leste",
    dial_code: "670",
    code: "TL"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/68/Flag_of_Togo.svg",
    name: "Togo",
    dial_code: "228",
    code: "TG"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/8e/Flag_of_Tokelau.svg",
    name: "Tokelau",
    dial_code: "690",
    code: "TK"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Tonga.svg",
    name: "Tonga",
    dial_code: "676",
    code: "TO"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Trinidad_and_Tobago.svg",
    name: "Trinidad and Tobago",
    dial_code: "1",
    code: "TT"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Tunisia.svg",
    name: "Tunisia",
    dial_code: "216",
    code: "TN"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg",
    name: "Turkey",
    dial_code: "90",
    code: "TR"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Turkmenistan.svg",
    name: "Turkmenistan",
    dial_code: "993",
    code: "TM"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_the_Turks_and_Caicos_Islands.svg",
    name: "Turks and Caicos Islands",
    dial_code: "1",
    code: "TC"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tuvalu.svg",
    name: "Tuvalu",
    dial_code: "688",
    code: "TV"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Flag_of_Uganda.svg",
    name: "Uganda",
    dial_code: "256",
    code: "UG"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Ukraine.svg",
    name: "Ukraine",
    dial_code: "380",
    code: "UA"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg",
    name: "United Arab Emirates",
    dial_code: "971",
    code: "AE"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg",
    name: "United Kingdom",
    dial_code: "44",
    code: "GB"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg",
    name: "United States",
    dial_code: "1",
    code: "US"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Uruguay.svg",
    name: "Uruguay",
    dial_code: "598",
    code: "UY"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Uzbekistan.svg",
    name: "Uzbekistan",
    dial_code: "998",
    code: "UZ"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/6e/Flag_of_Vanuatu_(official).svg",
    name: "Vanuatu",
    dial_code: "678",
    code: "VU"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/7/7b/Flag_of_Venezuela_(state).svg",
    name: "Venezuela",
    dial_code: "58",
    code: "VE"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Vietnam.svg",
    name: "Vietnam",
    dial_code: "84",
    code: "VN"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/4/42/Flag_of_the_British_Virgin_Islands.svg",
    name: "Virgin Islands, British",
    dial_code: "1",
    code: "VG"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/f/f8/Flag_of_the_United_States_Virgin_Islands.svg",
    name: "Virgin Islands, U.S.",
    dial_code: "1",
    code: "VI"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/d/d2/Flag_of_Wallis_and_Futuna.svg",
    name: "Wallis and Futuna",
    dial_code: "681",
    code: "WF"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Yemen.svg",
    name: "Yemen",
    dial_code: "967",
    code: "YE"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/0/06/Flag_of_Zambia.svg",
    name: "Zambia",
    dial_code: "260",
    code: "ZM"
  }, {
    flag: "https://upload.wikimedia.org/wikipedia/commons/6/6a/Flag_of_Zimbabwe.svg",
    name: "Zimbabwe",
    dial_code: "263",
    code: "ZW"
  }];
