<aside class="cart__panel" id="aria_cart" aria-labelledby="aria_cart_heading">
    <header class="sidebar-header">
        <mat-toolbar>
            <mat-icon svgIcon="cart" class="teller-online-icon"></mat-icon>
            <h2 id="aria_cart_heading" tabindex="-1" cdkFocusInitial>Cart</h2>
            <button class="close-button" type="button" mat-icon-button (click)="onClick_toggleCart()" aria-label="close">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar>
    </header>

    <div class="cart__details__partition">
        <ng-container *ngFor="let partition of (cartService.cart$ | async).partitions | keyvalue; let i=index;">
            <h3 class="cart__details__partition__title" *ngIf="hasMultiplePartitions()">
                {{ getCartPartitionHeading(partition.value) }}
            </h3>
            <mat-list class="cart__details cart__details--items" *ngIf="partition.value.length > 0">
                <mat-list-item class="cart__detail cart__item" *ngFor="let item of partition.value">
                    <button type="button"
                        *ngIf="!inboundRedirectService.isInboundRedirectUIContext"
                        class="delete-button"
                        mat-icon-button
                        mat-list-icon
                        (click)="onClick_removeFromCart(item)"
                        [attr.aria-label]="'Remove ' + item.title + ' from cart'">
                        <mat-icon svgIcon="remove" class="teller-online-icon"></mat-icon>
                    </button>
                    <div mat-line>
                        <div class="cart__item__label">
                            <a *ngIf="!inboundRedirectService.isInboundRedirectUIContext"
                               [routerLink]="'cart/'+item.itemGuid"
                               class="cart__item__link"
                               [matTooltip]="item.title">{{item.title}}
                            </a>
                            <span [matTooltip]="item.title"
                                  *ngIf="inboundRedirectService.isInboundRedirectUIContext">{{item.title}}</span>
                        </div>

                        <button mat-icon-button *ngIf="item.warning && item.warning.requiresAction" type="button" (click)="onClick_displayWarning(item)" [attr.aria-label]="'View warning for ' + item.title">
                            <mat-icon svgIcon="attention" class="teller-online-icon"></mat-icon>
                        </button>

                        <span class="cart__item__price">{{item.amountInCart | currency}}</span>
                    </div>
                </mat-list-item>
            </mat-list>
            <mat-list class="cart__details cart__details--summary" *ngIf="cartDetails.itemCount > 0">
                <mat-divider class="cart__details__divider--solid"></mat-divider>
                <mat-list-item class="cart__detail cart__detail--subtotal">
                    <div mat-line>
                        <span>Subtotal</span>

                        <span>{{(cartService.cart$ | async).checkoutEnabled ? (cartDetails.subtotal?.[partition.key] | currency) : 'TBD'}}</span>
                    </div>
                </mat-list-item>

                <mat-list-item class="cart__detail cart__detail--convenience-fee" *ngIf="cartDetails.convenienceFees?.[partition.key] || convenienceFees?.[partition.key]?.note">
                    <div mat-line aria-live="polite">
                        <span>
                            <span class="convenience-fee-label">
                                {{ convenienceFees?.[partition.key]?.label ?? "Convenience Fee" }}
                                {{cartDetails.convenienceFees?.[partition.key]
                                    ? (convenienceFees?.[partition.key]?.format == 'percent'
                                        ? (convenienceFees?.[partition.key]?.rate | percent : '0.1-2')
                                        : '')
                                    : ''}}
                            </span>

                            <button mat-icon-button type="button" (click)="onClick_showConvenienceFeeNote(partition.key)" *ngIf="convenienceFees?.[partition.key]?.note" [attr.aria-label]="'Information about convenience fees for ' + getCartPartitionHeading(partition.value)" aria-live="off">
                                <mat-icon svgIcon="info"></mat-icon>
                            </button>
                        </span>

                        <span *ngIf="cartDetails.convenienceFees && !calculatingFee">
                            {{cartDetails.convenienceFees?.[partition.key] | currency}}
                        </span>

                        <mat-spinner *ngIf="calculatingFee" diameter="24" color="accent"></mat-spinner>
                    </div>
                </mat-list-item>

                <mat-list-item class="cart__detail cart__detail--total">
                    <div mat-line>
                        <span>Total</span>

                        <span *ngIf="!calculatingFee">
                            {{cartDetails.total?.[partition.key] && (cartService.cart$ | async).checkoutEnabled ? (cartDetails.total?.[partition.key] | currency) : 'TBD'}}
                        </span>
                        <mat-spinner *ngIf="calculatingFee" diameter="24" color="accent"></mat-spinner>
                    </div>
                </mat-list-item>
            </mat-list>

            <mat-divider class="cart__details__divider--dashed" *ngIf="i < getPartitionsLength() - 1" ></mat-divider>
        </ng-container>
    </div>

    <div class="cart__disclaimer" *ngIf="cartDetails.itemCount > 0 && disclaimer" [ngClass]="{
        'cart__disclaimer--checkout-page': paymentPage
    }">
        <span class="cart__disclaimer__preview" ellipsis [ellipsis-content]="shortDisclaimer" ellipsis-word-boundaries></span>
        <button mat-button type="button" color="accent" (click)="onClick_showDisclaimer()" aria-controls="aria_disclaimer" aria-haspopup="dialog">
            <mat-icon svgIcon="info" class="teller-online-icon"></mat-icon>
            <span>View Full Disclaimer</span>
        </button>
    </div>

    <div class="cart__payment-details" *ngIf="cartDetails.itemCount > 0 && cartDetails.cartStatus != 'Processing' && !inboundRedirectService.isInboundRedirectUIContext">
        <h4 *ngIf="siteMetadataService.authenticationEnabled && !authService.isSignedIn && paymentRedirect">Checkout as Guest</h4>

        <form [attr.role]="!authService.isSignedIn && paymentRedirect ? 'form' : 'generic'"
            *ngIf="!paymentPage"
            #guestEmailForm="ngForm"
            (ngSubmit)="onSubmit_proceedToPayment(guestEmailForm)">
            <mat-form-field appearance="standard" *ngIf="!authService.isSignedIn && paymentRedirect" class="cart__payment-details__email">
                <mat-label>Email</mat-label>
                <input matInput
                    [(ngModel)]="guestDetails.email"
                    autocomplete="email"
                    type="email"
                    name="Email"
                    #emailModel="ngModel"
                    required
                    [pattern]="validationService.emailRegex"
                    maxlength="256">
                <mat-error *ngIf="emailModel.invalid">{{validationService.getFieldErrorMessage(emailModel.name, emailModel, validationService.fieldErrors[emailModel.name])}}</mat-error>
            </mat-form-field>

            <button mat-flat-button color="accent"
                type="submit"
                class="cart__payment-details__submit-btn"
                [disabled]="!(cartService.cart$ | async).checkoutEnabled || !paymentProvider.validConfig">
                Checkout
            </button>
        </form>

        <p *ngIf="siteMetadataService.authenticationEnabled && !authService.isSignedIn">Do you have an account? <a routerLink="/sign-in">Sign in</a> to save time.</p>
    </div>

    <div class="cart__payment-details cart__payment-details--processing" *ngIf="cartDetails.itemCount > 0 && cartDetails.cartStatus == 'Processing' && !paymentPage && !inboundRedirectService.isInboundRedirectUIContext">
        <p class="cart__payment-details__disclaimer" id="aria_processing_disclaimer">It looks like you started a transaction with our payment partner.<br>
            To continue, click checkout to be taken back to pay.<br>
            To edit your cart instead, click unlock.</p>

        <button type="button" mat-flat-button
            class="cart__payment-details__submit-btn cart__payment-details__submit-btn--unlock"
            aria-describedby="aria_processing_disclaimer"
            (click)="onClick_setCartOpen()">
            <mat-icon svgIcon="locked" class="teller-online-icon"></mat-icon>
            <span>Unlock</span>
        </button>

        <button type="button" mat-flat-button color="accent"
            class="cart__payment-details__submit-btn"
            aria-describedby="aria_processing_disclaimer"
            (click)="onClick_proceedToPayment()"
            [disabled]="!(cartService.cart$ | async).checkoutEnabled">
            <span>Checkout</span>
            <mat-icon svgIcon="right-arrow" class="teller-online-icon"></mat-icon>
        </button>
    </div>

    <div class="cart__empty-details" *ngIf="cartDetails.itemCount == 0">
        <teller-online-icon-cart-empty></teller-online-icon-cart-empty>

        <p>
            Your cart is empty. To pay for an item, search for it from the home page and add it to your cart.
        </p>

        <a mat-flat-button color="accent" routerLink="/">Home</a>
    </div>
</aside>
