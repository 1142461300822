import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-attention',
    templateUrl: '../svgs/Attention.svg',
    styles: [
        ':host.teller-online-icon.teller-online-icon--attention .cutout { fill: #fff; }',
    ],
    host: {
        class: 'teller-online-icon teller-online-icon--attention'
    }
})
export class TellerOnlineIconAttention {
}