// Angular Files
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnDestroy, NgModule, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';

// Angular Material Files
import { MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { MatLegacyMenuModule, MatLegacyMenuTrigger } from '@angular/material/legacy-menu';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialog, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';

// Other External Files
import { Subscription } from 'rxjs';

// Teller Online Files
import { ItemPaymentOptionDto } from 'apps/public-portal/src/app/core/api/PublicPortalApiClients';
import { ItemService, ItemDetailModel, SearchResult, ItemModel, ItemDetailsModelWithBillUrl } from 'apps/public-portal/src/app/core/services';
import { SelectPaymentComponent } from 'apps/public-portal/src/app/shared/components/select-payment/select-payment.component';
import { SelectPaymentModule } from 'apps/public-portal/src/app/shared/components/select-payment/select-payment.module';
import { ItemDetailTypeEnum, PopupDataModel } from 'apps/public-portal/src/app/core/services';

// Teller Online Library files
import { TellerOnlineIconsModule } from 'teller-online-libraries/icons';
import { API_BASE_URL, TellerOnlineAppService, TellerOnlineSiteMetadataService } from 'teller-online-libraries/core';
import { ColumnContentAlignment, TellerOnlineColumnMetadata, TellerOnlineDialogAction, TellerOnlineDialogComponent, TellerOnlineDialogOptions, TellerOnlineDialogTable, TellerOnlineMessageService, TellerOnlineWindowService } from 'teller-online-libraries/shared';
import { FlagTypeEnumDto } from 'libraries/core/src/lib/api/CoreWebApi';


@Component({
    selector: 'app-search-result',
    templateUrl: './search-result.component.html',
    styleUrls: ['./search-result.component.scss'],
    host: {
        class: 'search-result'
    },
    animations: [
        trigger('detailExpand', [
          state('collapsed', style({height: '0px', minHeight: '0'})),
          state('expanded', style({height: '*'})),
          transition('expanded <=> collapsed', animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class SearchResultComponent implements OnInit, OnDestroy {

    @Input() item: SearchResult;
    @Input() conflictingOptions = null;
    @Input() public paymentDisabled: boolean = false;
    @Input() public paymentDisabledMessage: string = '';

    @Output() addToCart = new EventEmitter<any>();
    @Output() updateCart = new EventEmitter<any>();
    @Output() removeFromCart = new EventEmitter<any>();
    @Output() loadMore = new EventEmitter<any>();

    @ViewChild(MatLegacyMenuTrigger) trigger: MatLegacyMenuTrigger; // For programmatic control of mat menu for SelectPaymentOptionComponent

    public ItemDetailTypeEnum = ItemDetailTypeEnum;

    public selectPaymentMenuOpen: boolean = false;
    public itemIsPayable: boolean;
    public collapsed: boolean = false;
    public FlagTypeEnumDto = FlagTypeEnumDto;
    public standardDetails: ItemDetailModel[];
    public actionableDetails: ItemDetailsModelWithBillUrl[];
    private _selectPaymentSheet: MatBottomSheetRef<SelectPaymentComponent> | null = null;

    // Subscriptions
    private _breakpointSubscription: Subscription;

    constructor(
        @Inject(API_BASE_URL) private baseUrl,
        private bottomSheet: MatBottomSheet,
        private windowService: TellerOnlineWindowService,
        private itemService: ItemService,
        private messageService: TellerOnlineMessageService,
        public appService: TellerOnlineAppService,
        public siteMetadata: TellerOnlineSiteMetadataService,
        public dialog: MatLegacyDialog
    ) { }

    ngOnInit(): void {
        // If the item has any negative payment options, it is not payable.
        // Note: If the item has no payment options it is not payable.
        this.itemIsPayable = true;
        if (this.item.paymentOptions?.some(p => (p.amount == null && p.maxAmount == 0) || p.amount == 0)) {
            this.itemIsPayable = false;
        }
        if (!this.item.paymentOptions?.every(p => p.amount >= 0 || p.maxAmount >= 0)){
            this.paymentDisabled = true;
            this.paymentDisabledMessage = "Negative amounts cannot be paid online";
        }

        this._breakpointSubscription = this.appService.breakpoints$.subscribe(() => {
            if (this._isSelectPaymentOpen(!this.appService.isNarrow)) {
                //open the new panel
                this.openSelectPayment();
                // then close the old panel
                this._closeSelectPayment(!this.appService.isNarrow);
            }
        });

        this.standardDetails = this.item.details?.filter(detail => detail.type == ItemDetailTypeEnum.Default);
        this.actionableDetails = this.item.details?.filter(detail => detail.type != ItemDetailTypeEnum.Default).map(x => {
            let url = undefined;
            try {
                const download = JSON.parse(x.value);

                if (this.item.page?.businessSystemNo && download.downloadKey) {
                    url = this.windowService.location.origin + "/api/public-portal/file-download/{BusinessSystemNo}/{DownloadIdentifier}";
                    url = url
                        .replace("{BusinessSystemNo}", encodeURIComponent("" + this.item.page?.businessSystemNo))
                        .replace("{DownloadIdentifier}", encodeURIComponent("" + download.downloadKey));
                }
            } catch { }

            return new ItemDetailsModelWithBillUrl({
                ...x,
                billUrl: url
            })
        });
    }

    ngOnDestroy() {
        if(this._breakpointSubscription) this._breakpointSubscription.unsubscribe();
    }

    on_addToCart(e) {
        this.addToCart.emit(e);
    }

    on_updateCartItem(e) {
        this.updateCart.emit(e);
    }

    onClick_removeFromCart(e) {
        this.removeFromCart.emit(e);
    }

    onClick_collapse() {
        this.collapsed = !this.collapsed;
    }

    async onClick_popup(popupJson: string) {
        let dialogData: TellerOnlineDialogOptions = new TellerOnlineDialogOptions();
        let popupResponse: PopupDataModel;
        let popup = JSON.parse(popupJson);

        if (popup.inPopupFormat){
            popupResponse = new PopupDataModel(JSON.parse(popup.popupData));
        } else {
            this.appService.triggerPageLoading();
            popupResponse = await this.itemService.dataPopup(this.item.page?.businessSystemNo, popup.popupKey);
            this.appService.finishPageLoading();
        }

        dialogData.title = popupResponse.header;
        dialogData.tables = popupResponse.popupDataTables.map<TellerOnlineDialogTable>(t => ({
            header: t.header,
            rows: t.rows ?? [],
            footer: t.footer,
            hasColumnHeaderRow: t.columnMetadata.some(x => !!x.title),
            columnMetadata: t.columnMetadata.map<TellerOnlineColumnMetadata>(m => ({
                title: m.title,
                contentAlignment: ColumnContentAlignment[m.contentAlignment]
            })) ?? []
        }));
        dialogData.items = popupResponse.details;
        dialogData.content = popupResponse.description;

        dialogData.actions = [
            new TellerOnlineDialogAction({
                text: "Close",
                close: true
            }),
            new TellerOnlineDialogAction({
                text: "Print",
                click: () => {
                    this.windowService.print()
                }
            })
        ];

        // TODO : SCCE-57 Make this a full-page-modal for mobile view
        this.dialog.open(TellerOnlineDialogComponent, {
            maxWidth: "100%",
            data: dialogData,
            panelClass: "dialog--printable"
        });
    }

    public getPopupSvg(detailJson: string): string {
        return JSON.parse(detailJson)?.svgIcon;
    }

    public viewFileError() {
        this.messageService.notification("Download Unavailable", "error");
    }

    public checkPaymentOptionNeedsPrompt(paymentOptions: ItemPaymentOptionDto[]): boolean {
        let status = true;

        if (paymentOptions?.length === 1) {
            status = paymentOptions[0].isCustomAmount;
        }

        return status;
    }

    onClick_loadMore() {
        this.loadMore.emit();
    }

    public get itemHasSinglePaymentOptionSelected() {
        return this.item.amountInCart && ((this.item.selectedPaymentOption && !this.item.selectedPaymentOptions) || this.item.selectedPaymentOptions?.length == 1)
    }

    public getSearchDisplayName(item: ItemModel) {
        return item.details.find(i => i.description.match(/_searchName_/))?.value ?? item.title;
    }

    /** Close either the mat-menu OR bottom sheet (depending on current width) containing SelectPaymentComponent */
    private _closeSelectPayment(narrow: boolean | null = null) {
        if ((this.appService.isNarrow && narrow === null) || narrow) {
            this.bottomSheet.dismiss();
            this._selectPaymentSheet = null;
        } else if((this.appService.isWide && narrow === null) || !narrow){
            this.trigger.closeMenu();
        }
    }

    /** Open either the mat-menu OR bottom sheet (depending on current width) containing SelectPaymentComponent
     * and await a response from the bottom sheet when on a narrow device.
     */
    public async openSelectPayment() {
        if (this.appService.isNarrow) {
            this._selectPaymentSheet = this.bottomSheet.open(SelectPaymentComponent, {
                panelClass: 'menu-sheet',
                data: this.item
            });

            /** Wait for the dismissal of the bottom sheet. This is necessary because we can't bind
             * the outputs of the SelectPaymentComponent as we would normally, but it's set up to pass
             * the relevant data through the afterDismissed event.
             */
            let data = await this._selectPaymentSheet.afterDismissed().toPromise()

            // Determine which type of event it was, if any at all.
            if (data?.paymentOption != null) {
                if (data.action === 'add')
                    this.on_addToCart([data.item, data.paymentOption, data.customAmount]);
                else if (data.action === 'update')
                    this.on_updateCartItem([data.item, data.paymentOption, data.customAmount]);
            }
        } else {
            this.trigger.openMenu();
            this.trigger.menuClosed.subscribe(() => {
                this.selectPaymentMenuOpen = false;
            });
            this.selectPaymentMenuOpen = true;
        }
    }

    /** Return the current state of the SelectPaymentComponent */
    private _isSelectPaymentOpen(narrow: boolean | null = null) {
        let open = false;
        if ((this.appService.isNarrow && narrow === null) || narrow) {
            open = this._selectPaymentSheet != null;
        } else if((this.appService.isWide && narrow === null) || !narrow) {
            open = this.trigger?.menuOpen;
        }
        return open;
    }
}

@NgModule({
    declarations: [ SearchResultComponent ],
    imports: [
        CommonModule,
        SelectPaymentModule,
        MatLegacyListModule,
        MatLegacyMenuModule,
        MatIconModule,
        MatToolbarModule,
        MatLegacyTooltipModule,
        MatLegacyButtonModule,
        MatLegacyDialogModule,
        MatLegacyProgressSpinnerModule,
        TellerOnlineIconsModule
    ],
    exports: [ SearchResultComponent ]

})

export class SearchResultModule { }
