import { HttpResponse, HttpResponseBase } from "@angular/common/http";
import { Observable } from "rxjs";


export class TellerOnlineApiClientBase {

    /**
     * Checks response for malformed aws timeouts. In the case of an aws timeout, a 504 is sent without the expected ErrorDto object in the body.
     * This detects that case and replaces the response with one containing an ErrorDto object so that the error is not silently swallowed.
     * @param url api url
     * @param response original response coming from the api
     * @param processResponse A transforming function that expects proper dto responses
     * @returns The properly transformed response to <T>
     */
    transformResult<T>(url: string, response: HttpResponseBase, processResponse: (r: HttpResponse<any>) => Observable<T>): Observable<T> {
        if (response.status === 504) {
            const errorDto = {
                errorMessage: "This action took too long to complete. Please try again.",
                errorNumber: `S-${Math.floor(Math.random() * 100000)}`,
                errorDef: "TimeoutFromApiClient"
            };
            throw errorDto;
        }
        return processResponse(<any>response)
    }
}
