// Angular Files
import { Pipe, PipeTransform } from '@angular/core';

// Named tileCasePipe just to ensure it doesn't conflict with Angular's titleCase pipe that doesn't work
@Pipe({
    name: 'titleCasePipe'
})
export class TitleCasePipe implements PipeTransform {

    transform(str: string, tagToWords: boolean = false) {
        if(str) {
            let strArr;
            if(tagToWords) 
                strArr = str.split("-");
            else
                strArr = str.split(" ");
        
            for(let i = 0; i < strArr.length; i++) {
                let word = strArr[i];
                word = word[0].toLocaleUpperCase() + word.slice(1);
                strArr[i] = word; 
            }
            return strArr.join(" ");
        }
    }

}
