﻿import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

// Based on https://blog.angular-university.io/angular-custom-validators/
@Directive({
    selector: "[validateExpirationDate]",
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: ValidateExpirationDateDirective,
        multi: true
    }]
})
export class ValidateExpirationDateDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors | null {
        return expirationDateValidator()(control);
    }
}

export function expirationDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let isValid: boolean = false;
        if (control?.value && control.value.length === 4) {
            let expiryMonth = control.value.slice(0, 2);
            let expiryYear = control.value.slice(2);

            let currentYear = new Date().getFullYear().toString().slice(2);
            let currentMonth = new Date().getMonth() + 1;

            // If the user entered an invalid month then the date cannot be valid.
            if (expiryMonth < 1 || expiryMonth > 12) {
                isValid = false;
            }
            // The date the user entered is valid if the year entered is after the current year
            // or if the year entered is the same as current year and month entered is either the current month or one in the future.
            else if (expiryYear > currentYear || (expiryYear === currentYear && expiryMonth >= currentMonth)) {
                isValid = true;
            }
        } else {
            // If only partial data is present consider it valid to avoid multiple validation errors for the same field.
            isValid = true;
        }

        return isValid ? null : {pattern: true};
    }
}