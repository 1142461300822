<div class="echeck__confirmation layout-grid">
    <mat-checkbox (change)="onCheck_agreeECheckDisclaimer($event)">I Agree</mat-checkbox>
    <div class="echeck__confirmation__preview" ellipsis [ellipsis-content]="shortECheckMessage"
        ellipsis-word-boundaries></div>

    <div class="echeck__confirmation__btn">
        <button mat-button type="button" color="accent" (click)="onClick_showECheckDisclaimer()"
            aria-controls="aria_disclaimer" aria-haspopup="dialog">
            <mat-icon svgIcon="info" class="teller-online-icon"></mat-icon>
            <span>{{forEdit ? 'View Disclaimer' : 'View Full Terms & Conditions'}}</span>
        </button>
    </div>
</div>