// Angular Files
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular Material Files
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';

// Other External Files
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask';

// Component Files
import { SelectPaymentComponent } from './select-payment.component';

// Teller Online Library Files
import { TellerOnlineSharedModule } from 'teller-online-libraries/shared';

@NgModule({
    declarations: [ SelectPaymentComponent ],
    imports: [
        TellerOnlineSharedModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatLegacyButtonModule,
        MatLegacyListModule,
        MatLegacyMenuModule,
        MatIconModule,
        MatLegacyInputModule,
        NgxMaskDirective,
        NgxMaskPipe,
        MatLegacyFormFieldModule
    ],
    providers: [
        { provide: MatBottomSheetRef, useValue: {} },
        { provide: MAT_BOTTOM_SHEET_DATA, useValue: MAT_BOTTOM_SHEET_DATA },
        provideNgxMask()
    ],
    exports: [ SelectPaymentComponent ]

})

export class SelectPaymentModule { }