// Angular Files
import { Injectable } from '@angular/core';

// Other External Files

// Payment Integration Files
import { PaymentMethodTypeEnum, PaymentMethodTypeEnumConvertor } from 'apps/public-portal/src/app/payment-integrations';

// Teller Online Files
import {
    PaymentMethodDto,
    PaymentMethodsApiClient,
    SavedPaymentMethodDto
} from 'apps/public-portal/src/app/core/api/PublicPortalApiClients';

@Injectable({
    providedIn: 'root'
})
export class PaymentMethodsService {
    constructor(private paymentMethodsApiClient: PaymentMethodsApiClient) {
    }

    /** Retrieves methods used during checkout */
    public async getSavedPaymentMethods(): Promise<SavedPaymentMethodModel[]> {
        let methods: SavedPaymentMethodModel[] = await this.paymentMethodsApiClient.getSavedPaymentMethods().toPromise();
        // map the enum to our new enum
        for(let i = 0; i < methods?.length; i++) {
            methods[i].type = PaymentMethodTypeEnumConvertor.fromDto(methods[i].paymentMethodType)
        }
        return methods;
    }

    /** Retrieves methods used within the user's profile for editing */
    public async getPaymentMethods(): Promise<PaymentMethodModel[]> {
        return await this.paymentMethodsApiClient.getPaymentMethods().toPromise();
    }

    public async getPaymentMethod(id: number) : Promise<PaymentMethodModel> {
        let method = await this.paymentMethodsApiClient.getPaymentMethod(id).toPromise();
        let methodModel = new PaymentMethodModel(method);
        methodModel.type = PaymentMethodTypeEnumConvertor.fromDto(methodModel.paymentMethodType);
        return methodModel;
    }

    public async removePaymentMethod(id: number) {
        return await this.paymentMethodsApiClient.removePaymentMethod(id).toPromise();
    }

    public async setDefaultPaymentMethod(id: number) {
        return await this.paymentMethodsApiClient.setDefaultPaymentMethod(id).toPromise();
    }

    public checkCardType(cardType) {
        if(cardType) {
            if (cardType.toLowerCase().includes("visa")) {
                return "visa";
            } else if (cardType.toLowerCase().includes("mastercard") || cardType.toLowerCase().includes("mc") || cardType.toLowerCase().includes("m/c")) {
                return "mastercard";
            } else if (cardType.toLowerCase().includes("disc")) {
                return "discover";
            } else if (cardType.toLowerCase().includes("american") || cardType.toLowerCase().includes("amex")) {
                return "amex";
            } else {
                return "creditCard";
            }
        }
    }
}

export class SavedPaymentMethodModel extends SavedPaymentMethodDto{
    type?: PaymentMethodTypeEnum;
}

export class PaymentMethodModel extends PaymentMethodDto{
    type?: PaymentMethodTypeEnum;
}
