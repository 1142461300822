import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-login',
    templateUrl: '../svgs/Login.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--login'
    }
})
export class TellerOnlineIconLogin {
}