// Angular Files
import { Component, NgModule, Input, AfterContentInit, HostBinding, HostListener, OnDestroy, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { A11yModule } from '@angular/cdk/a11y';

// Angular Material Files
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

// Teller Online Library Files
import { TellerOnlineIconsModule } from "teller-online-libraries/icons";
import { TellerOnlineAppService } from 'teller-online-libraries/core';

@Component({
    selector: 'teller-online-fab-menu',
    templateUrl: './fab-menu.component.html',
    styleUrls: ['./fab-menu.component.scss'],
    host: {
        class: 'fab-menu'
    }
})
export class TellerOnlineFabMenuComponent implements AfterContentInit, OnDestroy {
    // Declare @Input variables
    @Input() actionButtons: TellerOnlineFabMenuActionItem[];
    @Input() svgIcon?: string;
    @Input() icon?: string;
    @Input() color?: string = "accent";
    /** If provided, the fab will be an extended fab with this text */
    @Input() extended?: string;
    /** If true, will only be extended when it's active */
    @Input() extendOnActive: boolean = false;

    // Declare @Output variables

    // Public variables
    public FabMenuActionType = TellerOnlineFabMenuActionType;

    // Private variables

    // Subscriptions

    @HostBinding('class.fab-menu--active') public isActive = false;

    @HostListener('document:keydown', ['$event'])
    onKeydown(e) {
        if(e.key == "Escape") this.isActive = false;
    }

    constructor(private appService: TellerOnlineAppService, private el: ElementRef) {
        this.appService.fabShown = true;
    }

    ngAfterContentInit() {
    }

    ngOnDestroy(): void {
        this.appService.fabShown = false;
    }

    public onClick_toggleMenu() {
        this.isActive = !this.isActive;
        //push off the event to make sure the change is rendered first
        // then focus the first focusable element
        setTimeout(() => {
            this.el.nativeElement.querySelector(".fab-menu__actions").querySelector("button, a").focus();
        }, 0);
    }

}
@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatLegacyButtonModule,
        MatIconModule,
        MatLegacyTooltipModule,
        A11yModule,
        TellerOnlineIconsModule
    ],
    declarations: [ TellerOnlineFabMenuComponent ],
    exports: [ TellerOnlineFabMenuComponent ]
})
export class TellerOnlineFabMenuModule { }

export class TellerOnlineFabMenuActionItem {
    icon?: string;
    svgIcon?: string;
    color?: string = "accent";
    actionType: TellerOnlineFabMenuActionType = TellerOnlineFabMenuActionType.Button;
    action: string | Function;
    /**
     * If provided, will appear as a white box to the left of the fab button matching material design spec
     */
    label?: string;
}

export enum TellerOnlineFabMenuActionType {
    /** Will be rendered as an anchor tag for proper accessibility
     * Clicking it will take the user to another page
    */
    Anchor,
    /** Will be rendered as a button tag for proper accessibility
     * Clicking it will perform an action on this page
    */
    Button
}