// Angular Files
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { A11yModule } from '@angular/cdk/a11y';

// Angular Material Files
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

// Other External Files
import { RecaptchaModule } from 'ng-recaptcha';

// Root App Component Files
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Teller Online Files
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import {
    AuthService,
    CartService,
} from './core/services';
import { PAYMENT_INTEGRATIONS_API_BASE_URL } from './core/api/PaymentIntegrationApiClients';
import { ONLINE_IDENTITY_API_BASE_URL } from './core/api/TellerOnlineIdentityApiClients';
import { config } from "apps/public-portal/src/config/config";
import { environment } from 'apps/public-portal/src/environments/environment'

// Teller Online Library Files
import { TellerOnlineIconsModule, TellerOnlineIconsService } from 'teller-online-libraries/icons';
import {
    TellerOnlineErrorHandlerService,
    TellerOnlineSiteMetadataService,
    TellerOnlineCoreModule,
    TellerOnlineXsrfService,
    TellerOnlineAppService,
    API_BASE_URL
} from 'teller-online-libraries/core';
import { TellerOnlineSharedModule, TellerOnlineWindowService } from 'teller-online-libraries/shared';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        RecaptchaModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: "registerImmediately" }),
        CoreModule,
        SharedModule,
        TellerOnlineIconsModule,
        TellerOnlineSharedModule,
        TellerOnlineCoreModule,
        BrowserAnimationsModule,
        // Angular Material
        MatLegacyButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatToolbarModule,
        A11yModule
    ],
    providers: [
        Title,
        TellerOnlineIconsService,
        CurrencyPipe,
        { provide: ErrorHandler, useClass: TellerOnlineErrorHandlerService },
        { provide: API_BASE_URL, useValue: config.publicPortalApiUrl },
        { provide: PAYMENT_INTEGRATIONS_API_BASE_URL, useValue: config.paymentIntegrationsApiUrl },
        { provide: ONLINE_IDENTITY_API_BASE_URL, useValue: config.identityApiUrl },
        {
            provide: APP_INITIALIZER, useFactory: loadRequiredServices, deps: [
                TellerOnlineAppService,
                TellerOnlineXsrfService,
                TellerOnlineErrorHandlerService,
                TellerOnlineSiteMetadataService,
                AuthService,
                CartService,
                TellerOnlineWindowService,
                API_BASE_URL], multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: TellerOnlineXsrfService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TellerOnlineAppService, multi: true }
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }


export function loadRequiredServices(
    appService: TellerOnlineAppService,
    xsrfService: TellerOnlineXsrfService,
    errorHandlerService: TellerOnlineErrorHandlerService,
    siteMetadataService: TellerOnlineSiteMetadataService,
    authService: AuthService,
    cartService: CartService,
    windowService: TellerOnlineWindowService,
    apiBaseUrl: string) {
    return () => {
        return new Promise((resolve) => {
            // initialize all the shared services with the correct base url (because the injection token will not have run yet at this point)
            errorHandlerService.init(environment, apiBaseUrl);
            xsrfService.init(apiBaseUrl);
            siteMetadataService.init(apiBaseUrl);
            /* Immediately check if we need to reload the page,
            because we are immediatelly registering the service worker this should finish before the other requests finish so we shouldn't see any blips (double load)
            if there is a blip though, the speed should be so fast that it shouldn't be a big deal*/
            appService.checkForUpdates();
            // Grab an XSRF Token (needed for every http request)
            xsrfService.loadXsrfToken().then(() => {
                siteMetadataService.load().then(() => {
                    // Reassign the path to the favicon based on the current tenant (now that siteMetadataService is loaded)
                    if (siteMetadataService) {
                        // Tenants should be a single lowercase word
                        if (!siteMetadataService.tenant.match(/^[a-z]+$/)) {
                            return;
                        }

                        let favicon = document.querySelector("#favicon");
                        favicon.setAttribute('href', `assets/${siteMetadataService.tenant}/favicon.ico`);
                    }

                    getUser(siteMetadataService, authService, cartService, windowService, resolve);
                });
            });
        });
    }
}

function getUser(
    siteMetadataService: TellerOnlineSiteMetadataService,
    authService: AuthService,
    cartService: CartService,
    windowService: TellerOnlineWindowService,
    resolve) {
    if (siteMetadataService.authenticationEnabled) {
        // Get user details (if we have a user, needed on every page)
        authService.refreshAuthDetails().then(() => {
            // If there's a user (and this isn't the processing page), grab their cart and attempt to merge it with any session cart
            if (authService.authDetails.isSignedIn && !windowService?.url?.includes("checkout/processing"))
                cartService.retrieveUserCart();

            // Check every 30 seconds for if our session is about to expire.
            setTimeout(() => authService.checkSessionExpiration(), 30000);

            accesibility_contentLoaded();
            // We're done loading, remeber to call resolve to tell the app we're all good now!
            resolve(true); // The value could be anything but it doesn't matter, true means nothing
        });
    } else {
        accesibility_contentLoaded();
        resolve(true);
    }
}

function accesibility_contentLoaded() {
    document.querySelector('body').setAttribute('aria-busy', 'false');
}
