// Angular Files
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

// Teller Online Files
import { CoreModule } from 'apps/public-portal/src/app/core/core.module';
import { CartService } from './cart.service';

// Teller Online Library Files
import { TellerOnlineSiteMetadataService } from 'teller-online-libraries/core';
import { FlagTypeEnumDto } from 'libraries/core/src/lib/api/CoreWebApi';

@Injectable({
    providedIn: CoreModule
})
export class AccessGuardService {

    // If the url contains any of these values, we can't access that page when maintenance mode is active
    private _maintenancePages = [
        "cart/:itemGuid",
        "search/:id",
        "search/:pageId/details",
        "search/:id/:searchTypeName",
        "search/:pageId/item/:itemTypeId"
    ];

    constructor(
        private router: Router,
        private siteMetadataService: TellerOnlineSiteMetadataService,
        private cartService: CartService
    ) { }

    canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
        return this.canActivate(route);
    }

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
        const isMaintenancePage = this._maintenancePages.includes(route.routeConfig.path);
        let currentMaintenanceNotices = this.siteMetadataService.activeNotices?.filter(n => n.integrationFlags.filter(f => f.flagType == FlagTypeEnumDto.Maintenance));

        if (currentMaintenanceNotices.length > 0 && isMaintenancePage) {
            let itemPageId;
            if (route.routeConfig.path.startsWith("cart")) {
                let guid = route.params['itemGuid'];
                itemPageId = this.cartService.cartSnapshot.items.find(i => i.itemGuid == guid).page.pageId
            } else {
                itemPageId = route.params['id'] ?? route.params['pageId'];
            }

            if (currentMaintenanceNotices.find(n => n.integrationFlags.find(f => f.pageId == itemPageId && f.flagType == FlagTypeEnumDto.Maintenance))) {
                return this.router.parseUrl('/');
            }
        }

        return true;
    }
}
