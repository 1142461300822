// Angular Files
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

// Payment Integrations
import { PaymentProcessorProvider } from 'apps/public-portal/src/app/payment-integrations';

// Teller Online Files
import {
    AuthService,
    UserService,
    InboundRedirectService
} from 'apps/public-portal/src/app/core/services';

// Teller Online Library Files
import {
    TellerOnlineAppService,
    TellerOnlineSiteMetadataService
} from 'teller-online-libraries/core';

@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html',
    styleUrls: ['./header.component.scss'],
    host: {
        class: 'header'
    }
})
export class HeaderComponent {
    public returnToSenderLabel: string = "Cancel Payment";
    public isCheckoutSuccessPage: boolean = false;

    @Output() toggleMobileMenu = new EventEmitter<boolean>();
    @Output() toggleHelp = new EventEmitter<boolean>();
    @Input() siteTitle: string;

    constructor(
        public appService: TellerOnlineAppService,
        public siteMetadataService: TellerOnlineSiteMetadataService,
        public authService: AuthService,
        public userService: UserService,
        public paymentProvider: PaymentProcessorProvider,
        public inboundRedirectService: InboundRedirectService,
        public router: Router
    ) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.isCheckoutSuccessPage = event.url.includes('/checkout/success');
                this.returnToSenderLabel = this.isCheckoutSuccessPage
                    ? `Return to ${inboundRedirectService.redirectSourceFriendlyName}` 
                    : 'Cancel Payment';
            }
        });
    }

    onClick_signOut = async () => {
        await this.authService.signOut("/sign-in");
    }

    onClick_toggleMobileMenu() {
        this.toggleMobileMenu.emit(true);
    }

    onClick_toggleHelp() {
        if(!this.appService.currentUrl.includes("help")) this.toggleHelp.emit(true);
    }

    async onClick_cancelOrCompletePayment() {
        if (this.isCheckoutSuccessPage) {
            await this.inboundRedirectService.promptForRedirectToSource();
        } else {
            await this.inboundRedirectService.cancelPaymentAndRedirect();
        }
    }

    get canSavePaymentMethod(): boolean {
        return this.paymentProvider.canSavePaymentMethod;
    }

    get logoSource(): string {
        return 'assets/' + (this.siteMetadataService.tenant ?? 'images') + '/logo.png';
    }
}

