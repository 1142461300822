import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-minus',
    templateUrl: '../svgs/Minus.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--minus'
    }
})
export class TellerOnlineIconMinus {
}