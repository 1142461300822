// Angular Files
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Teller Online Shared Files
import { DirectivesModule } from './directives/directives.module';
import { PipesModule } from './pipes/pipes.module';
import { ComponentsModule } from './components/components.module';
import { ServicesModule } from './services/services.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule, 
        DirectivesModule, 
        PipesModule,
        ComponentsModule,
        ServicesModule
    ],
    exports: [
        CommonModule, 
        DirectivesModule, 
        PipesModule,
        ComponentsModule,
        ServicesModule
    ]
})
export class TellerOnlineSharedModule { }
