<header class="sidebar-header">
    <mat-toolbar>
        <h2 tabindex="-1" cdkFocusInitial>How can we help you?</h2>
        <button class="close-button" type="button" mat-icon-button (click)="onClick_toggleHelp()" aria-label="close help">
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar>
</header>

<form role="form" (submit)="onSubmit_searchArticles($event)" (reset)="onReset_clearSearch()" aria-labelledby="aria_help_search">
    <mat-form-field appearance="standard">
        <mat-label id="aria_help_search">Search help articles</mat-label>
        <input matInput name="search" [(ngModel)]="searchString">
        <mat-icon matPrefix svgIcon="search" class="teller-online-icon"></mat-icon>
        <button mat-icon-button matSuffix type="reset" *ngIf="searchString">
            <mat-icon matSuffix>close</mat-icon>
        </button>
    </mat-form-field>
</form>

<mat-list aria-labelledby="aria_relevant_articles" tabindex="0" role="list" *ngIf="relevantArticles?.length > 0">
    <h3 mat-subheader id="aria_relevant_articles">Relevant Articles</h3>
    <mat-list-item *ngFor="let article of relevantArticles">
        <h4 mat-line>{{article.title}}</h4>
        <div mat-line [innerHtml]="article.trustedBody"></div>
    </mat-list-item>
</mat-list>

<mat-list aria-labelledby="aria_other_articles" tabindex="0" role="list">
    <h3 mat-subheader id="aria_other_articles"><ng-template [ngIf]="relevantArticles?.length > 0">Other </ng-template>Articles</h3>
    <mat-list-item *ngFor="let article of otherArticles">
        <h4 mat-line>{{article.title}}</h4>
        <div mat-line [innerHtml]="article.trustedBody"></div>
    </mat-list-item>
</mat-list>
<a routerLink="/help" class="view-all" *ngIf="totalArticleCount > relevantArticles?.length + otherArticles?.length && appService.currentUrl != 'help'" mat-button color="accent">View All Articles</a>