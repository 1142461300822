// Angular Files
import { AfterViewInit, Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

// Other External Files
import { first } from 'rxjs/operators';

// Payment Integration Files
import { PointAndPayService } from 'apps/public-portal/src/app/payment-integrations/point-and-pay/service';
import { PaymentProcessorProvider } from 'apps/public-portal/src/app/payment-integrations/base';

// Teller Online Files
import { 
    AuthService,
    CartModel, 
    CartService, 
    AuthDetailsResponseModel
} from 'apps/public-portal/src/app/core/services';
import { TellerOnlineWindowService } from 'teller-online-libraries/shared';


@Component({
    selector: 'app-point-and-pay',
    templateUrl: './point-and-pay.component.html',
    host: {
        class: 'point-and-pay'
    }
})
export class PointAndPayComponent implements AfterViewInit {
    // Public variables
    public user: AuthDetailsResponseModel;
    public cart: CartModel;
    public paymentIdentifier: string;

    // Private variables
    private _loaded: boolean = false;
    private _submitting: boolean = false;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private authService: AuthService,
        private cartService: CartService,
        private paymentProvider: PaymentProcessorProvider,
        private windowService: TellerOnlineWindowService,
        public pointAndPayService: PointAndPayService
    ) { 
    }

    //#region OnInit Implementation

    async ngAfterViewInit() {

        // user details used for prepopulating the name/email on the checkout page
        this.user = this.authService.authDetails;

        // ensure we have the cart guid and items
        this.cartService.cart$.pipe(first(c => !!c.cartGuid)).subscribe(async cart => {
            this.cart = cart;
            this.paymentIdentifier = await this.pointAndPayService.startPayment(cart.cartGuid);
            this.windowService.setLocalStorageItem("pnpPaymentIdentifier", this.paymentIdentifier);
            this.submitForm();
        });

        // ensure we have the config
        this.paymentProvider.configLoaded$.subscribe(loaded => {
            this._loaded = loaded;
            this.submitForm();
        });
    }

    //#endregion
    
    
    //#region Event Handlers

    submitForm = () => {
        // only perform the actual submission if both the cart and the config are loaded
        if(this._loaded && this.paymentIdentifier && !this._submitting) {
            this._submitting = true;
            // currently this doesn't work, but I'm leaving it in, in the hopes we get P&P to change it
            /*// If we just loaded the page, these urls will still have the cartGuid variable in them, so replace it
            if(this.pointAndPayService.finishUrl.includes("[cartGuid")) {
                this.pointAndPayService.finishUrl = this.pointAndPayService.finishUrl.replace("[cartGuid", this.cart.cartGuid);
                this.pointAndPayService.cancelUrl = this.pointAndPayService.cancelUrl.replace("[cartGuid", this.cart.cartGuid);
            }*/
            let form = this.document.querySelector('form');
            // set the paramXML value manually because we can't properly bind it in the html
            //@ts-ignore
            form.elements.paramXML.value = this.paramXML;
            form.submit();
        }
    };
    
    //#endregion

    /**
     * Build up the paramXML property to be sent in the request because we can't do a proper binding in the template
     */
    public get paramXML() {
        let xml = "";
        if(this.paymentIdentifier) {
            xml = "<params";

            // populate user details if signed in
            if(this.user?.isSignedIn) {
                // split the name on spaces
                let username = this.user.name.split(" ");
                // last name is the last word in the name, first name will be everything else
                let lastname = username.pop();
                // leaving both of these in because at one time SignerFirstName and SignerLastName worked, then they stopped
                // and FirstName and LastName worked -- so both will be left in for the best support
                xml += ` SignerFirstName="${username.join(" ")}" SignerLastName="${lastname}" Email="${this.user.email}"`;
                xml += ` FirstName="${username.join(" ")}" LastName="${lastname}"`;
            // populate guest user email if not signed in
            } else {
                xml += ` Email="${this.cart.guestEmailAddress}"`;
            }

            // add cart guid
            xml += ` CartGuid="${this.paymentIdentifier}">`;
            // add cart items
            this.cart.items?.forEach(item => {
                xml += `<product ID="${this.pointAndPayService.productId}" Amount="${item.amountInCart}" />`;
            });

            xml += "</params>";
        }
        return xml;
    }
}
