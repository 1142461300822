// Angular Files
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

// Teller Online Files
import { HelpArticle, HelpService, HelpTag, HELP_ARTICLES } from 'apps/public-portal/src/app/shared/services';

// Teller Online Library Files
import { TellerOnlineAppService } from 'teller-online-libraries/core';

@Component({
    selector: 'app-help-sidebar',
    templateUrl: './help-sidebar.component.html',
    styleUrls: ['./help-sidebar.component.scss'],
    host: {
        class: 'help-sidebar'
    }
})
export class HelpSidebarComponent implements OnInit {   
    // Declare @Output variables
    @Output() public toggleHelp = new EventEmitter<boolean>();
     
    // Public variables
    public relevantArticles: HelpArticle[];
    public otherArticles: HelpArticle[];
    public totalArticleCount: number = HELP_ARTICLES.length;
    public searchString: string;

    // Private variables
    private _tag: HelpTag;

    // Subscriptions

    constructor(
        public appService: TellerOnlineAppService,
        public helpService: HelpService
    ) { }

    public ngOnInit() {
        this._determineTagByPage();
        this._filterArticles(this._tag);
    }

    public onSubmit_searchArticles(e) {
        e.preventDefault();
        this._filterArticles(this.searchString);
    }

    public onReset_clearSearch() {
        this.searchString = "";
        this._filterArticles(this._tag);
    }

    public onClick_toggleHelp = () => {
        this.toggleHelp.emit(true);
    }

    private _filterArticles(filterValue: string, tagMatch: boolean = false) {
        this.relevantArticles = this.helpService.articles.filter(article => {
            return article.match(filterValue, tagMatch);
        });
        this.otherArticles = this.helpService.articles.filter(article => {
            return !article.match(filterValue, tagMatch);
        });

        // Only grab at most 3 articles if we pulled relevant articles, we'll link off to view the rest
        if(this.relevantArticles?.length > 0) this.otherArticles = this.otherArticles.slice(0,2);
        // If we don't have any relevant articles, load at most 5 articles, we'll link off to view the rest
        else this.otherArticles = this.otherArticles.slice(0, 4);
    }

    private _determineTagByPage() {
        let currentUrl = this.appService.currentUrl;

        if(currentUrl.includes("payment-history") || currentUrl.includes("checkout/success")) this._tag = HelpTag.PaymentHistory;
        else if(currentUrl.includes("payment-methods")) this._tag = HelpTag.PaymentMethods;
        else if(currentUrl.includes("profile")) this._tag = HelpTag.ManageProfile;
        else if(currentUrl.includes("checkout")) this._tag = HelpTag.Checkout;
        else if(currentUrl.includes("sign") || currentUrl.includes("password") || currentUrl.includes("verify-email")) this._tag = HelpTag.Authentication;
        else this._tag = HelpTag.Search;
    }
}
