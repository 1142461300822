// Angular Files
import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";

// Teller Online Files
import { SessionAuthGuardService } from "./core/services";
import { AccessGuardService } from "./core/services/access-guard.service";

const routes: Routes = [
    {
        path: "sign-up/verify-email",
        canActivate: [SessionAuthGuardService],
        loadChildren: () =>
            import("./features/verify-email/verify-email.module").then(
                (m) => m.VerifyEmailModule
            ),
        data: {title: "Verify Email"}
    },
    {
        path: "help",
        canActivate: [SessionAuthGuardService],
        loadChildren: () =>
            import("./features/help/help.module").then(
                (m) => m.HelpModule
            ),
        data: {title: "Help"}
    },
    {
        path: "sign-in",
        canActivate: [SessionAuthGuardService],
        loadChildren: () =>
            import("./features/sign-in/sign-in.module").then((m) => m.SignInModule),
        data: {title: 'Sign In'}
    },
    {
        path: "sign-up",
        canActivate: [SessionAuthGuardService],
        loadChildren: () =>
            import("./features/sign-up/sign-up.module").then((m) => m.SignUpModule),
        data: {title: 'Sign Up'}
    },
    {
        path: "forgot-password",
        canActivate: [SessionAuthGuardService],
        loadChildren: () =>
            import("./features/forgot-password/forgot-password.module").then(
                (m) => m.ForgotPasswordModule
            ),
        data: {title: 'Forgot Password'}
    },
    {
        path: "forgot-password/reset",
        canActivate: [SessionAuthGuardService],
        loadChildren: () =>
            import("./features/reset-password/reset-password.module").then(
                (m) => m.ResetPasswordModule
            ),
        data: {title: 'Reset Password'}
    },
    {
        path: "search/:id",
        canActivate: [SessionAuthGuardService, AccessGuardService],
        loadChildren: () =>
            import("./features/search/search.module").then((m) => m.SearchModule),
        data: {title: 'Search'} // page title will be added to the start of the title
    },
    {
        path: "search/:pageId/details",
        canActivate: [SessionAuthGuardService, AccessGuardService],
        loadChildren: () =>
            import("./features/item-details/item-details.module").then(
                m => m.ItemDetailsModule
            ),
        data: {title: "View Details"}
    },
    {
        path: "search/:id/:searchTypeName",
        canActivate: [SessionAuthGuardService, AccessGuardService],
        loadChildren: () =>
            import("./features/search/search.module").then((m) => m.SearchModule),
        data: {title: 'Search'} // page title will be added to the start of the title and searchtype label will be added to the end
    },
    {
        path: "search/:pageId/item/:itemTypeId",
        canActivate: [SessionAuthGuardService, AccessGuardService],
        loadChildren: () =>
            import("./features/item-details/item-details.module").then(
                (m) => m.ItemDetailsModule
            ),
        data: {title: 'Purchase'}
    },
    {
        path: "download-receipt/:uid",
        canActivate: [SessionAuthGuardService],
        loadChildren: () =>
            import("./features/download-receipt/download-receipt.module").then(
                (m) => m.DownloadReceiptModule
            ),
        data: { title: 'Download Receipt' }
    },
    {
        path: "cart/:itemGuid",
        canActivate: [SessionAuthGuardService, AccessGuardService],
        loadChildren: () =>
            import("./features/item-details/item-details.module").then(
                (m) => m.ItemDetailsModule
            ),
        data: {title: 'Edit'}
    },
    {
        path: "checkout",
        canActivate: [SessionAuthGuardService, AccessGuardService],
        loadChildren: () =>
            import("./features/checkout/checkout.module").then(
                (m) => m.CheckoutModule
            ),
        data: {title: 'Checkout'}
    },
    {
        path: "checkout/redirect",
        loadChildren: () =>
            import("./features/checkout-redirect/checkout-redirect.module").then(
                (m) => m.CheckoutRedirectModule
            ),
        data: {title: "Checkout Redirect"}
    },
    {
        path: "checkout/success/:cartGuid",
        canActivate: [SessionAuthGuardService],
        loadChildren: () =>
            import("./features/checkout-success/checkout-success.module").then(
                (m) => m.CheckoutSuccessModule
            ),
        data: {title: 'Payment Receipt'}
    },
    {
        path: "checkout/processing",
        canActivate: [SessionAuthGuardService],
        loadChildren: () =>
            import("./features/checkout-processing/checkout-processing.module").then(
                (m) => m.CheckoutProcessingModule
            ),
        data: {title: 'Processing Payment...'}
    },
    {
        path: "force-change-password",
        loadChildren: () =>
            import("./features/force-change-password/force-change-password.module").then(
                (m) => m.ForceChangePasswordModule
            ),
        data: {title: 'Change password'}
    },
    {
        path: "profile",
        canActivateChild: [SessionAuthGuardService],
        loadChildren: () =>
            import("./features/profile/profile.module").then(
                (m) => m.ProfileModule
            ),
        data: {title: 'Manage Profile'}
    },
    {
        path: "TOL/:redirectSource",
        loadChildren: () =>
            import("./features/inbound-redirect/inbound-redirect.module").then(
                (m) => m.InboundRedirectModule
            ),
        data: {title: 'Processing request...'}
    },
    {
        path: "privacy-policy",
        loadChildren: () =>
            import("./features/privacy-policy/privacy-policy.module").then(
                (m) => m.PrivacyPolicyModule
            ),
        data: {title: 'Privacy Policy'}
    },
    {
        path: "",
        canActivate: [SessionAuthGuardService],
        loadChildren: () =>
            import("./features/welcome/welcome.module").then((m) => m.WelcomeModule)
    },
    {
        path: "**",
        redirectTo: "",
        pathMatch: "full",
    },
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: "reload",
            scrollPositionRestoration: "top",
            // will ensure that cancelling a back navigation event will not mess up navigation
            // This happens because cancelling via the canDeactivate occurs AFTER the url has been updated
            // If we use the default of 'replace', the navigation would indicate we had gone back even though we hadn't
            canceledNavigationResolution: 'computed',
            preloadingStrategy: PreloadAllModules
        })
    ],
    providers: [SessionAuthGuardService],
    exports: [RouterModule],
    declarations: [],
})
export class AppRoutingModule {}
