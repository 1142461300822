// Angular Files
import { NgModule } from '@angular/core';

// Teller Online Component Files
import { TellerOnlineDialogModule } from './dialog/dialog.component';
import { TellerOnlineFabMenuModule } from './fab-menu/fab-menu.component';
import { TellerOnlineNotificationModule } from './notification/notification.component';

@NgModule({
    imports: [
        TellerOnlineFabMenuModule,
        TellerOnlineDialogModule,
        TellerOnlineNotificationModule
    ],
    exports: [
        TellerOnlineFabMenuModule,
        TellerOnlineDialogModule,
        TellerOnlineNotificationModule
    ]
})
export class ComponentsModule { }