// Angular Files
import { Location } from '@angular/common';
import { Component, NgZone, OnInit, DoCheck, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// Angular Material Files
import { MatIconRegistry } from '@angular/material/icon';

// Other External Files
import { Subscription } from 'rxjs';

// Payment Integration Files
import { BasePaymentProcessorComponent } from 'apps/public-portal/src/app/payment-integrations/base/components';
import { WellsFargoService } from 'apps/public-portal/src/app/payment-integrations/wells-fargo/service';
import { PaymentProcessorProvider } from 'apps/public-portal/src/app/payment-integrations/base';

// Teller Online Files
import { AuthService, CartService, InboundRedirectService } from 'apps/public-portal/src/app/core/services';

// Teller Online Library Files
import { TellerOnlineAppService, TellerOnlineSiteMetadataService } from 'teller-online-libraries/core';
import {
    TellerOnlineMessageService,
    TellerOnlineValidationService
} from 'teller-online-libraries/shared';

@Component({
    selector: 'app-wells-fargo',
    templateUrl: './wells-fargo.component.html',
    styleUrls: ['./wells-fargo.component.scss'],
    host: {
        class: 'wells-fargo'
    }
})
export class WellsFargoComponent extends BasePaymentProcessorComponent implements OnInit, DoCheck, OnDestroy {
    // Subscriptions
    private _formChangeSubscription: Subscription;

    constructor(
        private wellsFargoService: WellsFargoService,
        ngZone: NgZone,
        location: Location,
        appService: TellerOnlineAppService,
        siteMetadataService: TellerOnlineSiteMetadataService,
        inboundRedirectService: InboundRedirectService,
        cartService: CartService,
        authService: AuthService,
        messageService: TellerOnlineMessageService,
        validationService: TellerOnlineValidationService,
        paymentProvider: PaymentProcessorProvider,
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer
    ) {
        super(appService, ngZone, location, siteMetadataService, inboundRedirectService, cartService, authService, messageService, validationService, paymentProvider, matIconRegistry, domSanitizer);
        this.loading = true;
    }

    //#region DoCheck Implementation

    ngDoCheck(): void {
        super.ngDoCheck();
    }

    //#endregion

    //#region OnDestroy Implementation

    ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this._formChangeSubscription) this._formChangeSubscription.unsubscribe();
    }

    //#endregion

    //#region Event Handlers

    onSubmit_validateAndSubmit = () => {
        this.appService.triggerPageLoading('Validating information...');
        // Run validation against regular fields + WellsFargo fields (manually set to false because they haven't loaded yet)
        if (this.validationService.runValidation(this.paymentDetailsForm, null, false)) {
            if (this.forEdit) {
                this.savePaymentMethod();
            } else {
                this.payCart();
            }
        } else {
            this.appService.finishPageLoading();
        }
    };

    //#endregion

    //#region BasePaymentProcessorComponent Implementation

    public override async savePaymentMethod() {
        this.appService.triggerPageLoading('Saving information...');

        try {
            var response = await this.wellsFargoService.savePaymentMethod({
                paymentMethodData: this.paymentMethodData,
                paymentToken: this._paymentToken,
                paymentMethodId: this.paymentMethodId
            });

            this.paymentMethodId = response.paymentMethodId;

            this.updateUrl();

            this.processingComplete.emit(response.last4);
        } catch (e) {
            this.processingError.emit(e);
        } finally {
            this.finishedDataEntry(true);
            this.appService.finishPageLoading();
        }
    }

    public override async payCart() {
        this.appService.triggerPageLoading("Processing payment, do not refresh your browser...");

        // Wait for the response.
        await this.cartService.updateCart({
            guestEmailAddress: this.paymentMethodData.billingInfo.email,
            rememberPaymentMethod: this.paymentMethodData.rememberPaymentMethod,
            paymentMethodId: null //unset any previously saved paymentMethodId incase a previous attempt to use a saved method was made
        });

        let proceed = await this.cartService.refreshCart(this._cartGuid, this.paymentMethodData.type);
        if (proceed) {
            try {
                let postPaymentResponse = await this.wellsFargoService.payCart({
                    cartId: this._cartId,
                    paymentMethodData: this.paymentMethodData,
                    paymentToken: this._paymentToken,
                    paymentProcessorTestTrigger: this._selectedTestTrigger,
                    inboundRedirectSourceId: this.inboundRedirectService.redirectSourceId
                });

                if (postPaymentResponse.cartStatus) {
                    this.processingComplete.emit(postPaymentResponse);
                } else {
                    // Display the appropriate message for the current payment method type
                    this.messageService.notification("Unable to process payment. Your account has not been charged. Reason: " +
                        postPaymentResponse.errorMessage, "error", 5000);
                }

            } catch (e) {
                this.processingError.emit(e);
            } finally {
                this.finishedDataEntry(true);
                this.appService.finishPageLoading();
            }
        } else {
            this.finishedDataEntry(true);
            this.appService.finishPageLoading();
        }
    }

    //#endregion
}
