import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-remove',
    templateUrl: '../svgs/Delete.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--remove'
    }
})
export class TellerOnlineIconRemove {
}