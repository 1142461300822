// Angular Files
import { CommonModule } from '@angular/common';
import { Component, OnInit, HostListener, DoCheck, Input, NgModule, EventEmitter, Output } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule } from '@angular/material/legacy-card';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';

// Teller Online Files
import { ChangeUserPasswordDto } from "apps/public-portal/src/app/core/api/TellerOnlineIdentityApiClients";
import { FeatureCanDeactivate, AuthService, UserService, PendingChangesGuardService } from "apps/public-portal/src/app/core/services";
import { PasswordService } from "apps/public-portal/src/app/shared/services";
import { PasswordCaptionModule } from '../password-caption/password-caption.component';

// Teller Online Library Files
import { TellerOnlineAppService } from 'teller-online-libraries/core';
import { TellerOnlineValidationService, TellerOnlineMessageService, TellerOnlineSharedModule } from 'teller-online-libraries/shared';

@Component({
    selector: 'app-password-change',
    templateUrl: './password-change.component.html',
    styleUrls: ['./password-change.component.scss'],
    host: {
        class: 'change-password-view narrow-page'
    }
})
export class PasswordChangeComponent implements OnInit, DoCheck, FeatureCanDeactivate {
    // Declare @Input variables
    @Input()
    set email(value: string) {
        this.passwordChange.email = value;
    }

    get email(): string {
        return this.passwordChange.email;
    }

    // Declare @Output variables
    @Output() onSubmit: EventEmitter<boolean> = new EventEmitter<boolean>();

    // Public variables
    public passwordChange: ChangeUserPasswordDto = new ChangeUserPasswordDto();
    public passwordError: boolean = false;
    public passwordAdapter = {
        getValue: () => {
            return this.passwordChange.newPassword;
        },
        applyValidationResults: (e) => {
            this.passwordError = !e.isValid;
        },
         reset: () => {
            this.passwordError = false;
            this.passwordChange.newPassword = "";
        }
    };

    // Private variables
    private _pendingChanges: boolean = false;

    // #region cTor

    constructor(
        private appService: TellerOnlineAppService,
        private messageService: TellerOnlineMessageService,
        private authService: AuthService,
        public validationService: TellerOnlineValidationService,
        public passwordService: PasswordService,
        public userService: UserService
    ) {
        // @ts-ignore
        this.passwordChange = {
            email: '',
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
        };
    }

    // #endregion

    // #region OnInit implementation

    ngOnInit() {
        this.passwordChange.email = this.email
    }

    // #endregion

    // #region DoCheck implementation

    ngDoCheck(): void {
        // If our current data isn't empty and doesn't match any of the default data, data entry has started
        if (this.passwordChange.oldPassword || this.passwordChange.newPassword || this.passwordChange.confirmPassword) {
            this._pendingChanges = true;
        }
    }

    // #endregion

    // #region FeatureCanDeactivate implementation

    // Called when leaving this page (internally or externally)
    // The message/title are only used when leaving this page but staying on the site (internal navigation)
    canDeactivate(featurePrompt: { message: string, title: string }): boolean {
        featurePrompt.title = 'Discard Changes?'
        featurePrompt.message =
            'Any changes you have made will be lost.<br> ' +
            'Are you sure you want to continue?';
        // If there are no pending changes, return true (it's okay to continue), else return false (stay here)
        return !this._pendingChanges;
    }

    // #endregion

    // #region Event Handlers

    // @HostListener allows us to also guard against browser refresh, close, etc
    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        let prompt = { message: "", title: "" };
        if (!this.canDeactivate(prompt)) {
            // This message MAY be shown to the users in IE/Edge when navigating away from the site
            // I haven't experienced it so it may be an older implementation that supports it but as of 2017 it was relevant
            $event.returnValue = prompt.message;
        }
    }

    // Run only when the form has actually been submitted
    // If the form is invalid and the submit button was clicked (rather than submitted another way), this won't run
    async onSubmit_changePassword(form: NgForm) {
        if (!this.validationService.runValidation(form)) return;

        this.appService.triggerPageLoading();

        try {
            await this.authService.changePassword(this.passwordChange);
            // emit success submit
            this.onSubmit.emit(true);
            // Clear out the values to show better indication to the user that we did it
            form.resetForm();

            this.messageService.notification('The password was successfully updated.', 'info', 5000)

            // reset all the password values so that pending changes won't get turned on again
            this.passwordChange.confirmPassword = null;
            this.passwordChange.newPassword = null;
            this.passwordChange.oldPassword = null;
            // turn off pending changes
            this._pendingChanges = false;
        } catch (e) {
            this.onSubmit.emit(false);
            throw(e);
        } finally {
            this.appService.finishPageLoading();
        }
    }

    onKeyUp_capturePassword = (e) => {
        this.passwordChange.newPassword = e.target.value;
    }

    onContentReady_focusInput(e) {
        e.component.focus();
    }

    // #endregion

    // #region Helpers

    public passwordComparison = () => {
        return this.passwordChange.newPassword;
    }

    // #endregion
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatLegacyInputModule,
        MatLegacyFormFieldModule,
        MatLegacyButtonModule,
        MatLegacyCardModule,
        PasswordCaptionModule,
        TellerOnlineSharedModule
    ],
    declarations: [ PasswordChangeComponent ],
    exports: [ PasswordChangeComponent ]
})
export class PasswordChangeModule { }
