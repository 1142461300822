import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-cart-empty',
    styles: [
        ':host.teller-online-icon--cart-empty { height: auto; width: 145px;}'
    ],
    templateUrl: '../svgs/CartEmptyImage.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--cart-empty'
    }
})
export class TellerOnlineIconCartEmpty {
}