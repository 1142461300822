import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-error',
    templateUrl: '../svgs/Error.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--error'
    }
})
export class TellerOnlineIconError {
}