import { Component } from '@angular/core';

@Component({
    selector: 'teller-online-icon-credit-card',
    templateUrl: '../svgs/CreditCard.svg',
    host: {
        class: 'teller-online-icon teller-online-icon--credit-card'
    }
})
export class TellerOnlineIconCreditCard {
}