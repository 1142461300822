// Angular Files
import { Component, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';

// Teller Online Icon Files
import { TellerOnlineIconRemove } from './components/remove.component';
import { TellerOnlineIconInfo } from './components/info.component';
import { TellerOnlineIconCart }  from './components/cart.component';
import { TellerOnlineIconHome } from './components/home.component';
import { TellerOnlineIconSearch }  from './components/search.component';
import { TellerOnlineIconMenu } from './components/menu.component';
import { TellerOnlineIconUserDashboard } from './components/user-dashboard.component';
import { TellerOnlineIconLocked } from './components/locked.component';
import { TellerOnlineIconPreferences } from './components/preferences.component';
import { TellerOnlineIconSignUp } from './components/sign-up.component';
import { TellerOnlineIconLogout } from './components/logout.component';
import { TellerOnlineIconLogin } from './components/login.component';
import { TellerOnlineIconViewBillDetails } from './components/view-bill-details.component';
import { TellerOnlineIconEnvelopeTracking } from './components/envelope-tracking.component';
import { TellerOnlineIconViewBill } from './components/view-bill.component';
import { TellerOnlineIconDownloadReceipt } from './components/download-receipt.component';
import { TellerOnlineIconCreditCard } from './components/credit-card.component';
import { TellerOnlineIconAddToCart } from './components/add-to-cart.component';
import { TellerOnlineIconSubnavArrow } from './components/subnav-arrow.component';
import { TellerOnlineIconRightArrow } from './components/right-arrow.component';
import { TellerOnlineIconPlus } from './components/plus.component';
import { TellerOnlineIconMinus } from './components/minus.component';
import { TellerOnlineIconOpenInNew } from 'libraries/icons/src/lib/components/open-in-new.component';
import { TellerOnlineIconExpand } from './components/expand.component';
import { TellerOnlineIconCollapse } from './components/collapse.component';
import { TellerOnlineIconError } from './components/error.component';
import { TellerOnlineIconPaymentComplete } from './components/payment-complete.component';
import { TellerOnlineIconECheck } from './components/echeck.component';
import { TellerOnlineIconClose } from './components/close.component';
import { TellerOnlineIconUser } from './components/user.component';
import { TellerOnlineIconSelectPayment } from './components/select-payment.component';
import { TellerOnlineIconCartEmpty } from './components/cart-empty.component';
import { TellerOnlineIconAttention } from './components/attention.component';
import { TellerOnlineIconMerchantLogos } from './components/merchant-logos.component';
import { TellerOnlineIconHelp } from './components/help.component';
import { TellerOnlineIconCancel } from './components/cancel.component';
import { TellerOnlineIconEmail } from './components/email.component';
import { TellerOnlineIconMarkRefunded } from './components/mark-refunded.component';
import { TellerOnlineIconSms } from './components/sms.component';
import { TellerOnlineIconSyncTeller } from './components/sync-teller.component';

@Injectable()
export class TellerOnlineIconsService {
    constructor(private cfr: ComponentFactoryResolver, private injector: Injector) {}

    /** List of available icons in the form "tag-name": Component */
    public icons = {
        'error': TellerOnlineIconError,
        'payment-complete': TellerOnlineIconPaymentComplete,
        'info': TellerOnlineIconInfo,
        'remove': TellerOnlineIconRemove,
        'user': TellerOnlineIconUser,
        'menu': TellerOnlineIconMenu,
        'cart': TellerOnlineIconCart,
        'home': TellerOnlineIconHome,
        'search': TellerOnlineIconSearch,
        'user-dashboard': TellerOnlineIconUserDashboard,
        'locked': TellerOnlineIconLocked,
        'preferences': TellerOnlineIconPreferences,
        'sign-up': TellerOnlineIconSignUp,
        'logout': TellerOnlineIconLogout,
        'login': TellerOnlineIconLogin,
        'view-bill-details': TellerOnlineIconViewBillDetails,
        'envelope-tracking': TellerOnlineIconEnvelopeTracking,
        'view-bill': TellerOnlineIconViewBill,
        'download-receipt': TellerOnlineIconDownloadReceipt,
        'credit-card': TellerOnlineIconCreditCard,
        'add-to-cart': TellerOnlineIconAddToCart,
        'subnav-arrow': TellerOnlineIconSubnavArrow,
        'right-arrow': TellerOnlineIconRightArrow,
        'plus': TellerOnlineIconPlus,
        'minus': TellerOnlineIconMinus,
        'open-in-new': TellerOnlineIconOpenInNew,
        'expand': TellerOnlineIconExpand,
        'collapse': TellerOnlineIconCollapse,
        'echeck': TellerOnlineIconECheck,
        'close': TellerOnlineIconClose,
        'select-payment': TellerOnlineIconSelectPayment,
        'cart-empty': TellerOnlineIconCartEmpty,
        'attention': TellerOnlineIconAttention,
        'merchant-logos': TellerOnlineIconMerchantLogos,
        'help': TellerOnlineIconHelp,
        'cancel': TellerOnlineIconCancel,
        'email': TellerOnlineIconEmail,
        'mark-refunded': TellerOnlineIconMarkRefunded,
        'sms': TellerOnlineIconSms,
        'sync-teller': TellerOnlineIconSyncTeller,
    }

    /** Generates the html for the component specified and adds any classes to the outerhtml wrapper if provided */
    public loadIconFromComponent(iconComponent, classes?: string[]) {
        // Generate the component and grab the HTML Element for the icon
        let iconElement: HTMLElement = this.cfr.resolveComponentFactory(iconComponent).create(this.injector).location.nativeElement;
        // Add our desired styling classes
        if(classes?.length > 0) iconElement.className += " " + classes.join(" ");
        // Grab the HTML of the component as a string
        return iconElement;
    }

    /** Loads the full html of the icon (including the outer html wrapper with classes) */
    public loadIcon(icon: string, classes?: string[]) {

        return this._loadIcon(icon, classes).outerHTML;
    }

    /** Loads just the svg content of the icon (can be used as the icon property for a dev extreme component) */
    public loadIconSvg(icon: string) {

        return this._loadIcon(icon).innerHTML;
    }

    /** Takes in a dashed name that represents an icon and returns the actual Angular component name
     * e.g. "error" becomes TellerOnlineIconError
     */
    private _convertIconToComponent(iconTag: string) {
        return this.icons[iconTag];
    }

    private _loadIcon(icon: string, classes?: string[]) {
        let iconComponent = this._convertIconToComponent(icon);

        return this.loadIconFromComponent(iconComponent, classes);
    }
}
