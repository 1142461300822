// Angular Files
import { Injectable } from '@angular/core';

// Teller Online Files
import { PageDto, PageApiClient } from 'apps/public-portal/src/app/core/api/PublicPortalApiClients';
import { CartService } from 'apps/public-portal/src/app/core/services/cart.service';
import { CoreModule } from 'apps/public-portal/src/app/core/core.module';

@Injectable({
    providedIn: CoreModule
})
export class PageService {
    pageDto: PageDto;
    constructor(
        private pageApiClient: PageApiClient,
        public cartService: CartService
    ) { }

    public async getPageDetails(pageId): Promise<PageModel> {
        this.pageDto =  await this.pageApiClient.getPageDetails(pageId).toPromise();
        return this.pageDto;
    }
}
export class PageModel extends PageDto{}
