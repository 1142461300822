// Angular Files
import { Injectable, ErrorHandler, NgZone } from '@angular/core';
import { Router } from '@angular/router';

// Services
import { TellerOnlineAppService } from './app.service';
import { TellerOnlineSiteMetadataService } from './site-metadata.service';

// Teller Online Shared Files
import { TellerOnlineDialogAction, TellerOnlineMessageService } from 'teller-online-libraries/shared';

// Teller Online Core Files
import { IErrorDto, ErrorLogApiClient } from '../api/CoreWebApi';

@Injectable({
    providedIn: 'root'
})
export class TellerOnlineErrorHandlerService implements ErrorHandler {
    private _environment;

    constructor(
        private router: Router,
        private errorLogApiClient: ErrorLogApiClient,
        private messageService: TellerOnlineMessageService,
        private appService: TellerOnlineAppService,
        private siteMetadata: TellerOnlineSiteMetadataService,
        private ngZone: NgZone
    ) { }

    init(environment, baseUrl: string) {
        this._environment = environment;
        ErrorLogApiClient.init(baseUrl);
    }

    handleError(error, showAlert: boolean = true) {
        // Turn off any loaders because we got an error
        this.appService.finishLoading();
        this.appService.finishPageLoading();

        var errorDto: IErrorDto;
        var showErrorDialog: boolean = this.appService.showErrorDialogs;
        if (error.errorDef) {
            errorDto = error as IErrorDto;
        } else if (error.rejection && error.rejection.errorDef) {
            errorDto = error.rejection as IErrorDto;
            //If the backend returned a 401 error it's because the user isn't authorized to be here so redirect them to the sign-in page
        } else if (error.rejection && error.rejection.status === 401) {
            // Using ngZone in order to over come "navigation triggered outside angular zone" issue
            // on navigating.
            this.ngZone.run(() => this.router.navigate(['/sign-in']));
            showErrorDialog = false;
        }

        var message: string;
        if (errorDto) {
            if (errorDto.errorDef == "SearchTypeInReadOnlyMode" || errorDto.errorDef == "SearchTypeInMaintenanceMode") {
                this.siteMetadata.load();
                this.router.navigate(['/']);
            }
            // This is an error from our server error handler.
            var messageFromServer = errorDto.errorMessage.replace(/\n/g, '<br/>');
            message = `${messageFromServer}<br/><br/>(Error Number ${errorDto.errorNumber})`;
            if (errorDto.errorDetails) {
                message += `</br></br>Details: ${errorDto.errorDetails}`;
            }
            if (errorDto.errorDef === "TimeoutFromApiClient") {
                // These timeouts come from AWS and need to be logged to the server
                this.logErrorToServer(error)
            }
        } else {
            // This is an unknown client error.
            const errorNumber = this._createClientErrorNumber();
            message = `An error occurred.<br/><br/>(Error Number ${errorNumber})`;
            if (this._environment && !this._environment.production) {
                message += `</br></br>Details: ${error}<br/>${error.stack}`;
            }

            this.logErrorToServer(error, errorNumber)
        }

        if (showAlert && errorDto && errorDto.errorMessage) {
            if (errorDto.prompt) {
                this.ngZone.run(() => this.messageService.alert(errorDto.errorMessage, 'Error'));
            } else {
                this.ngZone.run(() => this.messageService.notification(errorDto.errorMessage, 'error', 10000));
            }
        }

        if (showAlert && showErrorDialog) {
            this.messageService.custom({
                title: 'Error',
                content: message,
                actions: [new TellerOnlineDialogAction({
                    text: 'OK',
                    close: false,
                })],
            });
        }

        if (this.appService.debug) {
            throw error;
        }
    }

    /**
     * Logs error to the server
     * @param error Error object
     * @param errorNumber error number exists for when we need to generate error details. Usually for client side errors.
     */
    logErrorToServer(error, errorNumber?: string) {
        try {
            if (!errorNumber) {
                errorNumber = this._createClientErrorNumber();
            }
            let errorMessage: string = error?.toString() ?? '';
            if (errorMessage.startsWith('[object')) {
                // An invalid error object was provided, we attempt to get the error details
                errorMessage = '';
                try {
                    errorMessage = JSON.stringify(error);
                } catch { }
                if (!errorMessage) {
                    errorMessage = `Unable to get error details from ${error?.constructor.name}`;
                }
            }
            const stackTrace = error?.stack ?? (new Error().stack);

            // build the message which will be logged to the server
            let message = `ErrorNumber: ${errorNumber}, ${errorMessage}, Stack: ${stackTrace}`;

            // however, only log it if it didn't originate from the server
            // (e.g. a 500 will throw an unhelpful client side error that doesn't need to also be logged to the server)
            if (!error?.message?.includes("An unexpected server error occurred")) {
                this.errorLogApiClient.logError(message).subscribe(_ => {
                }, error2 => {
                    // Logging to the server failed. Console.log and otherwise do nothing.
                    console.log('Error occurred trying to log the error to the server: ' + error2);
                });
            }
        } catch (exception) {
            console.log('Error occurred trying to log the error to the server: ' + exception);
        }
    }

    /**
     * Generate a random number to correlate the error log with what the user sees.
     * Error number has the prefix "C" for Client error.
     */
    _createClientErrorNumber() {
        var randomNumber = Math.floor(Math.random() * 100000);
        return `C-${randomNumber}`;
    }
}
