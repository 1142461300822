// Angular Files
import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterEvent, Event } from '@angular/router';
import { NgForm } from '@angular/forms';

// Other External Files
import { Subscription } from 'rxjs';

// Payment Integration Files
import { PaymentProcessorProvider } from 'apps/public-portal/src/app/payment-integrations';
import { ConvenienceFeeConfigModel } from 'apps/public-portal/src/app/payment-integrations/base/models';

// Teller Online Files
import { ItemDto } from 'apps/public-portal/src/app/core/api/PublicPortalApiClients';
import {
    AuthService,
    CartModel,
    CartService,
    InboundRedirectService,
    ItemModel
} from 'apps/public-portal/src/app/core/services';

// Teller Online Library Files
import {
    CartStatusEnum,
    TellerOnlineAppService,
    TellerOnlineSiteMetadataService
} from 'teller-online-libraries/core';
import {
    TellerOnlineDialogAction,
    TellerOnlineMessageService,
    TellerOnlineValidationService
} from 'teller-online-libraries/shared';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrls: ['./cart.component.scss'],
    host: {
        class: 'cart'
    }
})
export class CartComponent implements OnInit, OnDestroy {
    // Declare @Output variables
    @Output() public toggleCart = new EventEmitter<boolean>();

    // Access html components
    @ViewChild('convenienceFeeNoteDialog') convenienceFeeNoteDialog: TemplateRef<any>;
    @ViewChild('disclaimerDialog') disclaimerDialog: TemplateRef<any>;

    // Public variables
    public showFeeNote: boolean = false;
    public showDisclaimer: boolean = false;
    public calculatingFee: boolean = false;

    public paymentPage: boolean = this.router.url.replace("#maincontent", "") == "/checkout";
    public paymentRedirect: boolean;
    public convenienceFees: { [key: string]: ConvenienceFeeConfigModel } = {};

    public cartDetails: CartDetails = {
        cartGuid: null,
        cartStatus: CartStatusEnum.Open,
        itemCount: 0,
        subtotal: {},
        convenienceFees: {},
        total: null,
        ver: 0
    };

    public disclaimer: string = this.siteMetadataService.customization.cartDisclaimer;
    /** A version of the disclaimer that can be used for the short preview text shown */
    public shortDisclaimer: string = this.disclaimer.replace(/<[^>]*>/g, '');

    public guestDetails:  {email: string } = { email: null };

    // Private variables
    /** A hot copy of the items in the cart, not be used for anything other than price calculation */
    private _cartItems;

    // Subscriptions
    private _cartSubscription: Subscription;
    private _routerEventSubscription: Subscription;
    private _convenienceFeeSubscription: Subscription;

    constructor(
        private messageService: TellerOnlineMessageService,
        private appService: TellerOnlineAppService,
        private router: Router,
        public cartService: CartService,
        public authService: AuthService,
        public validationService: TellerOnlineValidationService,
        public siteMetadataService: TellerOnlineSiteMetadataService,
        public paymentProvider: PaymentProcessorProvider,
        public inboundRedirectService: InboundRedirectService
    ) {
        this._routerEventSubscription = this.router.events.subscribe((event: Event|RouterEvent) => {
            switch(true) {
                case event instanceof NavigationEnd:
                    let endEvent = event as NavigationEnd;
                    this.paymentPage = endEvent.urlAfterRedirects.replace("#maincontent", "") == "/checkout";
                    break;
            }
        });


        this.paymentProvider.configLoaded$.subscribe(() => {
            this.convenienceFees["default"] = this.paymentProvider.defaultConfig.convenienceFee;

            for (let key in this.paymentProvider.configOverrides) {
                this.convenienceFees[key] = this.paymentProvider.configOverrides[key].convenienceFee;
            }
        })

        // TODO (PROD-276): For now we only support either both or neither integration being a redirect.
        this.paymentRedirect = this.paymentProvider.activeCreditProcessor.redirect;
    }

    ngOnInit() {
        // Setup/listen for changes to the cart
        this._cartSubscription = this.cartService.cart$.subscribe(cart => {
            // Manually call the function so we can unit test if the function was called
            this._setupCart(cart);
        });

        for (let key in this.paymentProvider.configOverrides) {
            let override = this.paymentProvider.configOverrides[key];
            override.convenienceFeeChanged$.subscribe(config => {
                this.convenienceFees[key] = config
            });
        }

        this._convenienceFeeSubscription = this.paymentProvider.defaultConfig.convenienceFeeChanged$.subscribe(config => {
            this.convenienceFees["default"] = config;
            this._calculateTotals();
        });
    }

    ngOnDestroy() {
        if(this._cartSubscription) this._cartSubscription.unsubscribe();
        if(this._routerEventSubscription) this._routerEventSubscription.unsubscribe();
        if(this._convenienceFeeSubscription) this._convenienceFeeSubscription.unsubscribe();
    }

    async onClick_removeFromCart(item) {
        this.messageService.prompt('Are you sure you want to remove ' + item.title + ' from your cart?', 'Remove Item?').then(async (confirm) => {
            if(confirm) {
                // See TODO below
                // // Capture the if the current route is the checkout page, before the cart changed is triggered
                // let previousPaymentPage = this.paymentPage;

                await this.cartService.removeItemFromCart(item);

                this.messageService.notification(item.title + ' was removed from the cart.', 'info', 4000);

                // TODO: This notification is arguably unecessary, and DevExtreme makes it challenging to stack toast notifcations.
                // If we move away from DevExtreme, a new notifcation system could be put in place, and we could show this message.

                // // Check if the cart has been emptied, and if the route was the checkout prior to cart change
                // if (this.cartDetails.itemCount == 0 && previousPaymentPage){
                //     this.messageService.notification('Cart empty, you must have items in your cart to checkout.', 'info', 4000, {my: 'top', at: 'bottom', of: 'header', offset: {x:12, y: 72}});
                // }
            }
        })
    }

    async onClick_displayWarning(item: ItemDto) {
        // Show a custom dialog with options to Resolve the conflict now or later
        let navigate: boolean = await this.messageService.custom({
            actions: [
                new TellerOnlineDialogAction({
                    text: "Resolve Now",
                    close: true
                }),
                new TellerOnlineDialogAction({
                    text: "Resolve Later",
                    close: false
                })
            ],
            content: item.warning.message,
            title: 'Warning: ' + item.title + ' Has Changed'
        })

        // Only route to item details if user selected 'Resolve Now'
        if (navigate) {
            this.router.navigate(['cart/'+ item.itemGuid]);
        }
    }

    onSubmit_proceedToPayment = (form: NgForm) => {
        this._proceedToPayment(form);
    }

    onClick_proceedToPayment = () => {
        this._proceedToPayment();
    }

    onClick_toggleCart = () => {
        this.toggleCart.emit(true);
    }

    onClick_showDisclaimer = () => {
        this.messageService.alert(this.disclaimer, "Disclaimer", "cart-disclaimer-modal");
    }

    onClick_showConvenienceFeeNote = (configKey) => {
        this.messageService.alert(this.convenienceFees?.[configKey]?.note, this.convenienceFees?.[configKey]?.label ?? "Convenience Fee", "cart-convenience-fee-modal");
    }

    onClick_setCartOpen = () => {
        this.messageService.prompt('If you have already started payment in another tab, you may be charged twice. Are you sure you want to unlock your cart?', 'Unlock Cart?').then(async (confirm) => {
            if(confirm) {
                this.cartService.openCart();
            }
        });
    }

    public getCartPartitionHeading(items: ItemModel[]) {
        const titles = new Set(items.map(i => i.page.title));
        return titles.size == 1 ? titles.keys().next().value : 'Other';
    }

    public hasMultiplePartitions() {
        return this.getPartitionsLength() > 1;
    }

    public getPartitionsLength() {
        return Object.keys(this.cartService.cartSnapshot.partitions).length
    }

    /** Called when a new version of the cart is pushed (via a subscription) */
    private _setupCart(cart: CartModel) {
        this.appService.consoleLog(this,  cart, 'Event order: ' + cart.version);

        //If this is an older version of the cart, we don't want it
        if(cart?.version && cart.version < this.cartDetails.ver)
            return;

        // Update the cart version
        this.cartDetails.ver = cart?.version;
        // Ensure the cart has finished loading before doing anything
        if(!this.cartService.loadingCart) {
            this.cartDetails.cartStatus = cart.cartStatus;
            this.cartDetails.cartGuid = cart.cartGuid;

            if (cart.guestEmailAddress) {
                this.guestDetails.email = cart.guestEmailAddress;
            }

            // Store the cart items for cost calculations later
            this._cartItems = cart.items;

            let changed = false;
            for (let key in this.cartDetails.subtotal) {
                // compare calculated subtotal with current subtotal for each partition
                if (cart.partitions[key]?.reduce((sub, item) => sub + item.amountInCart, 0) != this.cartDetails.subtotal[key]) {
                    changed = true;
                    break;
                }
            }

            //If the subtotal hasn't changed, no need to re-calculate
            if (!changed && this.cartDetails.itemCount == this._cartItems?.length)
                return;

            if (this.cartDetails.cartGuid && this._cartItems?.length > 0) {
                this.cartDetails.itemCount = this._cartItems.length;
                this._calculateTotals();
            } else {
                this._resetTotals();
                this.cartDetails.itemCount = 0;
            }
        }
    }

    /** Calculate the total cost for the items in the cart, including any applicable convenience fees */
    private async _calculateTotals() {
        this._resetTotals();
        if (this.cartDetails.cartGuid && this._cartItems.length > 0) {
            this.calculatingFee = true;
            let response = await this.paymentProvider.calculateConvenienceFee(this.cartDetails.cartGuid);

            let calculatedFees = response.fees ?? [];

            // temporary variables to prevent concurrent mutations of cartDetails
            let total = {};
            let subtotal = {};
            let convenienceFees = {};

            for (let configKey in this.convenienceFees) {
                const convenienceFeeConfig = this.convenienceFees[configKey];

                const convenienceFeeIsPercent = convenienceFeeConfig?.enabled && this.convenienceFees[configKey].format == 'percent';

                if (convenienceFeeIsPercent) this.cartDetails.convenienceFees[configKey] = 0;

                subtotal[configKey] = 0;

                this._cartItems.filter(item => (item.page.configKey ?? "default") == configKey).forEach(item => {
                    subtotal[configKey] += item.amountInCart;
                });

                if (convenienceFeeConfig?.enabled) {
                    convenienceFees[configKey] = calculatedFees.find(f => (f.configKey == '' ? "default" : f.configKey) == configKey)?.amount;
                }

                total[configKey] ??= 0;
                total[configKey] += subtotal[configKey];

                if(convenienceFeeConfig?.enabled) {
                    switch(convenienceFeeConfig.format) {
                        case 'percent':
                            total[configKey] += convenienceFees[configKey];
                            break;
                        case 'flat':
                            convenienceFees[configKey] = convenienceFeeConfig.rate;
                            total[configKey] += convenienceFees[configKey];
                            break;
                        default:
                            convenienceFees[configKey] = null;
                            total[configKey] = null;
                            break;
                    }
                }
            }

            this.cartDetails.total = total;
            this.cartDetails.subtotal = subtotal;
            this.cartDetails.convenienceFees = convenienceFees;

            this.calculatingFee = false;
        }
    }

    /** Reset the cart totals (subtotal, total, conveniencefee) to their defaults */
    private _resetTotals() {
        this.cartDetails.subtotal = {};
        this.cartDetails.convenienceFees = {};
        this.cartDetails.total = {};
    }

    /** Handles the act of trying to checkout - can be triggered via either enter in the form or clicking the button */
    private async _proceedToPayment(form: NgForm = null) {
        if(this.paymentRedirect)
        {
            this.appService.triggerLoading("Redirecting...");

            if (!this.authService.isSignedIn && this.cartDetails.cartStatus == CartStatusEnum.Open) {
                if (!this.validationService.runValidation(form)) {
                    this.appService.finishLoading();
                    return false;
                }

                await this.cartService.updateCart({
                    guestEmailAddress: this.guestDetails.email
                });
            }

            let proceed = await this.cartService.refreshCart(this.cartDetails.cartGuid);
            if (!proceed) {
                this.appService.finishLoading();
                return false;
            }
        }
        else
        {
            // trigger a regular load while the processor decides what to do
            this.appService.triggerLoading();
        }

        // TODO (PROD-276): Currently we can't mix redirect & non-redirect processors,
        // so checkout only needs to be called on one of them.
        this.paymentProvider.activeCreditProcessor.checkout(this.cartDetails.cartGuid);
    }
}

class CartDetails {
    cartGuid: string;
    cartStatus: CartStatusEnum;
    itemCount: number;
    subtotal: { [key: string]: number };
    convenienceFees: { [key: string]: number };
    total: { [key: string]: number };
    ver: number;
}
