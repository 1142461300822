// Angular Files
import { Component, Input, Output, EventEmitter, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Params, RouterModule } from '@angular/router';

// Angular Material Files
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

// Other External Files

// Component Files

// Teller Online Files
import { SearchResult } from 'apps/public-portal/src/app/core/services';

// Teller Online Library Files
import { TellerOnlineAppService } from "teller-online-libraries/core";
import { TellerOnlineIconsModule } from 'teller-online-libraries/icons';

@Component({
    selector: 'app-separated-search-result',
    templateUrl: './separated-search-result.component.html',
    styleUrls: ['./separated-search-result.component.scss'],
    host: {
        class: 'separated-search-result'
    }
})
export class SeparatedSearchResultComponent {
    // Public variables
    @Input() item: SearchResult;
    @Output() loadMore = new EventEmitter<void>();

    // Private variables

    // Subscriptions

    constructor(
        public appService: TellerOnlineAppService
    ) { }
}

@NgModule({
    declarations: [ SeparatedSearchResultComponent ],
    imports: [
        CommonModule,
        MatLegacyListModule,
        MatIconModule,
        MatToolbarModule,
        MatLegacyTooltipModule,
        MatLegacyButtonModule,
        RouterModule,
        TellerOnlineIconsModule
    ],
    exports: [ SeparatedSearchResultComponent ]

})

export class SeparatedSearchResultModule { }